import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { ButtonGroup } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { Switch } from "@lib/components";
import { TabSelect } from "@lib/components";
import { inject, observer } from "mobx-react";
import { Modal } from "@lib/components";
import { ModalContent } from "@lib/components";
import { FormTreeSelect } from "@lib/components";
import { cloneDeepSafe, RestaurantUtils } from "@lib/common";
import { MobxComponent } from "../../../../../mobx/components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { FieldOptionSetOptions } from "../menus/components/FieldOptionSetOptions";
import { TabGroup, TabItem } from "../settings-v2/receipt-printing";
import { EligibleItemsContainer, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsSwitchContainer } from "../settings-v2";
import { IconHelp } from "../../../../components/v2/icons";

type FormValues = T.Schema.Restaurant.Menu.RestaurantOptionSet;

interface Props {
	type: "edit" | "create";
	initialValues: FormValues | null;
	close: () => void;
}

interface State {
	tab: string;
	add_to: string[];
	restaurantStock: T.Schema.RestaurantMenuStock.Schema;
	using_points: boolean;
}

@inject("store") @observer
export class RestaurantFormOptionSetV2 extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
			add_to: [],
			restaurantStock: cloneDeepSafe(this.injected.store.restaurant_stock!),
			using_points: false,
		};
	}

	setTab = (tab: string) => {
		this.setState({ tab });
	};

	setRestaurantStock = (restaurantStock: T.Schema.RestaurantMenuStock.Schema) => {
		this.setState({ restaurantStock });
	};

	dishes_in = (id: string) => {
		const r = this.injected.store.restaurant!;
		return RestaurantUtils.menu
			.filterDishes(r, (dish) => {
				// console.log(dish.name)
				// console.log(dish.type)
				// console.log(dish.option_sets)
				return dish.type !== "combo" && dish.option_sets.indexOf(id) !== -1;
			})
			.map((dish) => dish._id);
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValues && !prevProps.initialValues) {
			this.setTab("0");
			this.setState({
				add_to: this.dishes_in(this.props.initialValues._id),
				restaurantStock: cloneDeepSafe(this.injected.store.restaurant_stock!),
				using_points: this.props.initialValues.using_points === true,
			});
		}
	}

	render() {

		const { store } = this.injected;
		const { type, initialValues, close } = this.props;
		const { tab, add_to, using_points } = this.state;
		const restaurant = this.injected.store.restaurant!;

		return (
				<div style={{ width: '100%'}}>
                <TabGroup className='menus' style={{ overflow: 'unset'}}>
						<TabItem
							active={this.state.tab === '0'}
							onClick={() => this.setState({ tab: '0'})}
							className="mobile_menu"
						>
						Menu
						</TabItem>
						<TabItem
							active={this.state.tab === '1'}
							onClick={() => this.setState({ tab: '1'})}
							className="mobile_menu"
						>
						Option Sets
						</TabItem>
						<TabItem
							active={this.state.tab === '2'}
							onClick={() => this.setState({ tab: '2'})}
							className="mobile_menu"
						>
						Conditions
						</TabItem>
						<TabItem
							active={this.state.tab === '3'}
							onClick={() => this.setState({ tab: '3'})}
							className="long-button"
						>
						    Add / Remove from Items
						</TabItem>
					</TabGroup>
				<RestaurantForm<FormValues>
					submit={async (r, values) => {

						let oldOptionSet = null;
						const existing = r.option_sets.findIndex((p) => p._id === values._id);

						// force set multiselect = true when using_points
						if (values.using_points === true) {
							values.conditions.multi_select = true;
						}

						if (existing === -1) {
							r.option_sets.push(values);
						} else {
							oldOptionSet = cloneDeepSafe(r.option_sets[existing]);
							r.option_sets[existing] = values;
						}

						for (const [i, m] of r.menus.entries()) {
							for (const [k, c] of m.categories.entries()) {
								for (const [z, d] of c.dishes.entries()) {
									if (add_to.indexOf(d._id) === -1) {
										const index = d.option_sets.indexOf(values._id);
										if (index !== -1) {
											r.menus[i].categories[k].dishes[z].option_sets.splice(index, 1);
										}
									} else {
										const index = d.option_sets.indexOf(values._id);
										if (index === -1) {
											r.menus[i].categories[k].dishes[z].option_sets.push(values._id);
										}
									}
								}
							}
						}

						const update = {
							$set: {
								menus: r.menus,
								option_sets: r.option_sets,
							},
						};
						//console.log(`SUBMIT: ${JSON.stringify(update)}`);

						// Update stock silently
						const updateStockItems = [];

						if (oldOptionSet) {
							for (const o of oldOptionSet.options) {
								const hasBeenDeleted = !values.options.find((v) => v._id === o._id);
								if (hasBeenDeleted) {
									updateStockItems.push({
										_id: o._id,
										key: "option_set_options" as "option_set_options",
										value: "" as "",
									});
								}
							}
						}

						for (const o of values.options) {
							updateStockItems.push({
								_id: o._id,
								key: "option_set_options" as "option_set_options",
								value: (() => {
									const val = this.state.restaurantStock.option_set_options[o._id];
									return typeof val === "undefined" ? "" as "" : val;
								})(),
							});
						}

						if (updateStockItems.length > 0) {
							store.api.menu_stock_update({
								_id: store.restaurant!._id,
								items: updateStockItems,
							})
								.then((result) => {
									store.setRestaurantStock(result.stock);
								})
								.catch(console.log);
						}

						return { r, update };

					}}
					validators={{
						name: (values) => {
							if (!values.name)
								return { name: "This field is required" };
						},
					}}
					initialValues={initialValues}
					onSuccess={close}
					onSuccessMessage={type === "edit" ? "Option set edited" : "Option set created"}
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue, values } = form;
						return (
							<div>
								{tab === "0" && (
									<SettingsFormGroup>
										{type !== "edit" && (
											<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer>
												<IconHelp />
												</SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<FastField
												name="using_points"
												render={({ field }: any) => (
													
														<TabGroup className='menus' style={{ overflow: 'unset'}}>
															<TabItem
																active={field.value === false || !using_points}
																onClick={() => {this.setState({ using_points: false}); setFieldValue("using_points",false)}}
															>
															Standard
															</TabItem>
															<TabItem
																active={field.value === true || using_points}
																onClick={() => {this.setState({ using_points: true}); setFieldValue("using_points",true)}}
															>
															Pizza
															</TabItem>
														</TabGroup>
												)}
											/>
											</SettingsFormGroupInput>
											
										)}
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
										<FastField
												name="name"
												render={({ field }: any) => (
												<input
													type="text"
													{...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
										<FastField
												name="display_name"
												render={({ field }: any) => (
												<input
													type="text"
													{...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
                                        <SettingsSwitchContainer>
                                            <SettingsFormLabelLeftContainer>
                                                Show In Menu
                                            </SettingsFormLabelLeftContainer>
                                            <FastField
                                                name="show_in_menu"
                                                render={({ field }: any) => (
                                                    <Switch
                                                        id="show_in_menu"
                                                        checked={field.value}
                                                        onChange={(e) => setFieldValue("show_in_menu", e.target.checked)} />
                                                )}
                                                />
										</SettingsSwitchContainer>
                                        <SettingsSwitchContainer>
                                            <SettingsFormLabelLeftContainer>
                                            Inc. Price in Free Quantity Promos
                                            </SettingsFormLabelLeftContainer>
                                            <FastField
                                                name="inc_price_free_qty_promo"
                                                render={({ field }: any) => (
                                                    <Switch
                                                        id="inc_price_free_qty_promo"
                                                        checked={field.value || false}
                                                        onChange={(e) => setFieldValue("inc_price_free_qty_promo", e.target.checked)} />
                                                )}
                                                />
										</SettingsSwitchContainer>
										{
											!!restaurant.abacus?.abacus_open_items && 

                                            <SettingsSwitchContainer>
                                                <SettingsFormLabelLeftContainer>
                                                Abacus - Disable Printing of Option Set Name
                                                </SettingsFormLabelLeftContainer>
                                                <FastField
                                                    name="abacus_hide_option_set_name"
                                                    render={({ field }: any) => (
                                                        <Switch
                                                            id="abacus_hide_option_set_name"
                                                            checked={field.value === undefined ? true : field.value}
                                                            onChange={(e) => setFieldValue("abacus_hide_option_set_name", e.target.checked)} />
                                                    )}
                                                    />
										    </SettingsSwitchContainer>
										}

										{
                                        <SettingsSwitchContainer>
                                             <SettingsFormLabelLeftContainer>
                                                Disable Printing of Option Set Name
                                             </SettingsFormLabelLeftContainer>
                                             <FastField
                                                 name="print_hide_option_set_name"
                                                 render={({ field }: any) => (
                                                    <Switch
                                                        id="print_hide_option_set_name"
                                                        checked={field.value === undefined ? false : field.value}
                                                        onChange={(e) => setFieldValue("print_hide_option_set_name", e.target.checked)} />
                                                 )}
                                                 />
                                         </SettingsSwitchContainer>
										}

									</SettingsFormGroup>
								)}

								{tab === "1" && (
									<ModalContent style={{ padding: '20px 0 0 0'}}>
										<FormGroup
											error={getFieldError(form, "options")}>
											<FieldOptionSetOptions
												values={values}
												setFieldValue={setFieldValue}
												option_sets={restaurant.option_sets}
												defaultEnabled={form.values.conditions.required && !form.values.conditions.multi_select}
												restaurantStock={this.state.restaurantStock}
												setRestaurantStock={this.setRestaurantStock}
												isModernVersion
											/>
										</FormGroup>
									</ModalContent>
								)}

								{tab === "2" && (
									<SettingsFormGroup>
                                        <SettingsSwitchContainer>
                                             <SettingsFormLabelLeftContainer>
                                                Required
                                             </SettingsFormLabelLeftContainer>
                                             <FastField
                                                 name="conditions.required"
                                                 render={({ field }: any) => (
                                                    <Switch
														id="conditions.required"
														checked={field.value}
														onChange={(e) => {
															if (!e.target.checked) {
																setFieldValue("options", form.values.options.map((option) => ({
																	...option,
																	quantity: 0,
																})));
															}
															setFieldValue("conditions.required", e.target.checked);
														}} />
                                                 )}
                                                 />
                                         </SettingsSwitchContainer>
										{!using_points && (

                                        <SettingsSwitchContainer>
                                            <SettingsFormLabelLeftContainer>
                                            Select Multiple
                                            </SettingsFormLabelLeftContainer>
                                            <FastField
                                                name="conditions.multi_select"
                                                render={({ field }: any) => (
                                                    <Switch
                                                    id="conditions.multi_select"
                                                    checked={field.value}
                                                    onChange={(e) => setFieldValue("conditions.multi_select", e.target.checked)} />
                                                )}
                                                />
                                        </SettingsSwitchContainer>

                                            )
										}
										{!using_points && (
                                            
                                            <SettingsSwitchContainer>
                                                <SettingsFormLabelLeftContainer>
                                                Enable Option Quantity
                                                </SettingsFormLabelLeftContainer>
                                                <FastField
                                                    name="conditions.quantity_selectt"
                                                    render={({ field }: any) => (
                                                        <Switch
                                                        id="conditions.quantity_select"
                                                        checked={field.value}
                                                        onChange={(e) => setFieldValue("conditions.quantity_select", e.target.checked)} />
                                                    )}
                                                    />
                                            </SettingsSwitchContainer>
										)}

                                    <SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Min Options Required</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="conditions.min_options"
												render={({ field }: any) => (
												<input
                                                    type="number" step={1} min={0} {...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Max Options Allowed</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="conditions.max_options"
												render={({ field }: any) => (
												<input
                                                    type="number" step={1} min={0} {...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										
										{!using_points && (
                                            <SettingsFormGroupInput>
                                            <SettingsFormLabelContainer>
                                                <SettingsFormLabelLeftContainer>Free Quantity</SettingsFormLabelLeftContainer>
                                                <SettingsFormLabelRightContainer>
                                                <IconHelp />
                                                </SettingsFormLabelRightContainer>
                                            </SettingsFormLabelContainer>
                                            <SettingsFormInputContainer>
                                                <FastField
                                                    name="conditions.free_amount"
                                                    render={({ field }: any) => (
                                                    <input
                                                        type="number" step={1} min={0} {...field}
                                                        />
                                                    )} />
                                            </SettingsFormInputContainer>
                                            </SettingsFormGroupInput>
                                            )
										}
									</SettingsFormGroup>
								)}

								{tab === "3" && (
										<SettingsFormGroupInput style={{gap: 16}}>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer style={{alignItems: 'center'}}>
													Edit Option Set
												</SettingsFormLabelLeftContainer>
											</SettingsFormLabelContainer>
											<EligibleItemsContainer className='menus-v2'>
												<FormTreeSelect
													id={`add-to-dishes`}
													selected={add_to}
													nodes={RestaurantUtils.menu.getTreeFilter(restaurant, () => true)}
													onChange={(add_to_dishes) => {
														this.setState({ add_to: add_to_dishes });
													}}
												/>
											</EligibleItemsContainer>
										</SettingsFormGroupInput>
								)}

								{tab === "4" && (
									<ModalContent>
										{type !== "edit" && (
											<FastField
												name="using_points"
												render={({ field }: any) => (
													<FormGroup title="Option Set Type"
														help="A Pizza option set allows the use of a point system to set limits to item add-ons. More info in (LINK HERE).">
														<ButtonGroup
															width={100}
															size="sm"
															selected={field.value === true ? 1 : 0}
															options={[
																{ name: "Standard", value: 0 },
																{ name: "Pizza", value: 1 },
															]}
															onSelect={({ value }) => {
																setFieldValue("using_points", value === 1);
																this.setState({ using_points: value === 1 });
															}
															}
														/>
													</FormGroup>
												)}
											/>
										)}
										<FastField
											name="integrations.pushpos.category_id"
											render={({ field }: any) => (
												<FormGroup
													title="POS Category Id"
													help="A unique id for your category"
													error={getFieldError(form, "integrations.pushpos.category_id")}>
													<Input type="number" {...field} required={false} />
												</FormGroup>
											)}
										/>
									</ModalContent>
								)}

                                <SettingsActionButtonContainer>
										<SettingsActionButtonWrapper style={{ left: 0}}>
											<button type="button" 
												className="default btn-cancel" 
												onClick={ () =>{ store.setActiveMobileMenu(
													store.activeMobileMenu.referrer.pop()!,
													Array.from(new Set(store.activeMobileMenu.referrer))
												); 
												}}>
													Cancel 
											</button>
											<button type="submit" disabled={isSubmitting}>
												{isSubmitting && <RotateLoader size={2} color="white" />}
												{!isSubmitting && "Save"}
											</button>
										</SettingsActionButtonWrapper>
									</SettingsActionButtonContainer>

							</div>
						);
					}}
				</RestaurantForm>
            </div>
		);

	}

}
