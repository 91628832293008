import React from "react";
import styled from "styled-components";

export const ServiceOrder = styled.div`
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-weight: 600;
    line-height: 24px;
    width: 264px;
    justify-content: center;
    color: #FEFEFE;
    border-radius: 4px;
`
export const SwitchButtonStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #F1F4F7;
    border-radius: 4px;
`
export const OrderViewMobile = styled.div`
@media (max-width: 930px) {
    width: 100%;
    float: none;
}
`;
export const MobileViewWrapper = styled.div`
  display: block;
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
`;
export const HeaderMobileWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #DFDFDF;
  padding: 8px 16px;
  background-color: #FFF;
`;
export const ActionToolBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    background-color: #FEFEFE;
    color: #4B4F5E;
    font-weight: 500;
    line-height: 24px;
    border-bottom: 1px solid #DFDFDF;

`;
export const ActionToolBarButton = styled.div`
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
`