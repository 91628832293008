import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../../mobx/components";
import styled from "styled-components";
import { BumpButton, Button, CommonText, FooterSection, ModalContent, RotateLoader } from "@lib/components";
import { SpecialHourModal, specialHourProps } from "./special-hour-modal";
import { ClockIconBlack, IconCalendarAlt } from "../../../../components/v2/icons";
import { SpecialHourCard } from "./special-hours-card";
import { DashedDivider, Flex } from "./special-hours-constants";
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter } from "../settings-v2";
import { UI } from "../../../../../core/ui";


const Table = styled.table`
    background-color: #fff;
    width: 100%;
    margin-bottom: 1rem;
    & thead {
        border-bottom: 1px solid #C5C5C5;
        background: var(--Secondary-Smokey-Grey-1, #6B7270);
        & th {
            color: white;
            text-align: left;
        }
    }
    & tr {
        &:not(:last-child) {
            border-bottom: 1px solid #C5C5C5;
        }

        &:not(:first-child) {
            background: white;
        } 

        &:not(:nth-child(odd)) {
            background: #F7F7F7;
        } 

        & td, th {
            padding: 12px 24px;
        }
    }
    display: none;
    @media (min-width: 992px) {
        display: table;
    }
`
const FlexWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
`

const SpecialHoursHeader = styled.div`
display: flex;
padding: 16px 24px 16px 0px;
align-items: flex-start;
align-self: stretch;
justify-content: space-between;

flex-direction: column;
gap: 16px;


& button {
    border-radius: 4px;
    background: var(--Primary-Red-Orange-1, #FB2F2F);
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    appearance: none;
    border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
    color: white;
    width: 100%;
}

@media (min-width: 992px) {
    flex-direction: row;    

    & button {
        width: auto;
    }
    
}
`

const P = styled.p`
    margin-bottom: 1rem;

    .text-danger {
        color: var(--Primary-Red-Orange-1, #FB2F2F);
    }
`

const SpecialHoursCardContainer = styled.div`
margin-top: 12px;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
flex: 1 0 0;
height: 80vh;
padding-bottom: 130px;
overflow-y: auto;

`

interface Props extends WithTranslation {
    show: boolean;
    toggleSpecialHoursModal: () => void;
}
interface State {
    show: boolean;
    // specialHourList: string[];
    selectedSpecialHour: specialHourProps | undefined;
    selectedIndex: number | undefined;
    loading: boolean;
    confirmDeletePopup: boolean;
    modalType: string;
}

@inject("store") @observer
class RestaurantSpecialHoursClass extends MobxComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show: false,
            // specialHourList: [],
            selectedSpecialHour: undefined,
            selectedIndex: undefined,
            modalType: 'add',
            loading: false,
            confirmDeletePopup: false
        }

        this.toggleShow = this.toggleShow.bind(this)
    }

    toggleShow() {
        this.setState({
            show: !this.state.show
        })
    }
    handleOpenFormModal(data: specialHourProps, index: number, modalType = 'add') {
        this.props.toggleSpecialHoursModal()
        this.setState(prev => ({ ...prev, selectedSpecialHour: data, selectedIndex: index, modalType }));
    }

    toggleDeletePopup(index?: number) {
        this.setState(prev => ({ ...prev, confirmDeletePopup: !prev.confirmDeletePopup, selectedIndex: index }));
    }

    handleDeleteSpecialHour = async () => {
        try {
            this.setState(prev => ({ ...prev, loading: true }))

            if (this.state.selectedIndex !== undefined) {
                const response = await this.injected.store.api.delete_special_hour(this.state.selectedIndex);
                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                    UI.notification.success("Special hour deleted!");
                    this.injected.store.deleteSpecialHour(this.state.selectedIndex)
                }
                this.setState(prev => ({ ...prev, loading: false, selectedIndex: undefined }))

            }
            this.toggleDeletePopup();
        }
        catch (e) {
            UI.notification.error("Something went wrong, try again or contact us");
            this.setState(prev => ({ ...prev, loading: false, selectedIndex: undefined }))
            this.toggleDeletePopup();
        }
    }

    render() {
        const specialHourList = this.injected.store.restaurant?.location?.special_hours ? this.injected.store.restaurant?.location?.special_hours : [];
        const restaurant = this.injected.store.restaurant!

        return (<>
            <ModalContent style={{ padding: '1rem' }}>
                <P style={{ color: 'var(--Secondary-Smokey-Grey-1, #6B7270)' }}>Set special opening hours for holidays or other periods. This will override your regular opening hours for your store location and all services.</P>
                <DashedDivider />
                <P className="big font-semi-bold">List of Special Hours</P>
                <Flex gap={3}>
                    <CommonText size="semiMedium" weight={500} color="#FB2F2F">
                        Note:
                    </CommonText>
                    <CommonText size="semiMedium" weight={500} color="#0C202A">
                        Dates that already expires will be automatically deleted.
                    </CommonText>
                </Flex>
                <SpecialHoursCardContainer>
                    {specialHourList.map((data, i) => {
                        return (
                            <SpecialHourCard key={i} data={data} index={i} selectedIndex={this.state.selectedIndex} openModal={(type: string) => this.handleOpenFormModal(data, i, type)} toggleDeletePopup={() => this.toggleDeletePopup(i)} />
                        )
                    })}
                </SpecialHoursCardContainer>
            </ModalContent>
            {this.state.confirmDeletePopup && <MobileModal customBackground="tranparent">
                <MobileModalContent>
                    <CommonText size="small" weight={500} color={' #6B7270'}>
                        Are you sure you want to delete this date(s)?
                    </CommonText>
                    <FlexWrapper>
                        <Button type="button" color='primary' full={true} loading={!!this.state.loading} onClick={this.handleDeleteSpecialHour}>
                            Confirm
                        </Button>
                        <Button type="button" full={true} onClick={() => this.toggleDeletePopup()}>
                            Cancel
                        </Button>
                    </FlexWrapper>
                </MobileModalContent>
            </MobileModal>}

            <FooterSection width={window.innerWidth < 420 ? window.innerWidth : 420} version="v2">
                <Button type="button" full={true} paddinglr={5} color='primary' onClick={() => {
                    this.props.toggleSpecialHoursModal();
                    this.setState(prev => ({
                        ...prev, selectedSpecialHour: undefined,
                        selectedIndex: undefined,
                        modalType: 'add'
                    }));
                }}>
                    Add special hours
                </Button>
            </FooterSection>
            <SpecialHourModal show={this.state.show || this.props.show} modalType={this.state.modalType} selectedIndex={this.state.selectedIndex} selectedSpecialHour={this.state.selectedSpecialHour} setShow={() => {
                this.props.toggleSpecialHoursModal();
                this.setState(prev => ({
                    ...prev,
                    selectedIndex: undefined,
                }));
            }} />
        </>)
    }
}

export const RestaurantSpecialHours = withTranslation()(RestaurantSpecialHoursClass);
