import React from 'react';
import styled from 'styled-components';
import { MobXProviderContext, observer } from 'mobx-react';
import { SearchIcon, CloseIcon } from '../../../../../components/v2/icons';

const InputWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
`;

const InputComponent = styled.input`
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    padding-left: 43px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

type InputWithIconProps = {
    
}

export const InputWithIcon: React.FC<InputWithIconProps> = observer(() => {
    const { store } = React.useContext(MobXProviderContext);
    const {searchKey} = store.bookingControl;
    const handleKeyPress = async (e: any) => {
        e.persist();
        const {value} = e.target;
        if (e.keyCode === 13 || e.key === 'Enter') {
            if (value) {
                const response = await store.api.get_search_bookings(store.restaurant._id, value)
                if (response.outcome !== 0)
                    throw new Error(response.message);
                store.updateBookings({
                    items: response.data,
                });
            }
            if(value === ''){
                store.service.booking.handle_get_booking()
            }
            e.preventDefault();
        }
    }
    return (
        <InputWrapper>
            <SearchIcon />
            <InputComponent
                placeholder='Search by name,...'
                onChange={(e) =>  store.updateBookingControl({ searchKey: e.target.value })}
                onKeyDown={handleKeyPress}
                value={searchKey}
            />
            {searchKey && <CloseIcon onClick={() => {
                store.service.booking.handle_get_booking()
                store.updateBookingControl({ searchKey: '' })
            }} />}
        </InputWrapper>
    );
});
