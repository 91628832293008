import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../mobx/components';
import styled, { css } from 'styled-components';
import { IconAddCard, IconAddons, IconArrowForward, IconDeliveries, IconDesignMode, IconDineIns, IconEligibleItemCheck, IconHelp, IconHelpV2, IconHome, IconHour, IconIntegrations, IconMoreOptions, IconPayment, IconPickups, IconPlusRed, IconServices, IconSystem, IconSystemAgeVerification, IconSystemAudioNotification, IconSystemConditionalFees, IconSystemCustomerAccounts, IconSystemEmailNotification, IconSystemGeneral, IconSystemLocation, IconSystemMisc, IconSystemOrdering, IconSystemPolicy, IconSystemPromo, IconSystemReceipt, IconSystemTipping, IconSystemWebhooks, IconTableBooking, IconWebsite, IconWrapperRounded } from '../../../../components/v2/icons';
import { MainContainer } from '../../../../components/v2/layout.c';
import { SettingsGeneral } from './general';
import { SettingsLocation } from './location';
import { SettingsPromoCodes } from './promo-codes';
import { SettingsConditionalFees } from './conditional-fees';
import { SettingsPoliciesAgreements } from './policies-agreements';
import { SettingsReceiptPrinting } from './receipt-printing';
import { SettingsMiscellaneous } from './miscellaneous';
import { SettingsOrdering } from './ordering';
import { SettingsTipping } from './tipping';
import { SettingsWebhooks } from './webhooks';
import { SettingsAgeVerification } from './age-verification';
import { SettingsCustomerAccounts } from './customer-accounts';
import { SettingsAudioNotification } from './audio-notification';
import { SettingsEmailNotification } from './email-notification';
import { PickUpServices } from './services/pickup/pickup-services';
import { DeliveriesServices } from './services/deliveries/deliveries-service';
import { DineInsServices } from './services/dine-ins/dine-ins-service';
import { GeneralPickUpServices } from './services/pickup/general';
import { AvailableHoursServices } from './services/available-hours';
import { CustomerInfoServices } from './services/customer-info';
import { OrderTimingServices } from './services/order-timing';
import { WaitTimesAutoStatusServices } from './services/wait-times-auto-statuses';
import { CustomCheckoutFields } from './services/custom-checkout-fields';
import { TookanServices } from './services/tookan';
import { GeneralDeliveriesServices } from './services/deliveries/general';
import { GeneralDineInsServices } from './services/dine-ins/general';
import { TablesDineIns } from './services/dine-ins/tables';
import { TablesBookingServices } from './services/tables-booking/table-booking-service';
import { GeneralTableBookingServices } from './services/tables-booking/general';
import { BookingTimingServices } from './services/tables-booking/booking-timing';
import { ZonesDeliveryServices } from './services/deliveries/zones';
import { ConditionDeliveryServices } from './services/deliveries/conditions';
import { DeliveriesFeeServices } from './services/deliveries/fee';
import { PaymentSettings } from './payments';
import { IntegrationsSettings, SettingsFormIntegrationsKounta } from './integrations';
import { SettingsFormIntegrationFacebookPixel } from '../settings/forms/integrations/facebook-pixel';
import { SettingsFormIntegrationGoogleAnalytics } from '../settings/forms/integrations/google-analytics';
import { SettingsFormIntegrationTookan } from '../settings/forms/integrations/tookan';
import { SettingsFormIntegrationQuestTag } from '../settings/forms/integrations/questtag';
import { SettingsFormIntegrationAbacus } from '../settings/forms/integrations/abacus';
import { SettingsFormIntegrationPostmates } from '../settings/forms/integrations/postmates';
import { SettingsFormIntegrationLalamove } from '../settings/forms/integrations/lalamove';
import { SettingsFormIntegrationWalletly } from '../settings/forms/integrations/walletly';
import { SettingsFormIntegrationUber } from '../settings/forms/integrations/uber';
import { SettingsWebsite } from './website';
import { SettingsWebsiteTheme } from './website/themes';
import { SettingsWebsiteColors } from './website/colors';
import { SettingsWebsiteFonts } from './website/fonts';
import { SettingsWebsiteTopNavBar } from './website/topnavbar';
import { SettingsWebsiteMenuNavBar } from './website/menunavbar';
import { SettingsWebsiteHeader } from './website/header';
import { SettingsWebsiteItems } from './website/items';
import { SettingsWebsiteFooter } from './website/footer';
import { SettingsWebsiteMobile } from './website/mobile';
import { SettingsWebsiteAlert } from './website/alert';
import { SettingsWebsiteDomain } from './website/domain';
import { SettingsWebsiteFavicon } from './website/favicon';
import { SettingsWebsiteSEO } from './website/seo';
import { SettingsWebsiteSocial } from './website/social';
import { SettingsWebsiteCustom } from './website/custom';
import { SettingsWebsiteMisc } from './website/misc';
import { SettingsWebsiteItemLayout } from './website/itemlayout';

export const ContentContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
background: var(--Neutral-White, #FEFEFE);
width: 100%;
height: auto;
overflow-y: scroll;
height: calc(100vh - 61.5px);
margin-top: 45.5px;
@media (min-width: 768px) {
    height: calc(100vh - 169px);
    margin-top: 54px;
}
&::-webkit-scrollbar {
    width: 0px!important;
}

& .text-orange {
    color: var(--Primary-Red-Orange-1, #FB2F2F);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

@media (min-width: 768px) {
    max-width: 720px;
    margin: 0 auto;
    background: unset;

    & form {
        background: #fff;
    }

}

@media (min-width: 992px) {
    max-width: 894px;
}

&.landing-page {
    form {
        width: 100%;
    }
}
`

export const SettingsMenuContainer = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
width: 100%;
&.border-bottom-mobile {
   border-bottom: 1px solid #DFDFDF;
}
@media (min-width: 768px) {
    gap: 16px;
    &.border-bottom-mobile {
        border-bottom: none;
    }
}
`

export const SettingsMenuRow = styled.div`
display: flex;
padding: 12px;
align-items: flex-start;
gap: 16px;
width: 100%;
border-radius: 4px;
cursor: pointer;
&.active, &:hover, &:focus {
    background: var(--Primary-Light-Alabaster-1, #F5F5F5);
   
}

&:hover {
    .icon-wrapper-rounded {
        background: var(--Secondary-Light-Grey-1, #DFDFDF);
    }
}

@media (min-width: 768px) {
    background: var(--Neutral-White, #FEFEFE);
    &.active, &:hover, &:focus {
        background: var(--Neutral-White, #FEFEFE);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    } 
}

`
export const SettingsMenuRowWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
flex: 1;
`
export const SettingsMenuRowItem = styled.div`
display: flex;
align-items: center;
gap: 16px;
align-self: stretch;
& p {
    flex: 1 0 0;
    color: var(--Secondary-Dark-Grey-1, #4B4F5E);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}
`

export const SettingsMenuRowHeader = styled.p`
display: flex;
height: 40px;
flex-direction: column;
justify-content: center;
align-self: stretch;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
`

/**GENERAL */

export const SettingsFormGroup = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
width: 100%;
& p {
    color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

@media (min-width: 768px) {
	flex-direction: row;
	flex-wrap: wrap;
}

& div [class^="listselect__ItemComponent"] {
    @media (max-width: 768px) {
        .flex-line.centered,
        .flex-l-r-center {
            flex-direction: column;
            align-items:start;
        }
    }
}
`
export const FlexWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
`
export const SettingsFormGroupInput = styled.div<{ error?: any }>`
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
gap: 4px;
@media (min-width: 768px) {
	flex: 50%;

    &.flex-half {
        flex: 0 0 calc(50% - 12px);
    }

	&.flex-full {
		flex: 100%;
	}

    &.cookie-consent-desktop {
        flex-direction: row;
        flex-wrap: wrap;

        & .cookie-consent-label {
            flex: 0 0 100%;
        }

        & .cookie-consent-color {
            flex: 0 0 calc(50% - 1rem);
        }

        & div:nth-child(2) {
            margin-right: 1rem;
        }
    }

}

   & div[class^="group__FormGroupComponent"]  button {
    height: 48px;
   }
@media (max-width: 768px) {
    & div[class^="group__FormGroupComponent"]  {
        input {
            width: 100% !important;
        }
    }

    & div[class^="tooltip__TooltipWrapper"] {
        button {
            height: 48px;
        }
    }
}

& .desc {
    color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 133.333% */
}
& div[class^="inputgroup__InputGroupComponent"] {
    width: 100%;
    & input,
    & div[class^="inputgroup__InputGroupIcon"] {
        height: 52px;
    }
}

${props => props.error ? `

    & div[class^="settings-v2__SettingsFormLabelContainer"],
    & div[class^="settings-v2__SettingsFormInputContainer"] {
        border-color: var(--Status-Cancelled, #BC362F);
        color:var(--Status-Cancelled, #BC362F);
        & div[class^="settings-v2__SettingsFormLabelLeftContainer"],
        & input, & textarea {
            color:var(--Status-Cancelled, #BC362F);
        }
        & svg path {
            fill: var(--Status-Cancelled, #BC362F);
        }
    }
    &::after {
        content: "${props.error}";
        display: block;
        width: 100%;
        position: relative;
        color:var(--Status-Cancelled, #BC362F);
        font-size: .75rem;
    }
    

 
`
        : null}

 & div[class^="restauranticonselect__Icon-"] {
    border: none;
 }

 &.desktop-only {
    @media (max-width: 767px) {
        display: none;
    }
 }

`

export const SettingsFormLabelContainer = styled.div`
display: flex;
align-items: center;
gap: 8px;
align-self: stretch;
&.desktop-only {
    width: 100%;
    @media (max-width: 768px) {
        display: none;
    }
    & button {
        align-self: stretch;
        padding-left: 32px;
        padding-right:32px;
    }
}
`
export const SettingsFormLabelLeftContainer = styled.div`
display: flex;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-size: 16px;
font-family: Inter;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
`

export const SettingsFormLabelRightContainer = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 8px;
flex: 0 0 20px;
`


export const SettingsFormInputContainer = styled.div`
display: flex;
padding: 12px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-White, #FEFEFE);

& input, & select, & textarea {
    color: var(--Secondary-Dark-Grey-1, #4B4F5E);
    pointer-events: auto;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border: none;
    width: 100%;
    &:focus-visible {
        outline: unset;
    }
}
&.disabled,&:disabled {
    background: var(--Secondary-Light-Grey-1, #DFDFDF);
    & input {
        background: var(--Secondary-Light-Grey-1, #DFDFDF);
    }
}

& div[class^="datepickerinput"] {
    width: 100%;
    & .dropdown {
        width: 100%;
    }
}

& div[class^="selectadv__Wrapper"] {
    border: none;
    padding: 0px;
    & div[class^="selectadv__InputIcon"] {
        border-left: none;
        color: #4B4F5E;
        justify-content: end;
    }



    & div[class^="inputtags__Tag"] {
        background: #dfdfdf;
        color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
    }
}

& div[class^="inputtags__Wrapper"] {
    border: none;
    padding: 0px;
}

&#map-input {
    & div[class^="selectasync__Wrapper"] {
        border: none;
        & div[class^="selectasync__InputIcon"] {
            border: none;
            justify-content: end;
        }

        padding: 0;
        & input {
            height: auto;
            margin: 1px;
        }
        
    }
}

& div.no-border-v2 {
	border: none!important;
    margin-top: 0!important;
}

& div[class^="box__Box"] {
    & input {
        border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
    }
}
    @media(max-width: 768px) {
	width: 100%;
 }
`

export const SettingsCustomOptionsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
@media (min-width: 768px) {
    flex-direction: row;
    & > div {
        flex: 1;
    }
}
`

export const SettingsCustomOption = styled.div`
display: flex;
padding: 12px;
align-items: center;
gap: 8px;
align-self: stretch;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
border-radius: 4px;
background: var(--Primary-Light-Alabaster-1, #F5F5F5);
cursor: pointer;
&.active, &:hover, &:active {
    border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
    background: var(--Primary-Accent-Wisp-Pink-1, #FFF3F3);
    color: #FB2F2F;
    justify-content: space-between;

    & svg path {
        fill : #FB2F2F;
    }
}

& .icon-w-24 {
    & svg {
        width: 24px;
        height: 24px;
    }
}
`

export const SettingsActionButtonContainer = styled.div<{ activeDesignMode?: boolean }>`
  & .btn-cancel {
    display: none;
  }
@media (min-width: 768px) {
    position: fixed;
    bottom: 0px;
    width: ${props => props.activeDesignMode ? '460px' : 'calc(100% - 60px)'};
    left: 0px;
    margin-left: 60px;
    border-top: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
    background: white;
    z-index: 99;
    & div[class^="settings-v2__SettingsActionButtonWrapper"] {
        position: relative;
        max-width: 894px;
        margin: 0 auto;
        border-top: 0px;
        justify-content: end;

        & button {
            min-width:${props => props.activeDesignMode ? '100%' : '148px'};
            flex: 0;
        }
    }

    & .btn-cancel {
        display: block;
        border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
        background: var(--Neutral-Gray, #F1F4F7);
        color: var(--Secondary-Dark-Grey-1, #4B4F5E);
        cursor: pointer;
    }

    &.no-sidebar {
        margin-left: 0;
        width: 100%;
    }
}
`

export const SettingsActionButtonWrapper = styled('div') <{ position?: string, noborder?: boolean, variant?: string, nopadding?: boolean }>`
display: flex;
width: 100%;
padding: ${props => props.nopadding ? '0' : '16px'};
align-items: center;
gap: 8px;
border-top: ${props => props.noborder ? 'none' : '1px solid var(--Secondary-Light-Grey-1, #DFDFDF)'};
background: #FFF;
position: ${props => props.position || 'fixed'};
bottom: 0px;
z-index: 99;

& button {
    display: flex;
padding: 12px;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
border-radius: 4px;
background: var(--Primary-Red-Orange-1, #FB2F2F);
color: var(--Neutral-Gray, #F1F4F7);
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
appearance: none;
border: none;
min-height: 48px;

${props => props.variant === 'white' ? css`
background: white;
color: #FB2F2F;
border: 1px solid #FB2F2F; 
` : null}
${props => props.variant === 'black' ? css`
background: #4B4F5E;
border-color: #4B4F5E;
background: transparent;
color: #4B4F5E;
` : null}
${props => props.variant === 'default' ? css`
border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);
color: #4B4F5E;
` : null}

&.default {
    border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Neutral-Gray, #F1F4F7);
color: #4B4F5E;
}

&.btn-cancel {
    border-radius: 4px;
border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Primary-Light-Alabaster-1, #F5F5F5);
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 114.286% */
}
}
@media(min-width: 768px) {
    &.mobile-only {
        display: none;
    }
}

@media(max-width: 768px) {
    &.desktop-only {
        display: none;
    }
}
`

export const SettingsSwitchContainer = styled.div`
display: flex;
align-items: center;
gap: 24px;
align-self: stretch;
@media (min-width: 768px) {
	flex: 50%;

    &.flex-half {
        flex: 0 0 calc(50% - 12px);
    }

	&.flex-full {
		flex: 100%;
	}
}

& div[class^="Switch__SwitchWrapper"] {
    &:focus, &:active, &:focus-visible {
    background: white!important;
    border-radius: 30px;
    }
}
`

export const SalesTaxesAddButton = styled('button') <{ variant?: string }>`
display: flex;
padding: 8px 16px;
align-items: center;
gap: 4px;
border-radius: 4px;
border: 1px solid var(--Primary-Red-Orange-1, #FB2F2F);
background: transparent;
color: var(--Primary-Red-Orange-1, #FB2F2F);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 114.286% */
cursor: pointer;
${props => props.variant === 'red' ?
        `background: var(--Primary-Red-Orange-1, #FB2F2F);
 color: var(--Neutral-Gray, #F1F4F7);
 & svg {
    width: 16px;
    height: 16px;
    & path  {
    fill: #f1f4f7;
    }  
}
 `
        : null}
`
export const SalesTaxCard = styled.div`
display: flex;
padding: 12px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-radius: 4px;
border: 1px dashed var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Primary-Light-Alabaster-1, #F5F5F5);
`
export const EligibleItemsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
& div.no-border-v2 {
	border: none!important;
}

&.menus-v2 {
    justify-content: flex-start;
    padding-bottom: 81px;
    & > div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    & div.no-border-v2 .flex-line  {
        padding: 16px;
        border-radius: 4px;
        background: var(--Neutral-Gray, #F1F4F7);
    }

    @media (min-width: 768px) {
        padding-bottom: 0px;
    }
}
`
export const EligibleItem = styled.div`
display: flex;
align-items: center;
align-self: stretch;
`
export const EligibleItemIconWrapper = styled.div`
display: flex;
padding: 12px;
align-items: center;
gap: 10px;
`
export const EligibleItemContentWrapper = styled.div`
display: flex;
padding: 12px 12px 12px 0px;
align-items: center;
gap: 8px;
flex: 1 0 0;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
`
export const OpeningHoursContainer = styled.div`
display: flex;
padding-bottom: 24px;
padding-top: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;

& .width200 {
    width: 100% !important;
}

& button[class^="button__ButtonComponent"] {
    height: 48px!important;
}
`

export const OpeningHoursCard = styled.div`
display: flex;
padding: 12px;
align-items: center;
gap: 16px;
align-self: stretch;
border-radius: 4px;
border: 1px dashed var(--Secondary-Light-Grey-1, #DFDFDF);
background: var(--Primary-Light-Alabaster-1, #F5F5F5);
`
export const OpeningHoursCardWrapper = styled.div`
display: flex;
align-items: center;
gap: 16px;
flex: 1 0 0;
justify-content: space-between;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
`

export const OpeningHoursCardScheduleWrapper = styled.div`
display: flex;
width: 120px;
height: 24px;
justify-content: flex-end;
align-items: center;
gap: 8px;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
`

export const OpeningHoursCardDatesWrapper = styled.div`
display: flex;
align-items: center;
gap: 16px;
flex: 1 0 0;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
`

export const MobileModal = styled.div<{ customBackground?: string, isStaff?: boolean, size?: string }>`
display: flex;
width: 100%;
flex-direction: column;
justify-content: flex-end;
align-items: flex-end;
background: ${({ customBackground }) => customBackground ? customBackground : "rgba(28, 28, 28, 0.20)"};
position: fixed;
height: 100%;
z-index: 100;
bottom: 0px;
right: 0;
gap:${({ isStaff }) => isStaff ? '16px' : "0"};
@media (min-width: 768px) {
    left: 0px;
    justify-content: center;

    &.menus-v2 {
        display: none;
    }
}

&.dashboard-filter-modal {
    &.full-mobile {
        & div[class^="settings-v2__MobileModalContent"] {
                max-width: 100%;
                width: 100%;
                margin: 0 0 0 auto;
                height: 100vh;
                justify-content: start;
                border-radius: unset;
                position: relative;
                overflow: auto;
                & div[class^="settings-v2__MobileModalFooter"] {
                    padding-top: 16px;
                    border-top: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
                    right: 0px;
                    padding-left: 16px;
                    padding-right: 16px;
                    position: fixed;
                    bottom: 16px;
                    width: 100%;

                    & div[class^="settings-v2__SettingsActionButtonWrapper"] {
                        z-index: 1;
                    }
                }

                & div[class^="settings-v2__SettingsCustomOptionsContainer"] {
                    flex-direction: column;
                }

                @media(min-width: 768px) {
                    max-width: 420px;
                    & div[class^="settings-v2__MobileModalFooter"] {
                        max-width: 420px;
                    }
                }
            }
    }
    @media (min-width: 768px) {
            & div[class^="settings-v2__MobileModalContent"] {
                max-width: 420px;
                width: 100%;
                margin: 0 0 0 auto;
                height: 100vh;
                justify-content: start;
                border-radius: unset;
                position: relative;
                overflow: auto;

                & div[class^="settings-v2__MobileModalFooter"] {
                    padding-top: 16px;
                    border-top: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
                    right: 0px;
                    padding-left: 16px;
                    padding-right: 16px;
                    position: fixed;
                    bottom: 16px;
                    width: 420px;

                    & div[class^="settings-v2__SettingsActionButtonWrapper"] {
                        z-index: 1;
                    }
                }

                & div[class^="settings-v2__SettingsCustomOptionsContainer"] {
                    flex-direction: column;
                }

                & .DayPicker {
                    width: 100%;
                }
            }

          
    }
}
`

export const MobileModalContent = styled.div<{ size?: string }>`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px 8px 0px 0px;
background: #FFF;
border-top: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);

@media (min-width: 768px) {
    max-width: 894px;
    width: 100%;
    margin: 0 auto;

    ${props => props.size == 'sm' ?
        `max-width: 428px;`
        : null}
}
`

export const MobileModalHeader = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
justify-content: space-between;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
& svg {
    cursor: pointer;
}
`
export const MobileModalBody = styled.div`
display: flex;
padding: 16px 0px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;

& p {
    color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
`

export const MobileModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`

export const SettingSwitchWithTextContainer = styled.div<{ error?: any }>`
display: flex;
padding-bottom: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-bottom: 1px dashed var(--Secondary-Light-Grey-1, #DFDFDF);
& p {
    display: flex;
align-items: flex-start;
gap: 10px;
align-self: stretch;
color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}
@media (min-width: 768px) {
	flex: 50%;

    &.flex-half {
        flex: 0 0 calc(50% - 12px);
    }

	&.flex-full {
		flex: 100%;
	}
}

${props => props.error ? `

    & div[class^="settings-v2__SettingsFormLabelContainer"],
    & div[class^="settings-v2__SettingsFormInputContainer"],
    & div[class^="settings-v2__SettingsSwitchContainer"]  {
        border-color: var(--Status-Cancelled, #BC362F);
        color:var(--Status-Cancelled, #BC362F);
        & div[class^="settings-v2__SettingsFormLabelLeftContainer"],
        & input, & textarea {
            color:var(--Status-Cancelled, #BC362F);
        }
        & svg path {
            fill: var(--Status-Cancelled, #BC362F);
        }
    }
    &::after {
        content: "${props.error}";
        display: block;
        width: 100%;
        position: relative;
        color:var(--Status-Cancelled, #BC362F);
        font-size: .75rem;
    }
    

 
`
        : null}
`

export const OptionalText = styled.span`
color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
`

export const AddNewFieldButton = styled.button`
    display: flex;
    border: none;
    background-color: #FB2F2F;
    font-weight: 600;
    color: #F1F4F7;
    border-radius: 4px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    width: 200px;
`

export const SettingsHeader = styled.div`
display: none;
padding: 12px 24px;
align-items: center;
justify-content: space-between;
gap: 16px;
align-self: stretch;
background: #FFF;
@media (min-width: 768px) {
    display: flex;
}
`

export const SettingsHeaderTextContainer = styled.div`
display: flex;
align-items: center;
gap: 16px;
flex: 1 0 0;
flex-wrap: wrap;
`

export const SettingsHeaderTextWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
`

export const SettingsHeaderTextTitle = styled.div`
align-self: stretch;
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
text-transform: capitalize;
`

export const SettingsHeaderTextBreadcrumbs = styled.div`
display: flex;
height: auto;
flex-wrap: wrap;
flex-direction: column;
justify-content: center;
align-self: stretch;

color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
text-transform: capitalize;
`
export const SettingsHeaderMenuContainer = styled.div`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;

border-radius: 4px;
background: var(--Neutral-Gray, #F1F4F7);

& div[class^="tooltip__TooltipWrapper"] {
    padding-left: 8px;
    padding-right: 8px;
}

& div.separator {
    background: #dfdfdf;
    width: 1px;
    height: 24px;
}

&.desktop-only {
    display: none;

    @media (min-width: 1024px) {
        display: flex;
    }
}
`

export const SettingsHeaderMenuItem = styled.div`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
cursor: pointer;

border-radius: 4px;
background: var(--Neutral-Gray, #F1F4F7);

& > * {
    color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
`
export const SaveButtonWrapper = styled.div`
.mobile-view {
  display: none;
}
@media (max-width: 767px) {
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: flex;
    padding: none;
  }
}
`;

interface Props { }
interface State { }

@inject("store") @observer
export class RestaurantSettingsV2 extends MobxComponent<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {
        this.injected.store.setActiveMobileMenu('settings')
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        document.getElementById('scroll-root')?.scroll({ top: 0, behavior: 'smooth' });
    }

    componentWillUnmount(): void {
        this.injected.store.setActiveMobileMenu('', [])
    }


    render() {
        const { store } = this.injected;
        const { saveRestaurant } = this;
        const { activeMobileMenu, activeMobileModal, setActiveDesignMode } = store;
        const pathname = store.router.s.path;
        const pathNameLength = store.router.s.path.split("/").length - 1;
        const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
        return (<>
            <SettingsHeader>
                <SettingsHeaderTextContainer>
                    <SettingsHeaderTextWrapper>
                        <SettingsHeaderTextTitle>{activeMobileMenu.name.replace(/-/g, ' ')}</SettingsHeaderTextTitle>
                        {activeMobileMenu.referrer.length > 0 &&
                            <SettingsHeaderTextBreadcrumbs style={{ flexDirection: 'row', justifyContent: 'start', gap: 4 }}>
                                {activeMobileMenu.referrer
                                    .map((r, index) => <span
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!,
                                            Array.from(new Set(store.activeMobileMenu.referrer))
                                        )}>
                                        {r.replace(/-/g, ' ')} {index !== (activeMobileMenu.referrer.length - 1) ? '/' : ''}
                                    </span>)} / {activeMobileMenu.name.replace(/-/g, ' ')}
                            </SettingsHeaderTextBreadcrumbs>}
                    </SettingsHeaderTextWrapper>
                </SettingsHeaderTextContainer>
                {pathNameEnd == 'settings' &&
                    <SettingsHeaderMenuContainer>
                        <span onClick={() => { }}>
                            <IconMoreOptions />
                        </span>
                    </SettingsHeaderMenuContainer>
                }

                {store.activeMobileMenu.name == 'edit-promo' &&
                    <SettingsHeaderMenuContainer>
                        <span onClick={() => store.setActiveMobileModal('edit-promo')}>
                            <IconMoreOptions />
                        </span>
                    </SettingsHeaderMenuContainer>
                }
                {store.activeMobileMenu.name == 'edit-printer' &&
                    <SettingsHeaderMenuContainer>
                        <span onClick={() => store.setActiveMobileModal('edit-printer')}>
                            <IconMoreOptions />
                        </span>
                    </SettingsHeaderMenuContainer>
                }

                {store.activeMobileMenu.name == 'website' &&
                    <SettingsHeaderMenuContainer className='desktop-only' style={{ padding: 0, background: 'unset' }}>
                        <SettingsHeaderMenuItem
                            onClick={() => {
                                setActiveDesignMode(true)
                            }}>
                            <IconDesignMode /> Design Mode
                        </SettingsHeaderMenuItem>
                        <SettingsHeaderMenuItem>
                            <IconMoreOptions />
                        </SettingsHeaderMenuItem>
                    </SettingsHeaderMenuContainer>
                }
            </SettingsHeader>
            <MainContainer className={(store.organisation && store.organisation.dashboard) || ''}>
                <ContentContainer>
                    {activeMobileMenu.name === 'system' &&
                        <>
                            <SettingsMenuContainer className="border-bottom-mobile">
                                <SettingsMenuRowHeader>Business</SettingsMenuRowHeader>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('general', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemGeneral />
                                            </IconWrapperRounded>
                                            <p>General</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('location', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemLocation />
                                            </IconWrapperRounded>
                                            <p>Location</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('promo-codes', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemPromo />
                                            </IconWrapperRounded>
                                            <p>Promo Codes</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('conditional-fees', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemConditionalFees />
                                            </IconWrapperRounded>
                                            <p>Conditional Fees</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('policies-&-agreements', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemPolicy />
                                            </IconWrapperRounded>
                                            <p>Policies & Agreements</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                            </SettingsMenuContainer>
                            <SettingsMenuContainer>
                                <SettingsMenuRowHeader>Functionality</SettingsMenuRowHeader>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('receipt-printing', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemReceipt />
                                            </IconWrapperRounded>
                                            <p>Receipt Printing</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('email-notification', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemEmailNotification />
                                            </IconWrapperRounded>
                                            <p>Email Notification</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('audio-notification', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemAudioNotification />
                                            </IconWrapperRounded>
                                            <p>Audio Notification</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('customer-accounts', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemCustomerAccounts />
                                            </IconWrapperRounded>
                                            <p>Customer Accounts</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('age-verification', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemAgeVerification />
                                            </IconWrapperRounded>
                                            <p>Age Verification</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('webhooks', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemWebhooks />
                                            </IconWrapperRounded>
                                            <p>Webhooks</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('tipping', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemTipping />
                                            </IconWrapperRounded>
                                            <p>Tipping</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('ordering', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemOrdering />
                                            </IconWrapperRounded>
                                            <p>Ordering</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                                <SettingsMenuRow onClick={() => store.setActiveMobileMenu('miscellaneous', 'system')}>
                                    <SettingsMenuRowWrapper>
                                        <SettingsMenuRowItem>
                                            <IconWrapperRounded className='icon-wrapper-rounded'>
                                                <IconSystemMisc />
                                            </IconWrapperRounded>
                                            <p>Miscellaneous</p>
                                            <IconArrowForward />
                                        </SettingsMenuRowItem>
                                    </SettingsMenuRowWrapper>
                                </SettingsMenuRow>
                            </SettingsMenuContainer>
                        </>}
                    {
                        activeMobileMenu.name === 'services' &&
                        <SettingsMenuContainer>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('pickup', 'services')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconPickups />
                                        </IconWrapperRounded>
                                        <p>Pickups</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('deliveries', 'services')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconDeliveries />
                                        </IconWrapperRounded>
                                        <p>Deliveries</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('dine-ins', 'services')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconDineIns />
                                        </IconWrapperRounded>
                                        <p>Dine-Ins</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('table-booking', 'services')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconTableBooking />
                                        </IconWrapperRounded>
                                        <p>Table Booking</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                        </SettingsMenuContainer>
                    }
                    {activeMobileMenu.name === 'settings' && (
                        <SettingsMenuContainer>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('system', 'settings')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconSystem />
                                        </IconWrapperRounded>
                                        <p>System</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('services', 'settings')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconServices />
                                        </IconWrapperRounded>
                                        <p>Services</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('payments', 'settings')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconAddCard />
                                        </IconWrapperRounded>
                                        <p>Payments</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('website', 'settings')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconWebsite />
                                        </IconWrapperRounded>
                                        <p>Website</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            <SettingsMenuRow onClick={() => store.setActiveMobileMenu('integrations', 'settings')}>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconIntegrations />
                                        </IconWrapperRounded>
                                        <p>Integrations</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow>
                            {/* <SettingsMenuRow>
                                <SettingsMenuRowWrapper>
                                    <SettingsMenuRowItem>
                                        <IconWrapperRounded className='icon-wrapper-rounded'>
                                            <IconAddons />
                                        </IconWrapperRounded>
                                        <p>Add-Ons</p>
                                        <IconArrowForward />
                                    </SettingsMenuRowItem>
                                </SettingsMenuRowWrapper>
                            </SettingsMenuRow> */}
                        </SettingsMenuContainer>)}

                    {activeMobileMenu.name === 'general' && (<SettingsGeneral />)}
                    {activeMobileMenu.name === 'location' && (<SettingsLocation />)}
                    {(activeMobileMenu.name === 'promo-codes'
                        || activeMobileMenu.referrer.includes('promo-codes'))
                        && (
                            <SettingsPromoCodes
                                activeMobileModal={activeMobileModal}
                                activeMobileMenu={activeMobileMenu.name}
                                saveRestaurant={saveRestaurant}
                                injected={this.injected}
                            />)}

                    {(activeMobileMenu.name === 'conditional-fees'
                        || activeMobileMenu.referrer.includes('conditional-fees'))
                        && (
                            <SettingsConditionalFees
                                activeMobileModal={activeMobileModal}
                                activeMobileMenu={activeMobileMenu.name}
                                saveRestaurant={saveRestaurant}
                                injected={this.injected}
                                conditionalFees={this.injected.store.restaurant?.settings.fees}
                            />)}

                    {(activeMobileMenu.name === 'policies-&-agreements'
                        || activeMobileMenu.referrer.includes('policies-&-agreements'))
                        && (
                            <SettingsPoliciesAgreements
                                activeMobileModal={activeMobileModal}
                                activeMobileMenu={activeMobileMenu.name}
                                saveRestaurant={saveRestaurant}
                                injected={this.injected}
                            />)}

                    {(activeMobileMenu.name === 'receipt-printing'
                        || activeMobileMenu.referrer.includes('receipt-printing')
                        || activeMobileMenu.referrer.includes('printer-setup')
                        || activeMobileMenu.referrer.includes('add-printer')) &&
                        <SettingsReceiptPrinting
                            activeMobileModal={activeMobileModal}
                            saveRestaurant={saveRestaurant}
                            injected={this.injected}
                            activeMobileMenu={activeMobileMenu.name} />}

                    {(activeMobileMenu.name === 'miscellaneous') && <SettingsMiscellaneous />}
                    {(activeMobileMenu.name === 'ordering') && <SettingsOrdering />}
                    {(activeMobileMenu.name === 'tipping') && <SettingsTipping />}
                    {(activeMobileMenu.name === 'webhooks'
                        || activeMobileMenu.referrer.includes('webhooks')) &&
                        <SettingsWebhooks
                            activeMobileMenu={activeMobileMenu.name}
                            saveRestaurant={saveRestaurant}
                            injected={this.injected}
                            webhooks={this.injected.store.restaurant?.webhooks}
                        />}
                    {(activeMobileMenu.name === 'age-verification') && <SettingsAgeVerification />}
                    {(activeMobileMenu.name === 'customer-accounts') && <SettingsCustomerAccounts />}
                    {(activeMobileMenu.name === 'audio-notification') && <SettingsAudioNotification />}
                    {(activeMobileMenu.name === 'email-notification') &&
                        <SettingsEmailNotification injected={this.injected} />}
                    {activeMobileMenu.name === 'pickup' && (<PickUpServices />)}
                    {activeMobileMenu.name === 'general-pickup' && (
                        <GeneralPickUpServices
                            injected={this.injected}
                        />
                    )}
                    {activeMobileMenu.name === 'customer-info' && (
                        <CustomerInfoServices
                            referrer={activeMobileMenu.referrer}
                            injected={this.injected}
                        />
                    )}
                    {activeMobileMenu.name === 'order-timing' && (
                        <OrderTimingServices
                            referrer={activeMobileMenu.referrer}
                            injected={this.injected}
                        />)}
                    {activeMobileMenu.name === 'available-hours' && (
                        <AvailableHoursServices
                            referrer={activeMobileMenu.referrer}
                            injected={this.injected} />
                    )}
                    {activeMobileMenu.name === 'wait-times-&-auto-statuses' && (
                        <WaitTimesAutoStatusServices
                            referrer={activeMobileMenu.referrer}
                            injected={this.injected}
                        />
                    )}
                    {(activeMobileMenu.name === 'custom-checkout-field' ||
                        activeMobileMenu.name === 'edit-custom-checkout-field') && (
                            <CustomCheckoutFields
                                referrer={activeMobileMenu.referrer}
                                injected={this.injected}
                            />
                        )}
                    {activeMobileMenu.name === 'tookan' && (
                        <TookanServices
                            injected={this.injected}
                        />)}

                    {(activeMobileMenu.name === 'payments') && <PaymentSettings />}
                    {activeMobileMenu.name === 'deliveries' && (<DeliveriesServices />)}
                    {activeMobileMenu.name === 'conditions' && (
                        <ConditionDeliveryServices
                            injected={this.injected}
                        />)}
                    {activeMobileMenu.name === 'fee' && (
                        <DeliveriesFeeServices
                            injected={this.injected}
                        />
                    )}
                    {activeMobileMenu.name === 'general-deliveries' && (
                        <GeneralDeliveriesServices
                            injected={this.injected}
                        />
                    )}
                    {activeMobileMenu.name === 'zones' && (
                        <ZonesDeliveryServices
                            injected={this.injected}
                        />)}
                    {activeMobileMenu.name === 'dine-ins' && (<DineInsServices />)}
                    {activeMobileMenu.name === 'general-dineins' && (
                        <GeneralDineInsServices
                            injected={this.injected}
                        />
                    )}
                    {activeMobileMenu.name === 'tables' && (
                        <TablesDineIns
                            injected={this.injected}
                        />
                    )}
                    {activeMobileMenu.name === 'table-booking' && (<TablesBookingServices />)}
                    {activeMobileMenu.name === 'general-tables' && (<GeneralTableBookingServices injected={this.injected} />)}
                    {activeMobileMenu.name === 'booking-timing' && (<BookingTimingServices injected={this.injected} />)}
                    {activeMobileMenu.name === 'integrations' && (<IntegrationsSettings />)}

                    {(activeMobileMenu.name === 'Facebook Pixel'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', paddingBottom: 83, width: '100%' }}>
                            <SettingsFormIntegrationFacebookPixel />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Google Analytics'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', paddingBottom: 83, width: '100%' }}>
                            <SettingsFormIntegrationGoogleAnalytics />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Tookan'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', paddingBottom: 83, width: '100%' }}>
                            <SettingsFormIntegrationTookan />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Shipday'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', paddingBottom: 83, width: '100%' }}>
                            <SettingsFormIntegrationQuestTag />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Abacus'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', paddingBottom: 83, width: '100%' }}>
                            <SettingsFormIntegrationAbacus />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Postmates'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', padding: '20px 20px 83px 20px', width: '100%' }}>
                            <SettingsFormIntegrationPostmates />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Lalamove'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', padding: '20px 20px 83px 20px', width: '100%' }}>
                            <SettingsFormIntegrationLalamove />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Walletly'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', padding: '20px 20px 83px 20px', width: '100%' }}>
                            <SettingsFormIntegrationWalletly />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Uber'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', padding: '20px 20px 83px 20px', width: '100%' }}>
                            <SettingsFormIntegrationUber />
                        </div>
                    }
                    {(activeMobileMenu.name === 'Kounta'
                        && activeMobileMenu.referrer.includes('integrations')) &&
                        <div style={{ background: '#fff', padding: '20px 20px 83px 20px', width: '100%' }}>
                            <SettingsFormIntegrationsKounta
                                restaurant={store.restaurant!}
                                app={store.activeBaseApp!}
                            />
                        </div>
                    }

                    {activeMobileMenu.name === 'website' && (
                        <SettingsWebsite
                            activeDesignMode={store.activeDesignMode}
                            setActiveDesignMode={store.setActiveDesignMode}
                            saveRestaurant={saveRestaurant}
                            injected={this.injected}
                            restaurant={store.restaurant!}
                        />)}
                    {(activeMobileMenu.name === 'themes'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteTheme
                            saveRestaurant={saveRestaurant}
                            injected={this.injected}
                            restaurant={store.restaurant!} />
                    }
                    {(activeMobileMenu.name === 'colors'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteColors />
                    }
                    {(activeMobileMenu.name === 'fonts'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteFonts />
                    }
                    {(activeMobileMenu.name === 'top-nav-bar'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteTopNavBar />
                    }
                    {(activeMobileMenu.name === 'menu-nav-bar'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteMenuNavBar />
                    }
                    {(activeMobileMenu.name === 'header'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteHeader />
                    }
                    {(activeMobileMenu.name === 'items'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteItems />
                    }
                    {(activeMobileMenu.name === 'item-layout'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteItemLayout />
                    }
                    {(activeMobileMenu.name === 'footer'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteFooter />
                    }
                    {(activeMobileMenu.name === 'mobile'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteMobile />
                    }
                    {(activeMobileMenu.name === 'alert'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteAlert />
                    }
                    {(activeMobileMenu.name === 'domain'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteDomain injected={this.injected} />
                    }
                    {(activeMobileMenu.name === 'favicon'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteFavicon />
                    }
                    {(activeMobileMenu.name === 'search-engine-optimization'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteSEO />
                    }
                    {(activeMobileMenu.name === 'social-media-links'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteSocial />
                    }
                    {(activeMobileMenu.name === 'custom-css-&-javascript'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteCustom />
                    }
                    {(activeMobileMenu.name === 'misc'
                        && activeMobileMenu.referrer.includes('website')) &&
                        <SettingsWebsiteMisc />
                    }
                </ContentContainer>

            </MainContainer>
        </>)
    }
}