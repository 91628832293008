import * as React from "react";
import shortid from "shortid";
import cloneDeep from "lodash/cloneDeep";
import { Modal, Tooltip } from "@lib/components";
import { ModalContent } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "react-sortable-hoc";
import { logger, RestaurantUtils, Untrusive } from "@lib/common";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Tag } from "@lib/components";
import { Checkbox } from "@lib/components";

import { getNextDayOpenTime, isDishPaused } from "../orders/sidebars/pauseServicesSidebar/constants";
import { FaFlushed } from "react-icons/fa"
import { UI } from "../../../../../core/ui";
import { ResumeModal } from "../menus/components/ResumeModal";
import { MENU_ITEM_TYPE, MENU_COMPONENTS } from "../menus/constant";
import { RestaurantFormCategory } from "../menus/forms/category";
import { RestaurantFormDish } from "../menus/forms/dish";
import { RestaurantFormMenu } from "../menus/forms/menu";
import { MenuListButton } from "../menus/layout/button";
import { MenuItemText, MenuListFloatingButtonWrapper, MenuListFloatingContent, MenuListFloatingTitle } from "../menus/layout/floating-button";
import { MenuListItem } from "../menus/layout/item";
import { MenuList } from "../menus/layout/list";
import { PauseSidebar } from "../menus/pauseItemsSidebar";
import { ItemButton, MenuListItemV2 } from "./item-v2";
import { RestaurantFormMenuV2 } from "./menu_v2";
import { TabGroup, TabItem } from "../settings-v2/receipt-printing";
import { RestaurantFormCategoryV2 } from "./category_v2";
import { RestaurantFormDishV2 } from "./dish-v2";
import { MenuCardTitle } from ".";
import { BottomMenuControls } from "../menus/mobileView/menuList/bottomMenuControls";
export type MenuComponents = "menu" | "category" | "item";

interface RestaurantDishWithTaxes
	extends T.Schema.Restaurant.Menu.RestaurantDish { }

type MenusEditMode = "normal" | "reOrder" | "editPrices";

interface Props { }
interface State {
	panel: MenuComponents | null;
	panel_index: number[];
	panel_pause: MenuComponents | null;
	panel_index_pause: number[];
	copy_type: MenuComponents | null;
	copy_index: number[];
	copy_loading: boolean;
	remove_type: MenuComponents | null;
	remove_index: number[];
	remove_loading: boolean;
	mode: MenusEditMode;
	loading: boolean;
	selected_dishes: string[];
	selected_loading: boolean;
	error: string | null;
	currentMenuItemId: string;
	currentMenuItem: T.Schema.Restaurant.Menu.RestaurantDish | null;
	currentMenuItems: T.Schema.Restaurant.Menu.RestaurantDish[];
	resumeModalOpen: boolean;
	oneTimeRenderFlag: boolean;
	activeDeleteItemModal: boolean;
	activeMobile: string | null;
	showBottomControl: string | null;
}

@inject("store")
@observer
export class RestaurantMenuListV2 extends MobxComponent<Props, State> {
	menu_backup: T.Schema.Restaurant.RestaurantSchema["menus"] | null;
	interval: number | null = null

	constructor(props: Props) {
		super(props);
		this.state = {
			panel: null,
			panel_index: [],
			copy_type: null,
			copy_index: [],
			copy_loading: false,
			remove_type: null,
			remove_index: [],
			remove_loading: false,
			mode: "normal",
			loading: false,
			selected_dishes: [],
			selected_loading: false,
			error: null,
			activeDeleteItemModal: false,
			panel_pause: null,
			panel_index_pause: [],
			currentMenuItemId: "",
			currentMenuItem: null,
			currentMenuItems: [],
			resumeModalOpen: false,
			oneTimeRenderFlag: true,
			activeMobile: '',
			showBottomControl: ''
	};
		this.menu_backup = null;
		this.interval = null;
	}

	componentWillUnmount() {
		if (this.state.mode !== "normal") {
			this.cancelEditMode();
		}
		if (this.interval) {
			clearInterval(this.interval);
		}
	}


	setPanel = (panel: MenuComponents | null, panel_index: number[]) => {
		this.setState({ panel, panel_index });
	};

	setOnPause = (panel: MenuComponents | null, panel_index: number[], id: string, item: T.Schema.Restaurant.Menu.RestaurantDish | null, items: T.Schema.Restaurant.Menu.RestaurantDish[]) => {
		this.setState({ panel_pause: panel, panel_index_pause: panel_index, currentMenuItemId: id, currentMenuItem: item, currentMenuItems: items });
	};

	create_menu = (): T.Schema.Restaurant.Menu.RestaurantMenu => {
		const _id = shortid.generate();
		return {
			_id,
			name: "",
			display_name: "",
			description: "",
			conditions: {
				hide_unavailable_menu: false,
				services: [],
				times: [],
				hours: [],
				pre_order: {
					enabled: false,
					days_in_advance: "",
					cutoff_time: "",
				},
				age_restricted: false,
			},
			categories: [],
			//@ts-ignore
			kounta: {
				kounta_sync_export_enabled: false,
				// kounta_username: "",
				// kounta_password: "",
				// kounta_site: ""
			},
		};
	};
	create_category(
		menu_id: string
	): T.Schema.Restaurant.Menu.RestaurantCategory {
		return {
			_id: shortid.generate(),
			menu_id,
			name: "",
			display_name: "",
			description: "",
			dishes: [],
			conditions: {
				hide_unavailable_category: false,
				services: [],
				hours: [],
			},
		};
	}
	create_dish(menu_id: string, category_id: string): RestaurantDishWithTaxes {
		const r = this.injected.store.restaurant!;
		const taxRates = r.settings.region.tax.rates ?? []
		const taxes = taxRates.map((rate) => {
			return rate._id;
		});
		return {
			type: "standard",
			_id: shortid.generate(),
			menu_id: menu_id,
			category_id: category_id,
			name: "",
			display_name: "",
			description: "",
			subtitle: "",
			price: "" as any,
			tags: [],
			image: null,
			status: null,
			// STANDARD
			ingredients: [],
			option_sets: [],
			// COMBO
			choices: [],
			price_type: "standard",
			taxes: taxes,
		};
	}

	generateCreateMenuButton = () => {
		const { store } = this.injected;
		return (
			<TabItem
				key="btn-create"
				onClick={() => store.setActiveMobileMenu('create-menu', 'menus', {panel: 'menu', index: -1})}
				className="footer-button"
			>
				Create New Menu
			</TabItem>
		);
	}

	list_menu = () => {
		const { store } = this.injected;
		const { activeMobileMenu } = store;
		const { loading: loading, mode } = this.state;
		const r = this.injected.store.restaurant!;
		const { menus } = r;
		const { pausedMenuItems } = this.injected.store;

		const menuIds = pausedMenuItems.map(dish => dish?.menu_id);

		return (
			<>
				{(activeMobileMenu.name !== 'create-menu' 
				&& activeMobileMenu.name !== 'edit-menu' 
				&& activeMobileMenu.name !== 'create-category'
				 && activeMobileMenu.name !== 'edit-category'
				 && activeMobileMenu.name !== 'create-item'
				 && activeMobileMenu.name !== 'edit-item') && (
					<>
					
					<MenuList id="menu-list" type={MENU_ITEM_TYPE.MENU}>
					{menus.map((menu, i) => {
						const totalItems = menu.categories.map(item => item.dishes).flat().length
						return (

							<MenuListItemV2
								key={menu._id}
								type={MENU_ITEM_TYPE.MENU}
								id={menu._id}
								mode={mode}
								onClick={() => this.setState({ activeMobile: menu._id, showBottomControl: 'menu' })}
								active={this.state.activeMobile || ''}
								isMenu
								name={	
									<MenuCardTitle className="m-l-3" style={{ wordBreak: 'break-all'}}>
											{menu.name}
									</MenuCardTitle>
								}
								index={i}
								onEdit={() => { store.setActiveMobileMenu('edit-menu', 'menus', {panel: 'menu', index: i});}}
								onRemove={() =>
									this.modal_remove_open("menu", [i])
								}
								onCopy={() => this.modal_copy_open("menu", [i])}
								isPausing={menuIds.includes(menu._id)}
								totalItems={totalItems}
								totalPausedItems={pausedMenuItems.filter(pausedItem => pausedItem?.menu_id === menu._id).length}
							>
								{this.list_category(i)}
								{this.state.showBottomControl === 'menu' && (
										<BottomMenuControls
											onEdit={() => { store.setActiveMobileMenu('edit-menu', 'menus', {panel: 'menu', index: i});}}
											onRemove={() => this.modal_remove_open("menu", [i])}
											onCopy={() => this.modal_copy_open("menu", [i])}
											onCancel={() => this.setState({ activeMobile: '', showBottomControl: '' })}
									/>
									)}  
							</MenuListItemV2>
						);
					})}
					</MenuList>
					{mode === "normal" && (
						<>
							{menus.length > 0 ? (
								<TabGroup className='menus menus-end' style={{ overflow: 'unset', marginBottom: '50px'}}>
									{this.generateCreateMenuButton()}

										<TabItem
											key="btn-re-arrange"
											onClick={() =>
												this.startEditMode("reOrder")
											}
											className="footer-button"
										>
											Re-Arrange
										</TabItem>

										<TabItem
											key="btn-edit-prices"
											onClick={() =>
												this.startEditMode("editPrices")
											}
											className="footer-button"
										>
											Edit Prices
										</TabItem>
								</TabGroup>
							) : (
								this.generateCreateMenuButton()
							)}
						</>
					)}

					{mode === "reOrder" &&
						this.saveCancelGroupButton(
							() =>
								this.saveEditMode("Menu Re-arranged Successfully!"),
							this.cancelEditMode,
							loading,
							"Re-Arrange Mode\n\nClick + hold to drag and drop items to re-order them"
						)}

					{mode === "editPrices" &&
						this.saveCancelGroupButton(
							() => this.saveEditMode("Prices Updated Successfully!"),
							this.cancelEditMode,
							loading,
						"Bulk Edit Prices Mode"
					)}
					</>)}
			</>
		);
	};
	list_category = (menuIndex: number) => {
		const { mode } = this.state;
		const r = this.injected.store.restaurant!;
		const menu = r.menus[menuIndex];
		const { pausedMenuItems } = this.injected.store;
		const pauseIds = pausedMenuItems.map(d => d?._id);
		const { store } = this.injected;
		const { activeMobileMenu } = store;

		const checkCateIsPausing = (category: T.Schema.Restaurant.Menu.RestaurantCategory) => {
			if (category && category.dishes && category.dishes.length) {
				return category.dishes.map(cate => cate._id).filter(d => pauseIds.includes(d)).length
			}
		};

		return (
			<>
			{(activeMobileMenu.name !== 'create-category' && activeMobileMenu.name !== 'edit-category') && (
			<MenuList id={menu._id} type={MENU_ITEM_TYPE.CATEGORY}>
				{menu.categories.map((category, i) => {
					const totalItems = category.dishes.filter(cate => cate._id).length;
					const isAbacusOpenItemsAvailable =
						!!r.abacus?.abacus_status &&
						!!r.abacus?.abacus_open_items;

					const noAbacusOpenItem =
						isAbacusOpenItemsAvailable && !category.abacus_open_item
							? "No Abacus Open Item"
							: "";

					return (
						<MenuListItemV2
							key={category._id}
							id={category._id}
							type={MENU_ITEM_TYPE.CATEGORY}
							onClick={() => this.setState({ activeMobile: category._id, showBottomControl: 'category' })}
							active={this.state.activeMobile || ''}
							mode={mode}
							isMenu
							name={
								<div className="flex-l-r-center width100">
									<div className="flex-line centered">
										<MenuCardTitle className="m-l-3" >
											{category.name}
										</MenuCardTitle>
									</div>

									{noAbacusOpenItem &&
										<Tooltip text="Please edit this category and set the Abacus Open Item" width={360} position="top">
											<Tag className="m-r-2">{noAbacusOpenItem}</Tag>
										</Tooltip>
									}
								</div>
							}
							index={i}
							onEdit={() =>
								{ this.setPanel("category", [menuIndex, i]);  store.setActiveMobileMenu('edit-category', 'menus', {panel: 'category', menuIndex: menuIndex, categoryIndex: i});}
							}
							onRemove={() =>
								this.modal_remove_open("category", [
									menuIndex,
									i,
								])
							}
							onCopy={() =>
								this.modal_copy_open("category", [menuIndex, i])
							}
							isPausing={!!checkCateIsPausing(category)}
							totalItems={totalItems}
							totalPausedItems={checkCateIsPausing(category)}
						>
							{this.list_dish(menuIndex, i)}
							{this.state.showBottomControl === 'category' && (
								<BottomMenuControls
									onEdit={() =>{ this.setPanel("category", [menuIndex, i]);  store.setActiveMobileMenu('edit-category', 'menus', {panel: 'category', menuIndex: menuIndex, categoryIndex: i});}}
									onRemove={() => this.modal_remove_open("category", [menuIndex, i,])}
									onCopy={() => this.modal_copy_open("category", [menuIndex, i])}
									onCancel={() => this.setState({ activeMobile: '', showBottomControl: '' })}
							/>
							)}
						</MenuListItemV2>
					);
				})}
				{mode === "normal" && (
					<TabGroup className='menus menus-end' style={{ overflow: 'unset', marginBottom: '50px'}}>
						<TabItem
						className="m-t-4"
						onClick={() => store.setActiveMobileMenu('create-category', 'menus', { panel: 'category', menuIndex: menuIndex, categoryIndex: -1})
						}
					>
						Create New Category
					</TabItem>
					</TabGroup>
					
				)}
			</MenuList>)}
			</>
		);
	};
	list_dish = (menuIndex: number, categoryIndex: number) => {
		const { selected_dishes } = this.state;
		const { mode } = this.state;
		const r = this.injected.store.restaurant!;
		const rs = this.injected.store.restaurant_stock!;
		const menu = r.menus[menuIndex];
		const category = menu.categories[categoryIndex];
		const currency = this.injected.store.intl.s.currency;
		const { pausedMenuItems } = this.injected.store;
		const { store } = this.injected;
		const { activeMobileMenu } = store
		
		return (
			<>
			{(activeMobileMenu.name !== 'create-item' && activeMobileMenu.name !== 'edit-item') && (<MenuList id={category._id} type={MENU_ITEM_TYPE.DISH}>
				{category.dishes.map((item, i) => {
					const stock = rs.dishes[item._id];
					let tag;

					if (item.status) {
						tag =
							item.status === "hidden"
								? "Hidden"
								: item.status === "no-stock"
									? "No Stock"
									: undefined;
					} else if (typeof stock !== "undefined") {
						tag = stock <= 0 ? `No Stock` : `${stock} units`;
					}

					const isPausing = pausedMenuItems.length > 0 && !!pausedMenuItems.find(d => d?._id === item._id)

					return (
						<MenuListItemV2
							key={item._id}
							id={item._id}
							onClick={() => this.setState({ activeMobile: item._id, showBottomControl: 'item' })}
							active={this.state.activeMobile || ''}
							type={MENU_ITEM_TYPE.DISH}
							mode={mode}
							isMenu
							name={
								<div className="flex-l-r-center width100">
									<div className="flex-line centered">
										{mode === "normal" && (
											<Checkbox
												id={`dish-${item._id}`}
												checked={
													selected_dishes.indexOf(
														item._id
													) !== -1
												}
												onChange={(e) => {
													e.stopPropagation();
													this.toggle_selected_dish(
														item._id
													);
												}}
											/>
										)}
										<MenuCardTitle className="m-l-3" >{item.name}</MenuCardTitle>
									</div>

									{tag && <Tag className="m-r-2">{tag}</Tag>}
								</div>
							}
							index={i}
							buttons={
								(r.reseller_id === "curbsidepivot" &&
									<Tooltip text="Stock" width={60} position="top">
										<ItemButton
											type="button"
											color="white"
											className="no-round no-border width32"
											paddinglr={5}
											onClick={(e) => {
												e.stopPropagation();
												this.dish_stock_update(item._id);
											}}
										>
										</ItemButton>
									</Tooltip>
								)
							}
							price={item.price}
							onEdit={() =>
							{ this.setPanel("item", [menuIndex, categoryIndex, i]); 
								store.setActiveMobileMenu('edit-item', 'menus', {panel: 'item', menuIndex: menuIndex, categoryIndex: categoryIndex, dishIndex: i})
							}}
							onPause={() =>
								this.setOnPause("item", [
									menuIndex,
									categoryIndex,
									i,
								], item._id, item, [item])
							}
							onCopy={() =>
								this.modal_copy_open("item", [
									menuIndex,
									categoryIndex,
									i,
								])
							}
							currency={currency}
							onPriceEdit={(price) => {
								r.menus[menuIndex].categories[
									categoryIndex
								].dishes[i].price = price;
							}}
							isPausing={isPausing}
							isPauseInfinity={item?.pause_infinite}
							pauseDuration={item.pause_duration}
							pauseStart={item.pause_start}
							isItem
						>
							{this.state.showBottomControl === 'item' && (
								<BottomMenuControls
									onEdit={() =>
										{ this.setPanel("item", [menuIndex, categoryIndex, i]); 
											store.setActiveMobileMenu('edit-item', 'menus', {panel: 'item', menuIndex: menuIndex, categoryIndex: categoryIndex, dishIndex: i})
										}}
									onCopy={() =>
										this.modal_copy_open("item", [
											menuIndex,
											categoryIndex,
											i,
										])}
									onCancel={() => this.setState({ activeMobile: '', showBottomControl: '' })}
							/>
							)}
						</MenuListItemV2>
					);
				})}
				{mode === "normal" && (
					<TabGroup className='menus menus-end' style={{ overflow: 'unset'}}>
						<TabItem
							className="m-t-4"
							onClick={() => store.setActiveMobileMenu('create-item', 'menus', { panel: 'item', menuIndex: menuIndex, categoryIndex: categoryIndex, dishIndex: -1})}
						>
							Create New Item
						</TabItem>
					</TabGroup>
					
				)}
			</MenuList>)}
			</>
			
		);
	};

	panel_menu = () => {
		const r = this.injected.store.restaurant!;
		const { store } = this.injected;
		const { activeMobileMenu } = store;
		
		const { panel, panel_index } = this.state;
		const [menuIndex, categoryIndex, dishIndex] = panel_index;
		const active = activeMobileMenu?.data?.panel === 'menu';
		let initialValues = null;
		if (active) {
			if (store.activeMobileMenu?.data?.index === -1) {
				initialValues = this.create_menu();
			} else {
				const item = r.menus[store.activeMobileMenu?.data?.index];
				initialValues = cloneDeep(item);
			}
		}
		return (
			<RestaurantFormMenuV2
				type={menuIndex === -1 ? "create" : "edit"}
				initialValues={initialValues}
				close={() => this.setPanel(null, [])}
			/>
		);
	};

	panel_category = () => {
		const r = this.injected.store.restaurant!;
		const { panel, panel_index } = this.state;
		const [menuIndex, categoryIndex] = panel_index;
		const { store } = this.injected;
		const { activeMobileMenu } = store;
		const active = activeMobileMenu?.data?.panel === "category";

		let initialValues = null;
		if (active) {
			const menu = r.menus[activeMobileMenu?.data?.menuIndex];
			if (activeMobileMenu?.data?.categoryIndex === -1) {
				initialValues = this.create_category(menu._id);
			} else {
				const item = r.menus[activeMobileMenu?.data?.menuIndex].categories[activeMobileMenu?.data?.categoryIndex];
				initialValues = cloneDeep(item);
			}
		}

		return (
			<RestaurantFormCategoryV2
				type={categoryIndex === -1 ? "create" : "edit"}
				menuIndex={menuIndex}
				initialValues={initialValues}
				close={() => this.setPanel(null, [])}
			/>
		);
	};

	panel_dish = () => {
		const r = this.injected.store.restaurant!;
		const { panel, panel_index } = this.state;
		const [menuIndex, categoryIndex, dishIndex] = panel_index;
		const { store } = this.injected;
		const { activeMobileMenu } = store;
		const active = activeMobileMenu?.data?.panel === "item";
		let initialValues = null;
		if (active) {
			const menu = r.menus[activeMobileMenu?.data?.menuIndex];
			const category = menu.categories[activeMobileMenu?.data?.categoryIndex];
			if (activeMobileMenu?.data?.dishIndex === -1) {
				initialValues = this.create_dish(menu._id, category._id);
			} else {
				const item: RestaurantDishWithTaxes =
					r.menus[activeMobileMenu?.data?.menuIndex].categories[activeMobileMenu?.data?.categoryIndex].dishes[
						activeMobileMenu?.data?.dishIndex
					];
				item.taxes = [];
				initialValues = cloneDeep(item);
			}
		}
		return (
			<RestaurantFormDishV2
				type={activeMobileMenu?.data?.dishIndex === -1 ? "create" : "edit"}
				menuIndex={activeMobileMenu?.data?.menuIndex}
				categoryIndex={activeMobileMenu?.data?.categoryIndex}
				initialValues={initialValues}
				close={() => this.setPanel(null, [])}
			/>
		);
	};

	sidebar_panel_dish = () => {
		const { panel_pause, currentMenuItemId, currentMenuItem, currentMenuItems } = this.state;
		const handleCloseSidebar = () => {
			this.setOnPause(null, [], "", null, []);
			this.setState({
				selected_dishes: []
			});
		}
		const r = this.injected.store.restaurant!;
		const openHours = r.location && r.location.opening_hours ? r.location.opening_hours : [];
		const specialHours = r.location && r.location.special_hours ? r.location.special_hours : [];

		const nextDayOpenTime = getNextDayOpenTime(openHours, specialHours);
		return (
			<PauseSidebar
				isOpen={panel_pause === MENU_COMPONENTS.ITEM}
				close={handleCloseSidebar}
				panel={MENU_COMPONENTS.ITEM}
				currentMenuItemId={currentMenuItemId}
				currentMenuItem={currentMenuItem}
				currentMenuItems={currentMenuItems}
				nextDayOpenTime={nextDayOpenTime}
			/>
		);
	};

	modal_copy = () => {
		const r = this.injected.store.restaurant!;
		const { copy_type, copy_index, copy_loading } = this.state;

		const [menuIndex, categoryIndex, dishIndex] = copy_index;

		let item;
		let copyFn;
		if (copy_type === "menu") {
			item = r.menus[menuIndex];
			copyFn = () => this.menu_copy(menuIndex);
		} else if (copy_type === "category") {
			item = r.menus[menuIndex].categories[categoryIndex];
			copyFn = () => this.category_copy(menuIndex, categoryIndex);
		} else if (copy_type === "item") {
			item =
				r.menus[menuIndex].categories[categoryIndex].dishes[dishIndex];
			copyFn = () => this.dish_copy(menuIndex, categoryIndex, dishIndex);
		}

		return (
			<Modal
				width="sm"
				close={this.modal_copy_close}
				closeButton={false}
				active={!!item}
			>
				<ModalContent className="flex-l-r-center">
					<h4 className="">Copy {copy_type}</h4>
					<p className="big underline">{item && item.name}</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button
						type="button"
						className="m-r-2 width100 max100px"
						onClick={this.modal_copy_close}
					>
						Cancel
					</Button>
					<Button
						type="button"
						className="width100 max100px"
						color="primary"
						onClick={copyFn}
					>
						{copy_loading && (
							<RotateLoader size={2} color="white" />
						)}
						{!copy_loading && "Copy"}
					</Button>
				</ModalContent>
			</Modal>
		);
	};
	modal_remove = () => {
		const r = this.injected.store.restaurant!;
		const { remove_type, remove_index, remove_loading } = this.state;

		const [menuIndex, categoryIndex, dishIndex] = remove_index;

		let item;
		let removeFn;
		if (remove_type === "menu") {
			item = r.menus[menuIndex];
			removeFn = () => this.menu_remove(menuIndex);
		} else if (remove_type === "category") {
			item = r.menus[menuIndex].categories[categoryIndex];
			removeFn = () => this.category_remove(menuIndex, categoryIndex);
		} else if (remove_type === "item") {
			item =
				r.menus[menuIndex].categories[categoryIndex].dishes[dishIndex];
			// @ts-ignore
			const dishId = item._id;
			removeFn = () =>
				this.dish_remove(menuIndex, categoryIndex, dishIndex, dishId);
		}

		return (
			<Modal
				width="sm"
				close={this.modal_remove_close}
				closeButton={false}
				active={!!item}
			>
				<ModalContent className="flex-l-r-center">
					<h4 className="">Delete {remove_type}</h4>
					<p className="big underline">{item && item.name}</p>
				</ModalContent>
				<ModalContent className="flex-right">
					<Button
						type="button"
						className="m-r-2 width100 max100px"
						onClick={this.modal_remove_close}
					>
						Cancel
					</Button>
					<Button
						type="button"
						className="width100 max100px"
						color="primary"
						onClick={removeFn}
					>
						{remove_loading && (
							<RotateLoader size={2} color="white" />
						)}
						{!remove_loading && "Delete"}
					</Button>
				</ModalContent>
			</Modal>
		);
	};

	menu_copy = async (menuIndex: number) => {
		await this.copy({
			successMsg: "Menu copied",
			process: (r) => {
				const menu = cloneDeep(r.menus[menuIndex]);
				const menu_id = shortid.generate();
				menu._id = menu_id;
				menu.name = `${menu.name} - Copy`;
				for (let i = 0; i < menu.categories.length; i++) {
					const category_id = shortid.generate();
					menu.categories[i]._id = category_id;
					menu.categories[i].menu_id = menu_id;
					for (let k = 0; k < menu.categories[i].dishes.length; k++) {
						menu.categories[i].dishes[k]._id = shortid.generate();
						menu.categories[i].dishes[k].menu_id = menu_id;
						menu.categories[i].dishes[k].category_id = category_id;
					}
				}
				r.menus.splice(menuIndex + 1, 0, menu);
			},
		});
	};
	menu_remove = async (menuIndex: number) => {
		await this.remove({
			successMsg: "Menu deleted",
			process: (r) => {
				r.menus.splice(menuIndex, 1);
			},
		});
	};
	category_copy = async (menuIndex: number, categoryIndex: number) => {
		await this.copy({
			successMsg: "Category copied",
			process: (r) => {
				const menu = r.menus[menuIndex];
				const category = cloneDeep(menu.categories[categoryIndex]);
				const category_id = shortid.generate();
				category._id = category_id;
				category.name = `${category.name} - Copy`;
				for (let i = 0; i < category.dishes.length; i++) {
					category.dishes[i]._id = shortid.generate();
					category.dishes[i].category_id = category_id;
				}
				r.menus[menuIndex].categories.splice(
					categoryIndex + 1,
					0,
					category
				);
			},
		});
	};
	category_remove = async (menuIndex: number, categoryIndex: number) => {
		await this.remove({
			successMsg: "Category deleted",
			process: (r) => {
				r.menus[menuIndex].categories.splice(categoryIndex, 1);
			},
		});
	};
	dish_copy = async (
		menuIndex: number,
		categoryIndex: number,
		dishIndex: number
	) => {
		await this.copy({
			successMsg: "Item copied",
			process: (r) => {
				const item = cloneDeep(
					r.menus[menuIndex].categories[categoryIndex].dishes[
					dishIndex
					]
				);
				item._id = shortid.generate();
				item.name = `${item.name} - Copy`;
				r.menus[menuIndex].categories[categoryIndex].dishes.splice(
					categoryIndex + 1,
					0,
					item
				);
			},
		});
	};
	dish_remove = async (
		menuIndex: number,
		categoryIndex: number,
		dishIndex: number,
		dishId: string
	) => {
		await this.remove({
			successMsg: "Item deleted",
			dishId: dishId,
			process: (r) => {
				r.menus[menuIndex].categories[categoryIndex].dishes.splice(
					dishIndex,
					1
				);
			},
		});
	};

	dish_stock_update = async (dishId: string) => {
		const inputValue = prompt(
			`Enter a numeric value for the stock quantity of this item. Leave blank to disable stock management`
		);

		if (inputValue === null) {
			return;
		}

		let value: "" | number;
		try {
			value = inputValue === "" ? "" : parseInt(inputValue);
		} catch (e) {
			UI.notification.error(
				"Please enter a numeric value or leave blank"
			);
			return;
		}

		const { store } = this.injected;

		try {
			Untrusive.start();
			const { stock } = await store.api.menu_stock_update({
				_id: store.restaurant!._id,
				items: [
					{
						_id: dishId,
						key: "dishes",
						value: value,
					},
				],
			});
			store.setRestaurantStock(stock);
			UI.notification.success("Stock updated");
		} catch (e) {
			logger.captureException(e);
			UI.notification.error("Something went wrong, try again");
		} finally {
			Untrusive.stop();
		}
	};

	copy = async (opts: {
		process: (r: T.Schema.Restaurant.RestaurantSchema) => void;
		successMsg: string;
	}) => {
		await this.saveRestaurant({
			successMsg: opts.successMsg,
			process: (r) => {
				opts.process(r);
				return {
					update: { $set: { menus: r.menus } },
				};
			},
			before: () => this.setState({ copy_loading: true }),
			onSuccess: () => this.modal_copy_close(),
			onFail: () => this.setState({ copy_loading: false }),
			onError: () => this.setState({ copy_loading: false }),
		});
	};
	remove = async (opts: {
		process: (r: T.Schema.Restaurant.RestaurantSchema) => void;
		successMsg: string;
		dishId?: string;
	}) => {
		await this.saveRestaurant({
			successMsg: opts.successMsg,
			process: (r) => {
				opts.process(r);
				return {
					update: { $set: { menus: r.menus } },
				};
			},
			before: () => this.setState({ remove_loading: true }),
			onSuccess: () => {
				this.modal_remove_close();
				if (opts.dishId) {
					this.injected.store.api
						.menu_stock_update({
							_id: this.injected.store.restaurant!._id,
							items: [
								{
									_id: opts.dishId,
									key: "dishes",
									value: "",
								},
							],
						})
						.catch(logger.captureException);
				}
			},
			onFail: () => this.setState({ remove_loading: false }),
			onError: () => this.setState({ remove_loading: false }),
		});
	};

	modal_copy_open = (type: MenuComponents, index: number[]) => {
		this.setState({
			copy_type: type,
			copy_index: index,
			copy_loading: false,
		});
	};
	modal_copy_close = () => {
		this.setState({
			copy_type: null,
			copy_index: [],
			copy_loading: false,
		});
	};
	modal_remove_open = (type: MenuComponents, index: number[]) => {
		this.setState({
			remove_type: type,
			remove_index: index,
			remove_loading: false,
		});
	};
	modal_remove_close = () => {
		this.setState({
			remove_type: null,
			remove_index: [],
			remove_loading: false,
		});
	};

	toggle_selected_dish = (dish_id: string) => {
		const selected_dishes = [...this.state.selected_dishes];
		const index = selected_dishes.indexOf(dish_id);
		if (index === -1) selected_dishes.push(dish_id);
		else selected_dishes.splice(index, 1);
		this.setState({ selected_dishes });
	};
	float_button_cancel = () => {
		this.setState({ selected_dishes: [] });
	};
	float_button_delete = async () => {
		await this.float_button_process({
			successMsg: "Items deleted",
			process: (r, selected_dishes) => {
				for (const [i, menu] of r.menus.entries()) {
					for (const [k, category] of menu.categories.entries()) {
						r.menus[i].categories[k].dishes =
							category.dishes.filter(
								(dish) =>
									selected_dishes.indexOf(dish._id) === -1
							);
					}
				}
			},
		});
	};
	showDeleteItemsModal = () => {
		this.setState({ activeDeleteItemModal: true });
	}
	float_button_no_stock = async () => {
		await this.float_button_process({
			successMsg: "Item status set",
			process: (r, selected_dishes) => {
				for (const [i, menu] of r.menus.entries()) {
					for (const [k, category] of menu.categories.entries()) {
						for (const [z, dish] of category.dishes.entries()) {
							if (selected_dishes.indexOf(dish._id) !== -1) {
								r.menus[i].categories[k].dishes[z].status =
									"no-stock";
							}
						}
					}
				}
			},
		});
	};
	float_button_hidden = async () => {
		await this.float_button_process({
			successMsg: "Item status set",
			process: (r, selected_dishes) => {
				for (const [i, menu] of r.menus.entries()) {
					for (const [k, category] of menu.categories.entries()) {
						for (const [z, dish] of category.dishes.entries()) {
							if (selected_dishes.indexOf(dish._id) !== -1) {
								r.menus[i].categories[k].dishes[z].status =
									"hidden";
							}
						}
					}
				}
			},
		});
	};
	float_button_available = async () => {
		await this.float_button_process({
			successMsg: "Item status set",
			process: (r, selected_dishes) => {
				for (const [i, menu] of r.menus.entries()) {
					for (const [k, category] of menu.categories.entries()) {
						for (const [z, dish] of category.dishes.entries()) {
							if (selected_dishes.indexOf(dish._id) !== -1) {
								r.menus[i].categories[k].dishes[z].status =
									null;
							}
						}
					}
				}
			},
		});
	};
	float_button_process = async (opts: {
		process: (
			r: T.Schema.Restaurant.RestaurantSchema,
			selected_dishes: string[]
		) => void;
		successMsg: string;
	}) => {
		const { selected_dishes } = this.state;
		await this.saveRestaurant({
			successMsg: opts.successMsg,
			process: (r) => {
				opts.process(r, selected_dishes);
				return {
					update: { $set: { menus: r.menus } },
				};
			},
			before: () => this.setState({ selected_loading: true }),
			onSuccess: () =>
				this.setState({ selected_loading: false, selected_dishes: [] }),
			onFail: () => this.setState({ selected_loading: false }),
			onError: () => this.setState({ selected_loading: false }),
		});
	};

	startEditMode = (mode: MenusEditMode) => {
		this.setState({ mode });
		const r = this.injected.store.restaurant!;
		this.menu_backup = cloneDeep(r.menus);
	};

	cancelEditMode = () => {
		const r = this.injected.store.restaurant!;
		r.menus = this.menu_backup || [];
		this.setState({ mode: "normal" });
	};

	saveEditMode = async (successMsg: string) => {
		const r = this.injected.store.restaurant!;

		const backup = cloneDeep(r);
		if (this.menu_backup) backup.menus = this.menu_backup;

		await this.saveRestaurantSilent({
			backup: backup,
			update: { $set: { menus: r.menus } },
			successMsg,
			before: () => this.setState({ loading: true }),
			onSuccess: () => {
				this.setState({ mode: "normal", loading: false });
				this.menu_backup = null;
			},
			onFail: () => this.setState({ loading: false }),
			onError: () => this.setState({ loading: false }),
		});
	};

	onDragStart = () => { };
	onDragUpdate = () => { };
	onDragEnd = (result: DropResult) => {
		const { destination, source, type } = result;
		if (!destination) return;
		const r = { ...this.injected.store.restaurant! };
		if (type === "MENU") {
			if (destination.index !== source.index) {
				r.menus = arrayMove(r.menus, source.index, destination.index);
				this.injected.store.setRestaurant(r);
			}
		} else if (type === "CATEGORY") {
			const menus = cloneDeep(r.menus);
			if (destination.droppableId !== source.droppableId) {
				const sourceMenu = source.droppableId;
				const destinationMenu = destination.droppableId;
				const sourceMenuIndex = menus.findIndex(
					(m) => m._id === sourceMenu
				);
				const destinationMenuIndex = menus.findIndex(
					(m) => m._id === destinationMenu
				);
				// CLT.ONE CATEGORY
				const item = cloneDeep(
					menus[sourceMenuIndex].categories[source.index]
				);
				item.menu_id = destinationMenu;
				for (let i = 0; i < item.dishes.length; i++) {
					item.dishes[i].menu_id = destinationMenu;
				}
				// REMOVE FROM OLD MENU
				menus[sourceMenuIndex].categories.splice(source.index, 1);
				// ADD TO NEW MENU
				menus[destinationMenuIndex].categories.splice(
					destination.index,
					0,
					item
				);
			} else if (destination.index !== source.index) {
				const menu_id = source.droppableId;
				const menu_index = menus.findIndex((m) => m._id === menu_id);
				menus[menu_index].categories = arrayMove(
					menus[menu_index].categories,
					source.index,
					destination.index
				);
			}
			r.menus = menus;
			this.injected.store.setRestaurant(r);
		} else if (type === "DISH") {
			const menus = cloneDeep(r.menus);
			if (destination.droppableId !== source.droppableId) {
				const sourceCategory = source.droppableId;
				const destinationCategory = destination.droppableId;

				const s = RestaurantUtils.menu.findCategoryById(
					r,
					sourceCategory
				);
				const d = RestaurantUtils.menu.findCategoryById(
					r,
					destinationCategory
				);

				if (!s || !d) return;

				// CLT.ONE CATEGORY
				const item = cloneDeep(
					menus[s.menuIndex].categories[s.categoryIndex].dishes[
					source.index
					]
				);
				item.menu_id = d.menu._id;
				item.category_id = d.category._id;

				// REMOVE FROM CATEGORY
				menus[s.menuIndex].categories[s.categoryIndex].dishes.splice(
					source.index,
					1
				);
				// ADD TO NEW CATEGORY
				menus[d.menuIndex].categories[d.categoryIndex].dishes.splice(
					destination.index,
					0,
					item
				);
			} else if (destination.index !== source.index) {
				const category_id = source.droppableId;
				const query = RestaurantUtils.menu.findCategoryById(
					r,
					category_id
				);
				if (!query) throw new Error("Category not found by id");
				const { menu, category, menuIndex, categoryIndex } = query;
				const dishes =
					menus[menuIndex].categories[categoryIndex].dishes;
				menus[menuIndex].categories[categoryIndex].dishes = arrayMove(
					dishes,
					source.index,
					destination.index
				);
			}
			r.menus = menus;
			this.injected.store.setRestaurant(r);
		}
	};

	float_button_pause = () => {
		const { selected_dishes } = this.state;
		const r = this.injected.store.restaurant!;
		const { menus = [] } = r;
		const allDishes = menus.flatMap(menu =>
			menu.categories.flatMap(category =>
				category.dishes
			)
		);

		const selectedItems = allDishes.filter(item => selected_dishes.includes(item._id));
		this.setState({
			panel_pause: "item",
			currentMenuItems: selectedItems
		});
	};

	float_button_resume = () => {
		const { pausedMenuItems } = this.injected.store;
		if (pausedMenuItems.length > 0) {
			this.setState({ resumeModalOpen: true });
		}
	};

	handleCloseResumeModal = () => {
		this.setState({ resumeModalOpen: false, selected_dishes: [] });
	};

	menu_list_floating = (
		selected_dishes: string[],
		selected_loading: boolean
	) => {
		const { resumeModalOpen, remove_loading } = this.state;
		return (
			<>
				<ResumeModal isOpen={resumeModalOpen} handleClose={this.handleCloseResumeModal} menuItemIds={selected_dishes} />
				<MenuListFloatingButtonWrapper>
					<MenuListFloatingContent>
						<MenuListFloatingTitle>
							<p className="font-semi-bold big">Actions</p>
							<p className="font-semi-bold small m-t-1">
								{selected_dishes.length} Selected
							</p>
						</MenuListFloatingTitle>
						<Button size="xs" onClick={this.float_button_cancel}>
							Cancel
						</Button>
						<Button size="xs" onClick={this.float_button_available}>
							Available
						</Button>
						<Button size="xs" onClick={this.float_button_no_stock}>
							No Stock
						</Button>
						<Button size="xs" onClick={this.float_button_hidden}>
							Hide
						</Button>
						<Button size="xs" onClick={this.showDeleteItemsModal}>
							Delete
						</Button>
						<Button size="xs" onClick={this.float_button_pause}>
							Pause Item
						</Button>
						<Button size="xs" onClick={this.float_button_resume}>
							Resume Item
						</Button>
						{selected_loading && (
							<div className="text-center p-1">
								<RotateLoader size={2} />
							</div>
						)}
					</MenuListFloatingContent>
				</MenuListFloatingButtonWrapper>
				{this.state.activeDeleteItemModal && (<div>
					<Modal
						width="sm"
						close={() => this.setState({ activeDeleteItemModal: false })}
						closeButton={false}
						active={this.state.activeDeleteItemModal}
					>
						<ModalContent className="flex-l-r-center">
							<h4 className="">
								{selected_dishes.length > 1
									? `Delete ${selected_dishes.length} items`
									: "Delete Item"}
							</h4>
							{/* <p className="big underline">{item && item.name}</p> */}
						</ModalContent>
						<ModalContent className="flex-right">
							<Button
								type="button"
								className="m-r-2 width100 max100px"
								onClick={() => this.setState({ activeDeleteItemModal: false })}
							>
								Cancel
							</Button>
							<Button
								type="button"
								className="width100 max100px"
								color="primary"
								onClick={this.float_button_delete}
							>
								{remove_loading && <RotateLoader size={2} color="white" />}
								{!remove_loading && "Delete"}
							</Button>
						</ModalContent>
					</Modal>

				</div>)}
			</>
		);
	};

	saveCancelGroupButton(
		saveCallback: () => void,
		cancelCallback: () => void,
		loading: boolean,
		helperText?: string
	) {
		return (
			<div>
				<div className="grid-2 xs xs-gap m-t-10">
					<div className="col">
						<MenuListButton onClick={saveCallback}>
							{loading && <RotateLoader size={2} color="white" />}
							{!loading && "Save"}
						</MenuListButton>
					</div>
					<div className="col">
						<MenuListButton onClick={cancelCallback}>
							Cancel
						</MenuListButton>
					</div>
				</div>
				{helperText && (
					<div className="text-center m-t-3 font-semi-bold">
						<p>{helperText}</p>
					</div>
				)}
			</div>
		);
	}

	render() {
		const { selected_dishes, selected_loading } = this.state;
		return (
			<>
				<div className="relative" style={{ width: '100%'}}>
					{selected_dishes.length > 0 &&
						this.menu_list_floating(selected_dishes, selected_loading)}

					<DragDropContext
						onDragStart={this.onDragStart}
						onDragUpdate={this.onDragUpdate}
						onDragEnd={this.onDragEnd}
					>
						{this.list_menu()}
						{this.modal_copy()}
						{this.modal_remove()}
						{this.panel_menu()}
						{this.panel_category()}
						{this.panel_dish()}
					</DragDropContext>
				</div>
				{this.sidebar_panel_dish()}
			</>
		);
	}
}
