import React, { useState } from 'react';
import shortid from 'shortid';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup } from '../..';
import { FieldDineInTables, FieldDineInTablesV2, RotateLoader, styled } from '@lib/components';
import { useStores } from '../../../../../../../mobx/useStores';
import { copy2Clipboard } from '@lib/common';
import { UI } from '../../../../../../../core/ui';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { updateRestaurantNotificationOptions } from '../../../../../../../core/libs/receipt';
import { FormikProps } from 'formik';
const TableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AddTableButton = styled.div`
  display: flex;
  width: 30%;
  background: #f5f5f5;
  color: #212121;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  outline: 0;
  border: 1px solid #e0e0e0;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #fb2f2f;
    color: #f5f5f5;
  }
`;

const generateTableField = () => ({
  _id: shortid.generate(),
  name: "",
  password: "",
});
interface Table {
    _id: string;
    name: string;
    password: string;
}

type FormValues = T.Schema.Restaurant.RestaurantSchema["settings"]["services"]["dine_in"];

export const TablesDineIns = (props: any) => {
  const store = useStores();
  const restaurant = store.restaurant!;
  const reseller = store.reseller!;
  const storeHost = reseller!.store_host;
  const storeURL = restaurant!.domain
    ? `https://${restaurant!.domain}`
    : `https://${restaurant!.subdomain}.${storeHost}`;

const [tablesDineIns, setTableDineIns] = useState<Table[]>([]);

  const handleAddTable = (form: FormikProps<FormValues>) => {
    form.setFieldValue('tables', [...form.values.tables, generateTableField()]);
  };

  const handleAddMultipleTables = (form: FormikProps<FormValues>) => {
    const quantity = prompt('How many tables do you want to add?');

    if (quantity === null) return;

    if (!/^[0-9]+$/.test(quantity)) {
      alert('You did not enter a numeric number. Please try again!');
      return;
    }

    const newTableFields = [];
    for (let i = 0; i < Number(quantity); i++) {
      newTableFields.push(generateTableField());
    }

    form.setFieldValue('tables', [...form.values.tables, ...newTableFields]);
  };
  const copyTableRows = () => {
    const texts = document.getElementsByClassName(
        "input__InputComponent-bXkjDh"
    );

    if (texts.length > 2 && texts.length % 3 === 0) {
        let rows = "Index,Table name,Order URL\n";
        let index = 1;
        for (let text = 0; text < texts.length; text += 3) {
            rows += `${index},${(texts[text] as HTMLInputElement).value},${
                (texts[text + 2] as HTMLInputElement).value
            }\n`;
            index++;
        }
        copy2Clipboard(rows);
        UI.notification.success('copied')
    }
};
const { injected } = props
let initialValues = restaurant.settings.services.dine_in;
return (
  <div style={{paddingTop: 16, paddingBottom: 83}}>
    <RestaurantForm<FormValues>
      submit={async (r, values) => {
        r.settings.services.dine_in = values;
        const update = { $set: { "settings.services.dine_in": r.settings.services.dine_in } };
        return { r, update };
      }}
      initialValues={initialValues}
      onSuccess={async () => await updateRestaurantNotificationOptions(injected.store)}
      onError={() => UI.notification.error("An error occurred")}
      onSuccessMessage="Settings Updated"
      width='100%'
    >
      {({ form, error, getFieldError }) => {
        const { isSubmitting, setFieldValue } = form;
        return (
          <>
            <SettingsFormGroup>
              <TableWrapper>
                <AddTableButton onClick={() => handleAddTable(form)}>Add Table</AddTableButton>
                <AddTableButton onClick={() => handleAddMultipleTables(form)}>Add Multiple Tables</AddTableButton>
                <AddTableButton onClick={copyTableRows}>Copy as CSV</AddTableButton>
              </TableWrapper>
              <FieldDineInTablesV2
                baseURL={storeURL}
                values={form.values.tables}
                onChange={tables => form.setFieldValue('tables', tables)}
                isMobile
              />
            </SettingsFormGroup>
            <SettingsActionButtonContainer>
                <SettingsActionButtonWrapper>
                    <button type="button" 
                        className="default btn-cancel" 
                        onClick={ () => store.setActiveMobileMenu(
                            store.activeMobileMenu.referrer.pop()!,
                            Array.from(new Set(store.activeMobileMenu.referrer))
                        )}>
                            Cancel 
                    </button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting && <RotateLoader size={2} color="white" />}
                        {!isSubmitting && "Save"}
                    </button>
                </SettingsActionButtonWrapper>
            </SettingsActionButtonContainer>
          </>
        )}}
      </RestaurantForm>
    </div>
  );
};
