import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { logger } from "@lib/common";
import { UI } from "../../../../../core/ui";
import { RotateLoader } from "@lib/components";
import { Button } from "@lib/components";
import { BlockInfo } from "@lib/components";
import { Modal } from "@lib/components";
import { ModalContent, ModalTitle } from "@lib/components";
import { StaffForm } from "./form";
import { ItemListWrapper, ItemListContent, ItemListItem } from "@lib/components";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { cloneDeepSafe } from "@lib/common";
import styled from "styled-components";
import Avatar from "react-avatar";
import { MobileModal, MobileModalContent, MobileModalFooter, MobileModalHeader, SalesTaxesAddButton, SettingsActionButtonWrapper, SettingsFormInputContainer} from "../../../restaurant/views/settings-v2";
import { StaffFormV2 } from "./form-staff-v2";
import { IconClose, IconPlusRed } from "../../../../components/v2/icons";

export const AddStaffButton = styled.div`
	display: flex;
	padding: 8px 16px;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	background-color: #FB2F2F;
	color: #FEFEFE;
	border-radius: 4px;
	cursor: pointer;
	height: 40px;
`;

export const AddingStaffWrapper = styled.div<{ isStaffV2?: boolean}>`
${({ isStaffV2 }) => isStaffV2 ? 'margin-bottom: 24px;' : ''}
 @media(max-width: 768px) {
	display: ${({ isStaffV2 }) => isStaffV2 ? 'none!important' : 'flex'};
 }
`;

const ListStaffWrapper = styled.div`
	@media(max-width: 768px) {
 }
`;
const DeleteModalDesktop = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 16px;
	font-family: Inter;
	.header {
		color: #4B4F5E;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}
	.content {
		display: flex;
		align-items: flex-start;
		gap: 4px;
		align-self: stretch;
		color: #6B7270;
	}
`
const DeleteModalWrapper = styled.div`
	.mobile-view {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: block;
	  padding: none;
    }
  }
`
export const TrashIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.66699 14C4.30033 14 3.98633 13.8693 3.72499 13.608C3.46366 13.3467 3.33321 13.0329 3.33366 12.6667V4H2.66699V2.66667H6.00033V2H10.0003V2.66667H13.3337V4H12.667V12.6667C12.667 13.0333 12.5363 13.3473 12.275 13.6087C12.0137 13.87 11.6999 14.0004 11.3337 14H4.66699ZM11.3337 4H4.66699V12.6667H11.3337V4ZM6.00033 11.3333H7.33366V5.33333H6.00033V11.3333ZM8.66699 11.3333H10.0003V5.33333H8.66699V11.3333Z" fill="#4B4F5E"/>
	</svg>
)

interface Props extends WithTranslation { }
interface State {
	modal_index: number;
	delete_index: number | null;
	delete_loading: boolean;
	key_word: string;
}

export function generateStaffRestrictions(): T.Schema.User.UserRestrictions {
	return {
		restaurants: [],
		restaurant: {
			create: true,
			billing: true,
			delete: true,
			dashboard: true,
			orders_board: true,
			orders_list: true,
			orders_bump: true,
			orders_delete: true,
			bookings: true,
			bookings_delete: true,
			customers: true,
			customers_actions: true,
			menus: true,
			settings_detail: {
				system: true,
				services: true,
				payments: true,
				website: true,
				integrations: true,
			},
		},
		website: {
			create: true,
			edit: true,
			delete: true,
		},
		online_payment: {
			view_transaction: true,
		},
		staff: {
			create: true,
			edit: true,
			delete: true,
		},
		api: {
			create: true,
			edit: true,
			delete: true,
		},
		misc: {
			hide_prices: false,
		},
	};
}

@inject("store") @observer
class DashboardStaffV2Class extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			modal_index: -1,
			delete_index: null,
			delete_loading: false,
			key_word: ''
		};
	}

	componentWillMount() {
		const { store } = this.injected;
		store.updateStaff({ loading: true, error: "" });
	}

	componentDidMount() {
		this.get();
	}

	get = async () => {
		const { store } = this.injected;
		store.updateStaff({ loading: true, error: "", items: [] });
		try {
			const response = await store.api.staff_find({});
			const response2 = await store.api.restaurants();
			if (response.outcome || response2.outcome) {
				store.updateStaff({
					loading: false,
					error: response.outcome ? response.message : "Something went wrong, try again or contact us",
					items: [],
				});
			}
			else {
				store.updateRestaurants({
					items: response2.items,
				});
				store.updateStaff({
					loading: false,
					error: "",
					items: response.staff,
				});
			}
		}
		catch (e) {
			logger.captureException(e);
			store.updateStaff({
				loading: false,
				error: "Something went wrong, try again or contact us",
				items: [],
			});
		}
	}

	create_staff = () => {
		const { store } = this.injected;
		this.setState({ modal_index: -1 });
		store.setOpenModalStaff(true)
	}

	delete_staff = async (index: number | null) => {
		if (index === null) return;
		const { store } = this.injected;
		const { items } = store.staff;
		try {
			this.setState({ delete_loading: true });
			const res = await store.api.staff_delete({ _id: items[index]._id });
			if (res.outcome) {
				UI.notification.error(res.message);
				this.setState({ delete_loading: false });
			}
			else {
				UI.notification.success("Staff deleted");
				const newItems = [...items];
				this.setState({
					delete_index: res.outcome ? index : null,
					delete_loading: false,
				});
				newItems.splice(index, 1);
				store.updateStaff({ items: newItems });
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({ delete_loading: false });
		}
	}

	loading = () => {
		return (
			<div className="m-t-12">
				<RotateLoader size={3} />
			</div>
		);
	}

	error = () => {
		const { store } = this.injected;
		const { error } = store.staff;
		return (
			<div className="m-t-12 text-center">
				<p className="big error-text m-b-4">{error}</p>
				<Button color="primary" size="sm" onClick={this.get}>
					Try Again
				</Button>
			</div>
		);
	}

	content = () => {
		const { store } = this.injected;
		const { items } = store.staff;
		const { restrictions } = store;
		const filteredItems = items.filter(item => 
			item.email.toLowerCase().includes(this.state.key_word.toLocaleLowerCase())
		);
		const handleAddStaff = () => {
			this.create_staff();
			store.setRouterStaff('add staff')
			store.setOpenModalStaff(true);
		}
		return (
			<div>
				{store.organisation && <AddingStaffWrapper className="flex-l-r-center" isStaffV2={store.organisation.dashboard === 'v2.1'}>
					<SettingsFormInputContainer style={{padding: '6px 12px'}}>
                            <input
                                style={{width: 312}}
                                type="text"
                                onChange={(e) => this.setState({ key_word: e.target.value })}
                                placeholder="Search E-Mail" />
                    </SettingsFormInputContainer>
					{restrictions.staff.create && (
						
						<SalesTaxesAddButton
						  variant="red"
						  type='button' 
						  style={{padding: '11px 16px', fontSize: 12}}
						  onClick={handleAddStaff}>
						  <IconPlusRed /> Add Staff
					  	</SalesTaxesAddButton>
					)}
				</AddingStaffWrapper>}

				{items.length === 0 && (
					<div className="text-center m-t-12">
						<BlockInfo className="flex-center max200 center">
							No staff found
						</BlockInfo>
					</div>
				)}

				{filteredItems.length !== 0 && (
					<ListStaffWrapper>
						<ItemListWrapper>
							<ItemListContent className="v2" style={{ display: 'flex', flexDirection: 'column', gap: '16px', boxShadow: 'none'}}>
								{filteredItems.map((s, i) => (
									<ItemListItem key={i} onClick={() => {
										store.setRouterStaff('edit staff')
										if (restrictions.staff.edit) {
											this.setState({ modal_index: i});
											store.setOpenModalStaff(true)
										}
									}} style={{ display: 'flex', alignItems: 'center', padding: '16px', borderBottom: '1px solid #f0f0f0' }}>
										
										<Avatar name={s.email} size="40" round={true} style={{ marginRight: '10px' }} />
										
										<p className="p-lr-4" style={{ flexGrow: 1, margin: 0, color: '#0C202A', fontSize: '14px', fontWeight: 500, lineHeight: '24px', wordBreak: 'break-word'}}>{s.email}</p>
										
										<div>
											<Button
												type="button"
												color="#F1F4F7"
												className="no-border"
												paddinglr={12}
												onClick={(e) => {
													if (restrictions.staff.delete) {
														e.stopPropagation();
														this.setState({ delete_index: i });
													}
												}}>
												{restrictions.staff.delete && <TrashIcon />}
											</Button>
										</div>
									</ItemListItem>
								))}
							</ItemListContent>
						</ItemListWrapper>
					</ListStaffWrapper>
				)}

			</div>
		);
	}

	modal = () => {
		const { store } = this.injected;
		const { items } = store.staff;
		const { modal_index } = this.state;

		const type = modal_index === -1 ? "create" : "edit";

		let initialValues: T.API.StaffCreateRequest = {
			email: "",
			password: "",
			restrictions: generateStaffRestrictions(),
		};

		if (type === "edit") {
			const found = cloneDeepSafe(items[modal_index]);
			if (found.restrictions) {

				if (typeof found.restrictions.restaurant.orders_list === "undefined") {
					found.restrictions.restaurant.orders_list = found.restrictions.restaurant.orders;
					found.restrictions.restaurant.orders_board = found.restrictions.restaurant.orders;
					delete found.restrictions.restaurant.orders;
				}

				if (!found.restrictions.restaurant.settings_detail && found.restrictions.restaurant.settings) {
					found.restrictions.restaurant.settings_detail = {
						system: true,
						services: true,
						payments: true,
						website: true,
					};
				}

			}
			initialValues = {
				email: found.email,
				password: "",
				restrictions: found.restrictions,
			};
		}

		return (
					<>
						{ store.openModalStaff && <StaffFormV2
							type={type}
							restaurants={store.restaurants.items.map((r) => ({ value: r._id, label: r.name }))}
							initialValues={initialValues}
							back={() => this.setState({ modal_index: -1 })}
						/>}
					</>
		);

	}

	modal_delete = () => {
		const { delete_index, delete_loading } = this.state;
		const { store } = this.injected;
		const { restrictions } = store;
		const { items } = store.staff;

		let active = false;
		let item;
		if (delete_index !== null) {
			active = true;
			item = items[delete_index];
		}

		if (!restrictions.staff.delete) {
			return null;
		}

		return (
			<DeleteModalWrapper>
			{/* <div className="desktop-view">
				<Modal
					width="sm"
					close={() => this.setState({ delete_index: null })}
					active={active}>
					<DeleteModalDesktop>
							<div className="header"> Delete Staff</div>
							<div className="content">{item ? item.email : ""}</div>
							<p className="content">Are you sure you want to delete this staff member. Once deleted, it cannot be recovered</p>
							<div>
								<Button style={{ width: '100%' }} color="primary" onClick={() => this.delete_staff(delete_index)}>
									{delete_loading && <RotateLoader size={2} color="white" />}
									{!delete_loading && "Confirm	"}
								</Button>
							</div>
					</DeleteModalDesktop>
				</Modal>
			</div> */}
			{active &&
			<MobileModal isStaff style={{ left: 0, gap: '16px', zIndex: 1}}>
				<MobileModalContent size="sm">
					<MobileModalHeader>
						<div> Delete Staff</div>
						
						<span onClick={() => {
							this.setState({ delete_index: null })
						}}>
							<IconClose />
						</span>
					</MobileModalHeader>
					<MobileModalContent  style={{ borderTop: 'none', padding: '0'}}>
					<p style={{fontFamily: 'Inter', color: '#6B7270'}}>{item ? item.email : ""}</p>
					<p style={{fontFamily: 'Inter', color: '#6B7270'}}>Are you sure you want to delete this staff member. Once deleted, it cannot be recovered</p>
					</MobileModalContent>
					<MobileModalFooter>
					<Button style={{ width: '100%' }} color="primary" onClick={() => this.delete_staff(delete_index)}>
						{delete_loading && <RotateLoader size={2} color="white" />}
						{!delete_loading && "Confirm"}
					</Button>
					</MobileModalFooter>
			</MobileModalContent>
			</MobileModal>

			}
			</DeleteModalWrapper>
			
		);
	}

	render() {
		const { store } = this.injected;
		const { loading, error } = store.staff;
		return (
			<div>
				{loading && this.loading()}
				{(!loading && error) && this.error()}
				{(!loading && !error && !store.openModalStaff) && this.content()}
				{this.modal()}
				{this.modal_delete()}
			</div>
		);
	}

}

export const DashboardStaffV2 = withTranslation()(DashboardStaffV2Class);
