import * as React from 'react';
import { inject, observer } from 'mobx-react';
import InputMask from "react-input-mask";
import { MobxComponent } from '../../../../../mobx/components';
import styled from 'styled-components';
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader, SettingsActionButtonContainer, SettingsActionButtonWrapper, SettingsFormGroup, SettingsFormGroupInput, SettingsFormInputContainer, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsHeader, SettingsHeaderTextBreadcrumbs, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper, SettingsSwitchContainer } from '../settings-v2';
import { MainContainer } from '../../../../components/v2/layout.c';
import { TabGroup, TabItem } from '../settings-v2/receipt-printing';
import { IconArrowDownwardMenu, IconArrowForward, IconClose, IconCopyAlt, IconDelete, IconEdit, IconHelp, IconMoreOptions } from '../../../../components/v2/icons';
import shortid from 'shortid';
import { Button, ButtonGroup, Checkbox, Input, Modal, ModalContent, RotateLoader, SelectAdv, Switch } from '@lib/components';
import { IoIosPause } from 'react-icons/io';
import { FastField } from 'formik';
import { RestaurantForm } from '../../../../../mobx/components/restaurant-form';
import { ListOrderTimesOptions, ListServicesOptionsNoB, logger, RestaurantUtils } from '@lib/common';
import { FieldOpeningHoursV2 } from '@lib/components/src';
import _cloneDeep from "lodash/cloneDeep";
import cloneDeep from 'lodash/cloneDeep';
import { MenuComponents } from '../menus/list.menus';
import { MobileMenus } from '../menus/mobileView/menuList/mobileMenus';
import { RestaurantFormOptionSetV2 } from './option_set_v2';
import { RestaurantOptionSetListV2 } from './list.option_sets';
import { ItemTags } from './item-tags';
import { RestaurantMenuListV2 } from './list.menus';
import { RestaurantDishTagsV2 } from './list-tags';
export const MenuCard = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;
word-break: break-all;

border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);

&.desktop-only {
 display: none;

    & > p {
        color: var(--Secondary-Smokey-Grey-1, #6B7270);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    @media (min-width: 768px) {
        display: flex;
    }
}


`

export const MenuCardWrapper = styled.div`
display: flex;
align-items: center;
gap: 8px;
align-self: stretch;
justify-content: space-between;
`
export const MenuCardTitle = styled.div`

color: var(--Secondary-Dark-1, #0C202A);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.28px;
`
export const TextEllipsis = styled.p`

color: var(--Secondary-Dark-1, #0C202A);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.28px;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
`

export const MenuCardActionsWrapper = styled.div`
display: flex;
align-items: center;
gap: 16px;
`

export const MenuCardActions = styled.div`
display: none;
align-items: center;
gap: 8px;
@media (min-width: 768px) {
    display: flex;
}
`
export const MenuCardActionsMobile = styled.div`
display:flex;
@media (min-width: 768px) {
    display: none;
}
`


export const MenuCardActionsIconWrapper = styled.div<{bg?:string}>`
display: flex;
padding: 8px 16px;
align-items: center;
gap: 4px;
flex: 1 0 0;
align-self: stretch;
height: 40px;
border-radius: 4px;
background: ${({bg}) => bg ? bg : 'var(--Neutral-Gray, #F1F4F7)'};
@media (max-width: 768px) {
	background-color: transparent;
    &.hide-mobile {
		display: none;
	}
}
`
export const MenuCreateActionButton = styled.div`
	display: flex;
	padding: 12px 16px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	border: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
	background: var(--Neutral-White, #FEFEFE);
	color: var(--Secondary-Dark-Grey-1, #4B4F5E);
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px
`
export const MenuWrapper = styled.div`
		.mobile-view {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: flex;
	  padding: none;
    }
 
`
interface Props { }
interface State {
    loading: boolean;
	expandedMenus: any;
	activeTab: string;
	createMenu: string;
	menuIndex: number;
	categoryIndex: number;
	panel: MenuComponents | null;
	panel_index: number[];
	copy_type: MenuComponents | null;
	copy_index: number[];
	copy_loading: boolean;
	remove_type: MenuComponents | null;
	remove_index: number[];
	remove_loading: boolean;
	selected_dishes: string[];
	selected_item_id: string;
	selected_tag_index: number;
 }
 interface RestaurantDishWithTaxes
	extends T.Schema.Restaurant.Menu.RestaurantDish { }

@inject("store") @observer
export class RestaurantMenusV2 extends MobxComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
			expandedMenus: {},
			activeTab: 'menu',
			createMenu: 'general',
			menuIndex: 0,
			categoryIndex: 0,
			panel: null,
			panel_index: [],
			copy_type: null,
			copy_index: [],
			copy_loading: false,
			remove_type: null,
			remove_index: [],
			remove_loading: false,
			selected_dishes: [],
			selected_item_id: '',
			selected_tag_index: -1
        }
    }
	toggleMenu = (menuIndex: number) => {
		this.setState((prevState) => ({
		  expandedMenus: {
			...prevState.expandedMenus,
			[menuIndex]: {
			  ...prevState.expandedMenus[menuIndex],
			  expanded: !prevState.expandedMenus[menuIndex]?.expanded,
			},
		  },
		}));
	  };
	
	  toggleCategory = (menuIndex: number, categoryIndex: number) => {
		this.setState((prevState) => ({
		  expandedMenus: {
			...prevState.expandedMenus,
			[menuIndex]: {
			  ...prevState.expandedMenus[menuIndex],
			  categories: {
				...prevState.expandedMenus[menuIndex]?.categories,
				[categoryIndex]: !prevState.expandedMenus[menuIndex]?.categories?.[categoryIndex],
			  },
			},
		  },
		}));
	  };
	  setPanel = (panel: any | null, panel_index: number[]) => {
		this.setState({ panel, panel_index });
	};
    createPizzaMenu = async () => {
		const menu_id = shortid.generate();

		const category1_id = shortid.generate();
		const category2_id = shortid.generate();
		const category3_id = shortid.generate();

		const dish1_id = shortid.generate();
		const dish2_id = shortid.generate();
		const dish3_id = shortid.generate();
		const dish4_id = shortid.generate();
		const dish5_id = shortid.generate();
		const dish6_id = shortid.generate();
		const dish7_id = shortid.generate();

		const optionset1_id = shortid.generate();
		const optionset2_id = shortid.generate();
		const dish9_id = shortid.generate();

		const menu: T.Schema.Restaurant.Menu.RestaurantMenu = {
			_id: menu_id,
			name: "Pizza Menu",
			display_name: "",
			description: "",
			conditions: {
				times: [],
				services: [],
				hours: [],
				pre_order: {
					enabled: false,
					days_in_advance: 0,
					cutoff_time: "",
				},
				age_restricted: false,
			},
			categories: [
				{
					_id: category1_id,
					menu_id: menu_id,
					name: "Pizzas",
					display_name: "",
					dishes: [
						{
							type: "standard",
							_id: dish1_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Magherita",
							display_name: "",
							price: 11.9,
							description:
								"Cheese, tomato & basil with tomato base",
							subtitle: "",
							image: {
								_id: "a5f571db-fbf5-4a4d-80e3-562e1cbf8f87",
								name: "pizza-margherita-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "Sk0rG2UWE",
									name: "Cheese",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "S1SUGhIb4",
									name: "Tomato",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "mirzQ-con",
									name: "Basil",
									active: true,
                                    to: '',
                                    from: ''
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
							option_set_primary: "",
						},
						{
							type: "standard",
							_id: dish2_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Pepperoni",
							display_name: "",
							price: 12.9,
							description:
								"Loaded with pepperonis and cheese on a tomato base",
							subtitle: "",
							image: {
								_id: "6f1e43bd-a365-46d7-9a86-063089271edd",
								name: "pizza-pepperoni-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "SJerI3IW4",
									name: "Cheese",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "ry_SU38-V",
									name: "Pepperoni",
									active: true,
                                    to: '',
                                    from: ''
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
							option_set_primary: "",
						},
						{
							type: "standard",
							_id: dish3_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Chicken",
							display_name: "",
							price: 14.9,
							description:
								"Seasoned chicken, corn, mushrooms and capsicum ",
							subtitle: "",
							image: {
								_id: "aacb1904-1925-4ace-87e4-5b15ff3c5a12",
								name: "pizza-chicken-corn-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "rkjKBnU-N",
									name: "Chicken",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "HkOcB3U-4",
									name: "Corn",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "SkCqB3UZV",
									name: "Mushroom",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "B1FornIWN",
									name: "Capsicum ",
									active: true,
                                    to: '',
                                    from: ''
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
						},
						{
							type: "standard",
							_id: dish4_id,
							menu_id: menu_id,
							category_id: category1_id,
							name: "Veggie",
							display_name: "",
							price: 12.9,
							description:
								"Tomato and onion with light herb seasoning",
							subtitle: "",
							image: {
								_id: "4b533707-09ea-4e54-8db5-2dd2ba05a1f2",
								name: "veggie-pizza-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [
								{
									_id: "ryx0Ph8-N",
									name: "Tomato Base",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "SkKAw2U-V",
									name: "Onion",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "r1lkO2U-4",
									name: "Pineapple",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "B15Juh8bE",
									name: "Capsicum",
									active: true,
                                    to: '',
                                    from: ''
								},
								{
									_id: "ByExun8bE",
									name: "Cheese",
									active: true,
                                    to: '',
                                    from: ''
								},
							],
							option_sets: [optionset1_id, optionset2_id],
							price_type: "standard",
							choices: [],
						},
					],
				},
				{
					_id: category2_id,
					menu_id: menu_id,
					name: "Combo Deals",
					display_name: "",
					dishes: [
						{
							type: "combo",
							_id: shortid.generate(),
							menu_id: menu_id,
							category_id: category2_id,
							name: "2 Pizzas & 1 Side",
							display_name: "",
							description:
								"Grab any 2 pizzas and any side with this combo",
							subtitle: "",
							price: 29.95,
							tags: [],
							image: null,
							status: null,
							ingredients: [],
							option_sets: [],
							choices: [
								{
									_id: shortid.generate(),
									name: "First Pizza",
									dishes: [
										dish1_id,
										dish2_id,
										dish3_id,
										dish4_id,
									],
									selected: null,
									lpo: 11.9,
								},
								{
									_id: shortid.generate(),
									name: "Second Pizza",
									dishes: [
										dish1_id,
										dish2_id,
										dish3_id,
										dish4_id,
									],
									selected: null,
									lpo: 11.9,
								},
								{
									_id: shortid.generate(),
									name: "Side Dish",
									dishes: [dish5_id, dish6_id, dish7_id],
									selected: null,
									lpo: 4,
								},
							],
							price_type: "standard",
						},
					],
				},
				{
					_id: category3_id,
					menu_id: menu_id,
					name: "Sides",
					display_name: "",
					dishes: [
						{
							type: "standard",
							_id: dish5_id,
							menu_id: menu_id,
							category_id: category3_id,
							name: "Garlic Bread",
							display_name: "",
							price: 4.5,
							description: "",
							subtitle: "",
							image: {
								_id: "87612477-521b-4854-9068-d56adca0576c",
								name: "garlic-bread-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [],
							option_sets: [],
							price_type: "standard",
							choices: [],
						},
						{
							type: "standard",
							_id: dish6_id,
							menu_id: menu_id,
							category_id: category3_id,
							name: "Fries",
							display_name: "",
							price: 4,
							description: "",
							subtitle: "",
							image: {
								_id: "dc42d806-534b-4e51-a2c6-870440391524",
								name: "fries-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [],
							option_sets: [],
							price_type: "standard",
							choices: [],
						},
						{
							type: "standard",
							_id: dish7_id,
							menu_id: menu_id,
							category_id: category3_id,
							name: "Fried Chicken",
							display_name: "",
							price: 7,
							description: "",
							subtitle: "",
							image: {
								_id: "7ebd6e08-efec-4fe1-a2ac-58efdd21e371",
								name: "fried-chicken-600-400.jpeg",
							},
							tags: [],
							status: null,
							ingredients: [],
							option_sets: [],
							price_type: "standard",
							choices: [],
						},
					],
				},
			],
		};

		const option_sets: T.Schema.Restaurant.Menu.RestaurantOptionSet[] = [
			{
				_id: optionset1_id,
				name: "Pizza Menu - Base Choice",
				display_name: "Base Choice",
				show_in_menu: false,
				conditions: {
					required: true,
					multi_select: false,
					quantity_select: false,
					min_options: "",
					max_options: "",
					free_amount: "",
				},
				options: [
					{
						_id: shortid.generate(),
						name: "Classic",
						quantity: 0,
						price: "",
						status: null,
					},
					{
						_id: shortid.generate(),
						name: "Thin",
						quantity: 0,
						price: "",
						status: null,
					},
					{
						_id: shortid.generate(),
						name: "Stuffed Crust",
						quantity: 0,
						price: "4.0",
						status: null,
					},
				],
			},
			{
				_id: optionset2_id,
				name: "Pizza Menu - Extra Toppings",
				display_name: "Extra Toppings",
				show_in_menu: false,
				conditions: {
					required: false,
					multi_select: true,
					quantity_select: false,
					min_options: "",
					max_options: "",
					free_amount: "",
				},
				options: [
					{
						_id: shortid.generate(),
						name: "Extra Onion",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Capsicum",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Mushroom",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Pineapple",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Jalapeños",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Olives",
						quantity: 0,
						price: 2,
					},
					{
						_id: shortid.generate(),
						name: "Extra Chicken",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Beef",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Pepperoni",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Bacon",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Prawns",
						quantity: 0,
						price: 3,
					},
					{
						_id: shortid.generate(),
						name: "Extra Seafood Mix",
						quantity: 0,
						price: 3,
					},
				],
			},
		];

		await this.saveRestaurant({
			process: (r) => {
				r.menus.push(menu);
				r.option_sets.push(option_sets[0]);
				r.option_sets.push(option_sets[1]);
				return {
					update: {
						$set: { menus: r.menus, option_sets: r.option_sets },
					},
				};
			},
			successMsg: "Menu template created",
			before: () => this.setState({ loading: true }),
			onSuccess: () => this.setState({ loading: false}),
			onFail: () => this.setState({ loading: false }),
			onError: () => this.setState({ loading: false }),
		});
	};

	
    render() {
        const { store } = this.injected;
        const { activeMobileMenu, activeMobileModal, setActiveMobileModal, setActiveMobileMenu } = store;
        const { restaurant } = store
		const r = this.injected.store.restaurant!;
		const { menus, option_sets, dish_tags } = r;
        const { initChargebeeSession } = store.billing
        const { loading, expandedMenus } = this.state;
		
        return (
			<MenuWrapper>
				<div>
				<>
            <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                <SettingsHeader>
                    <SettingsHeaderTextContainer>
                        <SettingsHeaderTextWrapper>
                            <SettingsHeaderTextTitle>Menus</SettingsHeaderTextTitle>
                            {activeMobileMenu.referrer.length > 0 &&
                            <SettingsHeaderTextBreadcrumbs style={{flexDirection: 'row', justifyContent: 'start', gap: 4}}>
                                {activeMobileMenu.referrer
                                .map((r, index) => <span
                                    key={index}
                                    style={{cursor: 'pointer'}}
                                        onClick={() => store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!,
                                            Array.from(new Set(store.activeMobileMenu.referrer))
                                        )}>
                                    {r.replace(/-/g, ' ')} {index !== (activeMobileMenu.referrer.length - 1) ? '/': ''}
                                </span>)} / {activeMobileMenu.name.replace(/-/g, ' ')}
                            </SettingsHeaderTextBreadcrumbs> }
                        </SettingsHeaderTextWrapper>
                    </SettingsHeaderTextContainer>
                </SettingsHeader>
                <MainContainer 
                    className={(store.organisation && store.organisation.dashboard) || ''}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                        maxWidth: 894,
                        width: '100%',
                        margin: '0 auto',
                        paddingLeft: 0
                    }}>
                    {(activeMobileMenu.name == '' ||
                    activeMobileMenu.name == 'menus' ||
					!activeMobileMenu.referrer.includes('menus')) && <>
                     <TabGroup className='menus' style={{ overflow: 'unset'}}>
						<TabItem
							active={this.state.activeTab === 'menu'}
							onClick={() => this.setState({ activeTab: 'menu'})}
						>
						Menu
						</TabItem>
						<TabItem
							active={this.state.activeTab === 'optionSets'}
							onClick={() => this.setState({ activeTab: 'optionSets'})}
						>
						Option Sets
						</TabItem>
						<TabItem
							active={this.state.activeTab === 'itemTags'}
							onClick={() => this.setState({ activeTab: 'itemTags'})}
						>
						Item Tags
						</TabItem>
					</TabGroup>
						<>
						
							
						{ this.state.activeTab === 'menu' && <RestaurantMenuListV2 />}
						{this.state.activeTab === 'optionSets' && 
								<RestaurantOptionSetListV2 />
						}
						

						{ this.state.activeTab === 'itemTags' &&  <RestaurantDishTagsV2 />
						// <>
						// 	{dish_tags.map((dish_tag, dish_tag_index) => {
						// 		return (
						// 			<React.Fragment key={dish_tag_index}>
						// 					<MenuCard>
						// 						<MenuCardWrapper>
						// 						<MenuCardTitle> {dish_tag.name}</MenuCardTitle>
						// 							<MenuCardActionsWrapper>
						// 								<MenuCardActions>
						// 									<MenuCardActionsIconWrapper
						// 										onClick={() =>  {
						// 											this.setState({
						// 												selected_tag_index: dish_tag_index
						// 											}, () => {
						// 												setActiveMobileMenu('edit-dish-tag', 'menus')
						// 											})
						// 										}}>
						// 										<IconEdit />
						// 									</MenuCardActionsIconWrapper>
						// 									<MenuCardActionsIconWrapper>
						// 										<IconDelete />
						// 									</MenuCardActionsIconWrapper>
						// 									<MenuCardActionsIconWrapper>
						// 										<IconCopyAlt />
						// 									</MenuCardActionsIconWrapper>
						// 								</MenuCardActions>
						// 								<MenuCardActionsMobile>
						// 									<IconMoreOptions />
						// 								</MenuCardActionsMobile>
						// 							</MenuCardActionsWrapper>
						// 						</MenuCardWrapper>
						// 				</MenuCard>
						// 				</React.Fragment>
						// 		)
						// 	})}

						// 	<TabGroup className='menus menus-end' style={{ overflow: 'unset', marginBottom: '50px'}}>
						// 		<TabItem
						// 			onClick={() => {
						// 				setActiveMobileMenu('create-dish-tag', 'menus')
						// 			}}>
						// 			Create New Item Tags
						// 		</TabItem>
						// 		<TabItem>
						// 			Re-Arrange
						// 		</TabItem>
							
						// 	</TabGroup>
						// </> 
						}
						</>
                    
					</>}

                    {activeMobileMenu.name == 'templates' && 
                    
                    <MenuCard
                        className='desktop-only'
                        style={{padding: 24, gap: 16, borderRadius: 0}}>
                        <p>Pick from one of the ready made templates to help you get started building your menu.</p>
                        <p>Choosing a template below will create a new menu, it will not overwrite any of your existing menus or items.</p>
                        
                        <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper>
                                <button 
                                    type="button" 
                                    className='btn-cancel'
                                    onClick={ () => store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )}>
                                    Cancel
                                </button>
                                <button onClick={this.createPizzaMenu} style={{minWidth: 191}}>
                                    {loading && <RotateLoader size={2} />}
                                    {!loading && "Create Pizza Menu"}
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                    </MenuCard>}

                    {activeMobileMenu.name == 'menus-upload-service' && 
                    
                    <MenuCard
                        className='desktop-only'
                        style={{padding: 24, gap: 16, borderRadius: 0}}>
                            <p>Need a hand uploading your menu? Simply complete the upload request form linked below.</p>
                        <p>As a free user, we charge <strong>$7 per hour</strong> for our upload service. <u>Upgrading to a paid plan will allow you to access this service for <strong>FREE</strong></u></p>
                        <p>We will process your request within 48-72 hours, notify you upon completion and bill you automatically. The average menu costs between $7 to $14 to upload.</p>
                        <p>Please ensure you have added a payment method to your account before completing the upload form as we won't start without it.</p>
                    
                        <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper>
                                <button 
                                    type="button" 
                                    className='btn-cancel'
                                    onClick={ () => 
                                        window.open(`https://airtable.com/shrTnLexLfBva8wJO?prefill_Restaurant+ID=${restaurant!._id}&prefill_Restaurant+Subdomain=${restaurant!.subdomain}`, '_blank')
                                    }
                                    style={{minWidth: 152}}>
                                    Go to Upload Form
                                </button>
                                <button 
                                    onClick={() => initChargebeeSession(restaurant!._id)}
                                    style={{minWidth: 282}}>
                                    Update Payment Methods & Subscription
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                    </MenuCard>}
                    {(activeMobileMenu.name == 'create-menu' || activeMobileMenu.name == 'edit-menu' 
					|| activeMobileMenu.name == 'create-category' || activeMobileMenu.name == 'edit-category' 
					|| activeMobileMenu.name == 'create-item' || activeMobileMenu.name == 'edit-item') && 
                    
                    <MenuCard
                        style={{padding: 24, gap: 16, borderRadius: 0, marginBottom: '100px', width: '100%'}}>
						<RestaurantMenuListV2 />
                    </MenuCard>}

					{(activeMobileMenu.name == 'create-option-set' || activeMobileMenu.name == 'edit-option-set') &&      
					<MenuCard
						style={{padding: 24, gap: 16, borderRadius: 0}}>
						<RestaurantOptionSetListV2 />
		
					</MenuCard>}
					
					{(activeMobileMenu.name == 'create-dish-tag' || activeMobileMenu.name == 'edit-dish-tag') && 
						<RestaurantDishTagsV2 />			
					}
                </MainContainer>
            </div>
            {activeMobileModal == 'menus' && <MobileModal>
                <MobileModalContent style={{
                    height: '100%',
                    borderRadius: 'unset',
                    justifyContent: 'start'
                }}>
                    <MobileModalHeader style={{alignItems: 'center'}}>
                        More Actions
                        <span onClick={() => {
                           setActiveMobileModal('')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalBody>
                        <SettingsActionButtonWrapper variant='default' position='relative' noborder nopadding>             
                            <button
                                onClick={() => {
                                    setActiveMobileModal('menus-templates')
                                }}>
                                Templates
                            </button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper variant='default'  position='relative' noborder nopadding>             
                            <button
                                onClick={() => {
                                    setActiveMobileModal('menus-upload-service')
                                }}>
                                Upload Service
                            </button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper variant='default'  position='relative' noborder nopadding>             
                            <button
                                onClick={() => 
                                    window.open(
                                        'https://support.cloudwaitress.com/getting-started/setup-your-menus',
                                        '_blank')}>
                                Help
                            </button>
                        </SettingsActionButtonWrapper>
                    </MobileModalBody>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper style={{right: 0}}>
                            <button
                                onClick={() => {
                                    setActiveMobileModal('')
                                }}>
                                Cancel
                            </button>
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal>
            }

            {(activeMobileModal == 'menus-templates' ||
            activeMobileMenu.name == 'templates') && <MobileModal className='menus-v2'>
                <MobileModalContent style={{
                    height: '100%',
                    borderRadius: 'unset',
                    justifyContent: 'start',
                }}>
                    <MobileModalHeader style={{
                        alignItems: 'center',
                    }}>
                         Menu Template
                        <span onClick={() => {
                               if(activeMobileMenu && activeMobileMenu.name) {
                                store.setActiveMobileMenu(
                                   store.activeMobileMenu.referrer.pop()!,
                                   Array.from(new Set(store.activeMobileMenu.referrer))
                               )
                           } else setActiveMobileModal('menus')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalBody>
                        <p>Pick from one of the ready made templates to help you get started building your menu.</p>
                        <p>Choosing a template below will create a new menu, it will not overwrite any of your existing menus or items.</p>
                        
                    </MobileModalBody>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper style={{right: 0}}>
                        <button
                            className='btn-cancel'
                                onClick={() => {
                                    if(activeMobileMenu && activeMobileMenu.name) {
                                         store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!,
                                            Array.from(new Set(store.activeMobileMenu.referrer))
                                        )
                                    } else setActiveMobileModal('menus')
                                }}>
                                Cancel
                            </button>
                        <button
                                onClick={this.createPizzaMenu}>
                                {loading && <RotateLoader size={2} />}
								{!loading && "Create Pizza Menu"}
                            </button>
                            
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal>}

            {(activeMobileModal == 'menus-upload-service' ||
           activeMobileMenu.name == 'menus-upload-service') && <MobileModal className='menus-v2'>
                <MobileModalContent style={{
                    height: '100%',
                    borderRadius: 'unset',
                    justifyContent: 'start',
                }}>
                    <MobileModalHeader style={{
                        alignItems: 'center',
                    }}>
                         Menu Upload Service
                        <span onClick={() => {
                               if(activeMobileMenu && activeMobileMenu.name) {
                                store.setActiveMobileMenu(
                                   store.activeMobileMenu.referrer.pop()!,
                                   Array.from(new Set(store.activeMobileMenu.referrer))
                               )
                           } else setActiveMobileModal('menus')
                        }}>
                            <IconClose />
                        </span>
                    </MobileModalHeader>
                    <MobileModalBody>
                        <p>Need a hand uploading your menu? Simply complete the upload request form linked below.</p>
                        <p>As a free user, we charge <strong>$7 per hour</strong> for our upload service. <u>Upgrading to a paid plan will allow you to access this service for <strong>FREE</strong></u></p>
                        <p>We will process your request within 48-72 hours, notify you upon completion and bill you automatically. The average menu costs between $7 to $14 to upload.</p>
                        <p>Please ensure you have added a payment method to your account before completing the upload form as we won't start without it.</p>
                        <SettingsActionButtonWrapper variant='default'  position='relative' noborder nopadding>             
                            <button
                                onClick={() => initChargebeeSession(restaurant!._id)}
                                >
                               Update Payment Methods & Subscription
                            </button>
                        </SettingsActionButtonWrapper>
                        <SettingsActionButtonWrapper variant='default' position='relative' noborder nopadding>             
                            <button
                                onClick={ () => 
                                    window.open(`https://airtable.com/shrTnLexLfBva8wJO?prefill_Restaurant+ID=${restaurant!._id}&prefill_Restaurant+Subdomain=${restaurant!.subdomain}`, '_blank')
                                }>
                               Go to Upload Form
                            </button>
                        </SettingsActionButtonWrapper>
                    </MobileModalBody>
                    <MobileModalFooter>
                        <SettingsActionButtonWrapper style={{right: 0}}>
                        <button
                        
                                onClick={() => {
                                    if(activeMobileMenu && activeMobileMenu.name) {
                                         store.setActiveMobileMenu(
                                            store.activeMobileMenu.referrer.pop()!,
                                            Array.from(new Set(store.activeMobileMenu.referrer))
                                        )
                                    } else setActiveMobileModal('menus')
                                }}>
                                Cancel
                            </button>
                            
                        </SettingsActionButtonWrapper>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal>}
        		</>
				</div>
				{/* <div className='mobile-view'>
				<MobileMenus />
				</div> */}
			</MenuWrapper>
        )
    }
}

