import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../mobx/components';
import styled, { css, withTheme } from 'styled-components';
import { IconArrowDownward, IconArrowForward, IconArrowUpward, IconCheck, IconClose, IconEvent, IconHelp, IconMailAlt, IconMoney, IconMoreOptions, IconMoreOptionsHorizontal, IconShoppingBag, IconTips, IconTips2, MailIcon } from '../../../../components/v2/icons';
import { SettingsFormGroupInput, SettingsFormInputContainer } from '../settings-v2';
import { DatePicker, RotateLoader, SelectAdv } from '@lib/components';
import { IconCalendarAlt } from '@lib/components/dist/esm/components/icons/order-icons-v2';
import { Bar, BarChart, CartesianGrid, Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getOptionalOverviewReportEmail } from '../../../../utils/overview_report_email';
import _capitalize from "lodash/capitalize";
import _uniq from "lodash/uniq";
import { RestaurantUtils, Untrusive, logger } from '@lib/common';
import moment from "moment-timezone";
import capitalize from 'lodash/capitalize';
import { MobileModal } from '../settings-v2';
import { MobileModalContent } from '../settings-v2';
import { MobileModalHeader } from '../settings-v2';
import { MobileModalBody } from '../settings-v2';
import { SettingsFormLabelContainer } from '../settings-v2';
import { SettingsFormLabelLeftContainer } from '../settings-v2';
import { SettingsFormLabelRightContainer } from '../settings-v2';
import { SettingsCustomOptionsContainer } from '../settings-v2';
import { SettingsCustomOption } from '../settings-v2';
import { MobileModalFooter } from '../settings-v2';
import { SettingsActionButtonWrapper } from '../settings-v2';
import { UI } from '../../../../../core/ui';

const TopControlsContainer = styled.div`
display: flex;
padding: 8px 16px;
align-items: center;
gap: 8px;
align-self: stretch;
width: calc(100% + 28px);
position: relative;
left: -14px;
top: -14px;

background: var(--Neutral-White, #FEFEFE);
& div[class^="settings-v2__SettingsFormGroupInput"] {
    @media (min-width: 768px) {
        flex: 0 0 350px!important;
    }
}
& div[class^="settings-v2__SettingsFormInputContainer"] {
    padding: 0px 12px;
    & input {
        height: auto;
        padding: 8px 12px;
    }
}
`
const TopControlsContainerHeader = styled.div`
display: none;
height: 40px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
flex: 1 0 0;
@media (min-width: 768px) {
    display: flex;
}
`
const TopControlsContainerHeaderTitle = styled.div`

display: flex;
height: 24px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
align-self: stretch;

color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 40px; /* 222.222% */

`
const RoundedIconWrapper = styled.div`
display: flex;
padding: 10px;
align-items: center;
gap: 8px;

border-radius: 4px;
background: var(--Neutral-Gray, #F1F4F7);
`

const MetricsCard = styled.div<{full?: boolean}>`
display: flex;
padding: 16px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
flex: 0 0 calc(50% - 8px);
@media (min-width: 992px) {
    flex: 1;
}
 ${ props => props.full ? `
    flex: 100%;
    @media (min-width: 992px) {
        flex: 1;
    }
` : null }

border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);
`
const MetricsCardBody = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
`
const MetricsCardStats = styled.div`
display: flex;
align-items: center;
gap: 8px;
align-self: stretch;
`
const MetricsCardNumbers = styled.div`
flex: 1 0 0;

color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 120% */
`
const MetricsCardPercentage = styled.div<{variant?: string}>`
display: flex;
padding: 2px;
align-items: flex-start;

border-radius: 4px;
background: rgba(0, 145, 0, 0.10);

& > * {
    color: var(--Status-Completed, #009100);
font-family: Inter;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 160% */
}

${props => props.variant === 'danger' ?
 `border-radius: 4px;
 background: rgba(188, 54, 47, 0.10);
 & > * {
    color: var(--Status-Cancelled, #BC362F);
 }`
: null}
`
const MetricsCardText = styled.div`
    align-self: stretch;

    color: var(--Secondary-Smokey-Grey-1, #6B7270);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */

`
const MetricsContainer = styled.div`
display: flex;
flex-wrap: wrap;
align-items: flex-start;
gap: 16px;
align-self: stretch;
width: 100%;
`
const MetricsCardIconContainer = styled.div`
display: none;
align-items: flex-start;
gap: 16px;
@media (min-width: 992px) {
    display: flex;
}
`

const MetricsCardIconWrapper = styled.div`
display: flex;
padding: 12px;
align-items: flex-start;
gap: 10px;

border-radius: 4px;
background: var(--Secondary-Light-Grey-1, #DFDFDF);


`
const CardContainer = styled.div<{full?: boolean}>`
display: flex;
width: 100%;
padding: 16px;
flex-direction: column;
align-items: center;
gap: 16px;

border-radius: 4px;
background: #FEFEFE;
${props => props.full ? 'flex: 100%;': `
flex: 1;
@media (min-width: 992px) {
flex: 0 0 calc(50% - 8px);
}
`}

`
const CardContainerHeader = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 16px;
align-self: stretch;
`
const CardContainerHeaderTitle = styled.div`
flex: 1 0 0;

color: var(--Secondary-Dark-1, #0C202A);
font-feature-settings: 'liga' off, 'clig' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 200% */
`
const CardContainerHeaderTabs = styled.div`
display: flex;
align-items: flex-start;
`
const CardContainerHeaderTabItem = styled.div<{active?: boolean}>`
display: flex;
padding: 4px 8px;
justify-content: center;
align-items: center;
gap: 16px;
cursor: pointer;

border-radius: 4px;
background: var(--Neutral-White, #FEFEFE);
color: var(--Secondary-Smokey-Grey-1, #6B7270);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 200% */

${props => props.active ?
    `background: var(--Primary-Accent-Wisp-Pink-1, #FFF3F3);
    color: var(--Primary-Red-Orange-1, #FB2F2F);
`
: null}
`
const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    padding-bottom: 16px;
`
const ChartContainer = styled.div`
display: flex;
align-items: center;
gap: 24px;
align-self: stretch;

@media (max-width: 375px) {
    & .recharts-responsive-container {
        width: 100px!important;
        & .recharts-wrapper {
            width: 100px!important;
            & .recharts-surface {
                width: 100px!important;
            }
        }
    }
}

@media (max-width: 316px) {
    flex-direction: column;
}
`
const ChartList = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
`
const ChartListItem = styled.div`
display: flex;
align-items: center;
gap: 8px;
align-self: stretch;
`
const ChartListItemLegend = styled.div<{variant?: string, fill?: string}>`
display: flex;
padding: 4px;
justify-content: center;
align-items: center;
gap: 16px;
&::before {
    width: 12px;
    height: 12px;
    background: #0064C8;
    content: '';
    border-radius: 100%;

    ${props => props.variant == 'pickup' ? 'background: #E5AC00;': null}
    ${props => props.variant == 'dine-in' || props.variant == 'dine_in' ? 'background: #009100;': null}
    ${props => props.variant == 'booking' || props.variant == 'table_booking' ? 'background: #00A6DD;': null}
    ${props => props.variant == 'cash' ? 'background: #4A0000;': null}
    ${props => props.variant == 'card' ? 'background: #A30800;': null}
    ${props => props.variant == 'stripe' ? 'background: #FF6262;': null}
    ${props => props.fill ? `background: ${props.fill}`: null}
}
`
const ChartListItemContent = styled.div`
display: flex;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
`
const ChartListItemContentTitle = styled.div`
flex: 1 0 0;

color: var(--Secondary-Smokey-Grey-1, #6B7270);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
`
const ChartListItemContentAmount = styled.div`
flex: 1 0 0;

color: var(--Secondary-Dark-1, #0C202A);
text-align: right;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
`
const TableFlex = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
width: 100%;
`
const TableFlexRow = styled.div`
display: flex;
align-items: center;
gap: 8px;
align-self: stretch;
`
const TableFlexRowHeader = styled.div`
display: flex;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
`
const TableFlexRowHeaderData = styled.div`
width: 96px;

color: rgba(107, 114, 112, 0.60);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 200% */
&:not(:first-child) {
    width: auto;
    flex: 1 0 0;
    text-align: right;
}
`
const TableFlexRowBody = styled(TableFlexRow)`
    padding-bottom: 8px;
    border-bottom: 1px dashed var(--Secondary-Light-Grey-1, #DFDFDF);
`
const TableFlexRowData = styled(TableFlexRowHeader)``

const TableFlexRowDataCell = styled(TableFlexRowHeaderData)`
color: var(--Secondary-Dark-Grey-1, #4B4F5E);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
`
const COLORS = [
'#E5AC00',
'#0064C8',
'#009100',
'#00A6DD'
]
const PAYMENTS_COLORS = [
'#4A0000',
'#A30800',
'#FF6262'
]

type Period = "day" | "week" | "month" | "year";
interface Props extends WithTranslation {}
interface State {
      start: number;
      end: number;
      period: Period;
      chart: "sales" | "orders";
      filtersActive: boolean;
      sendEmailActive: boolean;
      counts: "item" | "category" | "tax";
      error: boolean;
      data: T.API.DashboardReportsBasicResponse | null;
      emails: string[];
      emailOptions: string[];
      isSendingEmail: boolean;
      isCustomFilter: boolean;
      orderTypeActive: string;
      paymentsMethodActive: string;
  }

@inject("store") @observer
class RestaurantDashboardV2Class extends MobxComponent<Props, State> {
    mounted = false;

    constructor(props: Props) {
		super(props);

		const { store } = this.injected;
		const r = store.restaurant!;
		const emailStore = r.settings.notifications.email.store || undefined;
		const optionalOverviewReportEmail =
		emailStore && getOptionalOverviewReportEmail(emailStore) || [];

		const overviewReportEmails = emailStore && emailStore.overview_report_emails || [];

		const emails =
			overviewReportEmails.length > 0
				? overviewReportEmails
				: optionalOverviewReportEmail;
		const emailOptions = _uniq([...emails, ...optionalOverviewReportEmail]);

		this.state = {
			...this.getTimeRange(Date.now(), "day"),
			chart: "sales",
			filtersActive: false,
			sendEmailActive: false,
			counts: "item",
			error: false,
			data: null,
			emails,
			emailOptions,
			isSendingEmail: false,
            isCustomFilter: false,
            orderTypeActive: 'sales',
            paymentsMethodActive: 'sales'
		};
	}

	componentDidMount() {
		this.mounted = true;
		this.query();
	}

	componentWillUnmount(): void {
		this.mounted = false;
	}

	query = async () => {
		try {
			this.setState({ error: false, data: null });
			const { store } = this.injected;
			const { start, end, period } = this.state;
			const r = store.restaurant!;
			const data = await this.injected.store.api.reports_basic({
				restaurant_id: r._id,
				start: start,
				end: end,
				period: period,
			});
			if (data.outcome && this.mounted) {
				this.setState({
					error: true,
					data: null,
				});
			} else if (this.mounted) {
				this.setState({ data });
			}
		} catch (e) {
            logger.captureException(e);
			if (this.mounted) {
				this.setState({
					error: true,
					data: null,
				});
			}
		}
	};

    setTimePeriod = (period: Period) => {
		this.setState(
			{
				...this.getTimeRange(Date.now(), period),
			},
			this.query
		);
	};

	getTimeRange = (start: number, period: Period, custom?: boolean, end?: number) => {
		const { store } = this.injected;
		const r = store.restaurant!;
		const tz = r.settings.region.timezone;
		const start_day = r.settings.region.day_of_the_week;
	
		let momentStart = moment.tz(start, tz).startOf(period);
		let momentEnd = moment.tz(start, tz).endOf(period);
		if(period === 'week' && start_day === 'mon') {
			momentStart = moment.tz(start, tz).startOf('isoWeek');
			momentEnd = moment.tz(start, tz).endOf('isoWeek');
		} else if(custom) {
            momentStart = moment.tz(start, tz).startOf('day');
			momentEnd = moment.tz(end, tz).endOf('day');
        }
		return {
			start: momentStart.valueOf(),
			end: momentEnd.valueOf(),
			period: period,
		};
	};

	changeTimeRange = (direction: "forward" | "backward") => {
		const tz = this.injected.store.restaurant!.settings.region.timezone;
		const { start, period } = this.state;
		const fn = direction === "forward" ? "add" : "subtract";
		const next = moment.tz(start, tz)[fn](1, period).valueOf();
		this.setState(
			{
				...this.getTimeRange(next, period),
			},
			this.query
		);
	};

	reset = () => {
		this.setTimePeriod("day");
	};

    renderCounts = () => {
        const { data, error, counts} = this.state;
		const { store } = this.injected;
		const availableCategoryCounts: T.API.DashboardReportsBasicResponseCounts[] =
			[];
		const deletedCategoryCounts: T.API.DashboardReportsBasicResponseCounts[] =
			[];
		const dishCounts: T.API.DashboardReportsBasicResponseCounts[] = [];

		if (!data || data.outcome === 1 || error) {
			return <CardContainer style={{alignItems: 'center', justifyContent: 'center'}}>
            <RotateLoader size={3} />
            </CardContainer>;
		}

		const r = store.restaurant!;

		function sortItems(
			a: T.API.DashboardReportsBasicResponseCounts,
			b: T.API.DashboardReportsBasicResponseCounts
		) {
			// Use toUpperCase() to ignore character casing
			const qtyA = a.qty;
			const qtyB = b.qty;

			let comparison = 0;
			if (qtyA < qtyB) {
				comparison = 1;
			} else if (qtyA > qtyB) {
				comparison = -1;
			}
			return comparison;
		}

		// get the Category Name based on Category Id get from API
		if (data.category_counts) {
			data.category_counts.map((categoryCount) => {
				const category = RestaurantUtils.menu.findCategoryById(
					r,
					categoryCount._id
				);
				if (category) {
					availableCategoryCounts.push({
						name:
							category.category.display_name ||
							category.category.name,
						qty: categoryCount.qty,
					});
				} else
					deletedCategoryCounts.push({
						name: `Deleted Category - ${categoryCount._id}`,
						qty: categoryCount.qty,
					});
			});
		}

		if (data.dish_counts) {
			data.dish_counts.map((dishCount) => {
				dishCounts.push({ name: dishCount.name, qty: dishCount.qty });
			});
		}

		const countsData: T.API.DashboardReportsBasicResponseCounts[] =
			counts === "item"
				? dishCounts.sort(sortItems)
				: counts === "category"
				? [
						...availableCategoryCounts.sort(sortItems),
						...deletedCategoryCounts.sort(sortItems),
				  ]
				: data.tax_data;
        return (

        <CardContainer>
            <CardContainerHeader>
                <CardContainerHeaderTitle>Order Count</CardContainerHeaderTitle>
                <CardContainerHeaderTabs>
                    <CardContainerHeaderTabItem 
                        onClick={() => this.setState({
                            counts: 'item'
                        })}
                        active={counts === 'item'}>Items</CardContainerHeaderTabItem>
                    <CardContainerHeaderTabItem
                    onClick={() => this.setState({
                        counts: 'category'
                    })}
                        active={counts === 'category'}>Category</CardContainerHeaderTabItem>
                    <CardContainerHeaderTabItem 
                    onClick={() => this.setState({
                        counts: 'tax'
                    })}
                        active={counts === 'tax'}>Tax</CardContainerHeaderTabItem>
                </CardContainerHeaderTabs>
            </CardContainerHeader>
            <TableFlex>
                <TableFlexRow>
                    <TableFlexRowHeader>
                        <TableFlexRowHeaderData>Items</TableFlexRowHeaderData>
                        <TableFlexRowHeaderData>Count</TableFlexRowHeaderData>
                    </TableFlexRowHeader>
                </TableFlexRow>
                {countsData.map((item, index) => {
				    return ( 
                    <TableFlexRowBody key={index}>
                        <TableFlexRowData>
                            <TableFlexRowDataCell style={{flex: 1}}>{capitalize(item.name)}</TableFlexRowDataCell>
                            <TableFlexRowDataCell>{item.qty}</TableFlexRowDataCell>
                        </TableFlexRowData>
                    </TableFlexRowBody>)
                })}
            </TableFlex>
        </CardContainer>
        )
    }
    
    displayPeriod = (period: Period, custom?: boolean, start?: number, end?: number) => {
        const { store } = this.injected;
        const r = store.restaurant!;
        const dateFormat = r.settings.region.formats.date;
        if(period === 'week' || custom) {
            let times = this.getTimeRange(Date.now(), period);
            if(custom) {
                times = this.getTimeRange(start!, period, custom, end);
                return 'Custom - '+moment(times.start).format(dateFormat)+
                ' to '+moment(times.end).format(dateFormat);
            } else  {
            return 'This week - '+moment(times.start).format(dateFormat)+
            ' to '+moment(times.end).format(dateFormat);
            }
        } else if(period === 'month') {
            return 'This month - '+moment().format('MMMM YYYY');
        } else if(period === 'year') {
            return 'This year - '+moment().format('YYYY');
        }
        return 'Today - '+moment().format(dateFormat);
    }
    
    sendEmail = async () => {
		const { store } = this.injected;

		try {
			Untrusive.start();
			this.setState({ isSendingEmail: true });

			const { start, end, period, emails } = this.state;
			const r = store.restaurant!;

			// await new Promise((resolve, reject) => setTimeout(reject, 2000));
			await store.api.send_overview_report_email({
				restaurant_id: r._id,
				start: start,
				end: end,
				period: period,
				emails,
			})
            UI.notification.success("Overview Report has been sent!");
			this.setState({ sendEmailActive: false });
		} catch (e) {
			logger.captureException(e);
			UI.notification.error(
				"An error occurred, try again soon or contact us",
				{ timeout: 6000 }
			);
		} finally {
			Untrusive.stop();
			this.setState({ isSendingEmail: false });
		}
	};

    getDataBreakdown() {
        const { chart, data } = this.state
        if(data && data.outcome === 0) {
     
        if(chart === 'orders') {
            return data.orders_breakdown
        }
        return  data.sales_breakdown
        } return []
    }
    
    getDataPayments(activePayment: string) {
        const { data } = this.state
        if(data && data.outcome === 0) {
     
        if(activePayment === 'counts') {
            return data.payment_counts
        } else if(activePayment === 'tips') {
            return data.payment_tips
        }
        return  data.payment_sales
        } return []
    }

    getDataService(activeService: string) {
        const { data } = this.state
        if(data && data.outcome === 0) {
     
        if(activeService === 'count') {
            return data.service_counts
        } else if(activeService === 'tips') {
            return data.service_tips
        }
        return  data.service_sales
        } return []
    }

    getMetricsPercentage(current: number, previous: number) {
        const compute = ((current - previous) / current);
        const is_increase = current > previous;
        const percentage = compute * 100;
        return (
            <MetricsCardPercentage variant={!is_increase ? 'danger': undefined}>
                {is_increase ? <IconArrowUpward /> : <IconArrowDownward />}
                <span>
                {percentage.toFixed(2)}%
                </span>
            </MetricsCardPercentage>
        )
    }
    render() {
        const { store, t } = this.injected;
        const { saveRestaurant } = this;
        const { activeMobileMenu, activeMobileModal, setActiveDesignMode} = store;
        const { 
            data,
            error,
            sendEmailActive,
            filtersActive,
            emailOptions,
            emails,
            period,
            isCustomFilter,
            start,
            end,
            isSendingEmail,
            orderTypeActive,
            chart,
            paymentsMethodActive} = this.state
        const emailOptionsLabel = emailOptions.map((address) => ({
			label: address,
			value: address,
		}));
        return (<div style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
            <TopControlsContainer>
                <TopControlsContainerHeader>
                    <TopControlsContainerHeaderTitle>Dashboard</TopControlsContainerHeaderTitle>
                </TopControlsContainerHeader>
                <SettingsFormGroupInput>
                    <SettingsFormInputContainer onClick={() => this.setState({
                    filtersActive: !this.state.filtersActive
                })}>
                       
                        <input 
                            type="text"
                            readOnly
                            value={this.displayPeriod(
                                    period,
                                    isCustomFilter,
                                    start,
                                    end)}
                            />
                         <IconCalendarAlt />
                    </SettingsFormInputContainer>
                </SettingsFormGroupInput>
                <RoundedIconWrapper onClick={() => this.setState({
                    sendEmailActive: !this.state.sendEmailActive
                })}>
                    <IconMailAlt />
                </RoundedIconWrapper>
            </TopControlsContainer>
            <MainContainer>
                <MetricsContainer>
                    <MetricsCard full>
                        <MetricsCardIconContainer>
                            <MetricsCardIconWrapper>
                                <IconMoney />
                            </MetricsCardIconWrapper>
                        </MetricsCardIconContainer>
                        <MetricsCardBody>
                            <MetricsCardStats>
                                {data && data.outcome == 0 && !error ?
                                <MetricsCardNumbers>
                                {
                                   t("currency", { value: data.sales_total.toFixed(2) })
                                }
                                </MetricsCardNumbers>:
                                <MetricsCardNumbers>$0.00</MetricsCardNumbers>
                                }
                              {data && data.outcome == 0 && data.sales_total > 0 && 
                                this.getMetricsPercentage(data.sales_total, data.previous_sales_total)}
                            </MetricsCardStats>
                            <MetricsCardText>Total Sales</MetricsCardText>
                        </MetricsCardBody>
                    </MetricsCard>
                    <MetricsCard>
                        <MetricsCardIconContainer>
                            <MetricsCardIconWrapper>
                                <IconShoppingBag />
                            </MetricsCardIconWrapper>
                        </MetricsCardIconContainer>
                        <MetricsCardBody>
                            <MetricsCardStats>
                                {data && data.outcome == 0 && !error ?
                                    <MetricsCardNumbers>{t("number", { value: data.orders_total })}</MetricsCardNumbers>
                                :<MetricsCardNumbers>0</MetricsCardNumbers>}
                                {data && data.outcome == 0 && data.orders_total > 0 && 
                                this.getMetricsPercentage(data.orders_total, data.previous_orders_total)}
                            </MetricsCardStats>
                            <MetricsCardText>Total Orders</MetricsCardText>
                        </MetricsCardBody>
                    </MetricsCard>
                    <MetricsCard>
                    <MetricsCardIconContainer>
                            <MetricsCardIconWrapper>
                                <IconEvent />
                            </MetricsCardIconWrapper>
                        </MetricsCardIconContainer>
                        <MetricsCardBody>
                            <MetricsCardStats>
                                {data && data.outcome == 0 && !error ?
                                    <MetricsCardNumbers>{t("number", { value: data.service_counts.table_booking })}</MetricsCardNumbers>
                                :<MetricsCardNumbers>0</MetricsCardNumbers>}
                                 {data && data.outcome == 0 && data.service_counts.table_booking > 0 && 
                                this.getMetricsPercentage(data.service_counts.table_booking, data.previous_table_booking)}
                            </MetricsCardStats>
                            <MetricsCardText>Number of Booking</MetricsCardText>
                        </MetricsCardBody>
                    </MetricsCard>
                    <MetricsCard>
                        <MetricsCardIconContainer>
                            <MetricsCardIconWrapper>
                                <IconTips />
                            </MetricsCardIconWrapper>
                        </MetricsCardIconContainer>
                        <MetricsCardBody>
                            <MetricsCardStats>
                            {data && data.outcome == 0 && !error ?
                                <MetricsCardNumbers>{t("number", { value: data.tips_count })}</MetricsCardNumbers>
                            :<MetricsCardNumbers>0</MetricsCardNumbers>}
                                {data && data.outcome == 0 && data.tips_count > 0 && 
                                this.getMetricsPercentage(data.tips_count, data.previous_tips_count)}
                            </MetricsCardStats>
                            <MetricsCardText>Number of Tips</MetricsCardText>
                        </MetricsCardBody>
                    </MetricsCard>
                    <MetricsCard>
                    <MetricsCardIconContainer>
                            <MetricsCardIconWrapper>
                                <IconTips2 />
                            </MetricsCardIconWrapper>
                        </MetricsCardIconContainer>
                        <MetricsCardBody>
                            <MetricsCardStats>
                            {data && data.outcome == 0 && !error ?
                             <MetricsCardNumbers>
                            {
							store.restaurant?.settings.region.currency.symbol
								? `${store.restaurant?.settings.region.currency.symbol} ${data.tips_total}`
								: t("currency", { value: data.tips_total })
						    }</MetricsCardNumbers>
                            :<MetricsCardNumbers>$0.00</MetricsCardNumbers>}
                                {data && data.outcome == 0 && data.tips_total > 0 && 
                                this.getMetricsPercentage(data.tips_total, data.previous_tips_total)}
                            </MetricsCardStats>
                            <MetricsCardText>Total Tips</MetricsCardText>
                        </MetricsCardBody>
                    </MetricsCard>

                </MetricsContainer>
                {data && data.outcome == 0 && !error  ?
                <CardContainer full>
                    <CardContainerHeader>
                        <CardContainerHeaderTitle>Trends</CardContainerHeaderTitle>
                        <CardContainerHeaderTabs>
                            <CardContainerHeaderTabItem
                                onClick={() => {
                                    this.setState({
                                        chart: 'sales'
                                    })
                                }}
                                active={chart === 'sales'}>Sales</CardContainerHeaderTabItem>
                            <CardContainerHeaderTabItem
                                onClick={() => {
                                    this.setState({
                                        chart: 'orders'
                                    })
                                }}
                                active={chart === 'orders'}>Orders</CardContainerHeaderTabItem>
                            <CardContainerHeaderTabItem><IconMoreOptionsHorizontal /></CardContainerHeaderTabItem>
                        </CardContainerHeaderTabs>
                    </CardContainerHeader>
                    <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        width={500}
                        height={300}
                        data={this.getDataBreakdown().map(sb => ({
                            [sb.l]: sb.v,
                            name: sb.l
                        }))}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                        barSize={24}
                        barGap={0}
                        barCategoryGap={0}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        {data.sales_breakdown.map((sb, index) => (
                            <Bar key={index} dataKey={sb.l} stackId={sb.l} fill={COLORS.concat(PAYMENTS_COLORS)[index % COLORS.concat(PAYMENTS_COLORS).length]} />
                        ))}
                        </BarChart>
                    </ResponsiveContainer>
                </CardContainer>: <CardContainer full style={{alignItems: 'center', justifyContent: 'center'}}><RotateLoader size={3} />
                </CardContainer>}
              
                {data && data.outcome == 0 && !error  ?
                <CardContainer>
                    <CardContainerHeader>
                        <CardContainerHeaderTitle>Order Types</CardContainerHeaderTitle>
                        <CardContainerHeaderTabs>
                            <CardContainerHeaderTabItem 
                            onClick={() => this.setState({
                                orderTypeActive: 'sales'
                            })}
                                active={orderTypeActive === 'sales'}>Sales</CardContainerHeaderTabItem>
                            <CardContainerHeaderTabItem 
                            onClick={() => this.setState({
                                orderTypeActive: 'count'
                            })}
                            active={orderTypeActive === 'count'}>Count</CardContainerHeaderTabItem>
                            <CardContainerHeaderTabItem 
                            onClick={() => this.setState({
                                orderTypeActive: 'tips'
                            })}
                            active={orderTypeActive === 'tips'}>Tips</CardContainerHeaderTabItem>
                        </CardContainerHeaderTabs>
                    </CardContainerHeader>
                   
                    <ChartContainer>
                        <ResponsiveContainer width={180}  height={240}>
                            <PieChart width={120} height={120} onMouseEnter={() => {}}>
                                <Pie
                                data={Object.entries(this.getDataService(orderTypeActive))
                                    .filter(([_, value]) => value > 0).map(([name, value]) => { return {name, value} }) }
                                cx={80}
                                cy={120}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="value"
                                >
                                {Object.entries(this.getDataService(orderTypeActive))
                                    .filter(([_, value]) => value > 0).map((_, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                </Pie>
                                <Label value="Total" position='center' />
                            </PieChart>
                        </ResponsiveContainer>
                        <ChartList>
                            {Object.entries(this.getDataService(orderTypeActive)).filter(([_, value]) => value > 0)
                            .map(([method, value], index) => (
                            <ChartListItem key={index}>
                                <ChartListItemLegend variant={method} />
                                <ChartListItemContent>
                                    <ChartListItemContentTitle>{capitalize(method)}</ChartListItemContentTitle>
                                    <ChartListItemContentAmount>{orderTypeActive == 'count' ? value :t("currency", { value: value.toFixed(2) })}</ChartListItemContentAmount>
                                </ChartListItemContent>
                            </ChartListItem>
                            ))}
                        </ChartList>
                    </ChartContainer>
                    </CardContainer>
                : <CardContainer style={{alignItems: 'center', justifyContent: 'center'}}><RotateLoader size={3} />
                </CardContainer>}
                
              
              
                {data && data.outcome == 0 && !error  ?
                 <CardContainer>
                    <CardContainerHeader>
                        <CardContainerHeaderTitle>Payments Methods</CardContainerHeaderTitle>
                        <CardContainerHeaderTabs>
                            <CardContainerHeaderTabItem
                                onClick={() => this.setState({
                                    paymentsMethodActive: 'sales'
                                })}
                                active={paymentsMethodActive === 'sales'}>Sales</CardContainerHeaderTabItem>
                            <CardContainerHeaderTabItem 
                            onClick={() => this.setState({
                                paymentsMethodActive: 'counts'
                            })}
                            active={paymentsMethodActive === 'orders'}>Counts</CardContainerHeaderTabItem>
                            <CardContainerHeaderTabItem 
                            onClick={() => this.setState({
                                paymentsMethodActive: 'tips'
                            })}
                            active={paymentsMethodActive === 'tips'}>Tips</CardContainerHeaderTabItem>
                        </CardContainerHeaderTabs>
                    </CardContainerHeader>
                    <ChartContainer>
                        <ResponsiveContainer width={180}  height={240}>
                            <PieChart width={120} height={120} onMouseEnter={() => {}}>
                                <Pie
                                data={Object.entries(this.getDataPayments(paymentsMethodActive)).filter(([_, value]) => value > 0).map(([name, value]) => { return {name, value} }) }
                                cx={80}
                                cy={120}
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="value"
                                >
                                {Object.entries(this.getDataPayments(paymentsMethodActive)).filter(([_, value]) => value > 0).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={PAYMENTS_COLORS[index % PAYMENTS_COLORS.length]} />
                                ))}
                                </Pie>
                                <Label value="Total" position='center' />
                            </PieChart>
                        </ResponsiveContainer>
                        <ChartList>
                            {Object.entries(this.getDataPayments(paymentsMethodActive)).filter(([_, value]) => value > 0)
                            .map(([method, value], index) => {
                                return (
                                    <ChartListItem key={index}>
                                        <ChartListItemLegend fill={PAYMENTS_COLORS[index % PAYMENTS_COLORS.length]}/>
                                        <ChartListItemContent >
                                            <ChartListItemContentTitle>{capitalize(method)}</ChartListItemContentTitle>
                                            <ChartListItemContentAmount>{paymentsMethodActive === 'counts' ? value :t("currency", { value: value.toFixed(2) })}</ChartListItemContentAmount>
                                        </ChartListItemContent>
                                    </ChartListItem>
                                )
                            })}
                          
                        </ChartList>
                    </ChartContainer>
                    </CardContainer>: <CardContainer style={{alignItems: 'center', justifyContent: 'center'}}><RotateLoader size={3} /></CardContainer> }
                    
                {data && data.outcome == 0 && !error  ?
                <CardContainer>
                    <CardContainerHeader>
                        <CardContainerHeaderTitle>Order Completion</CardContainerHeaderTitle>
                    </CardContainerHeader>
                    <TableFlex>
                        <TableFlexRow>
                            <TableFlexRowHeader>
                                <TableFlexRowHeaderData>Service Type</TableFlexRowHeaderData>
                                <TableFlexRowHeaderData>Count</TableFlexRowHeaderData>
                                <TableFlexRowHeaderData>Sales</TableFlexRowHeaderData>
                                <TableFlexRowHeaderData>Tips</TableFlexRowHeaderData>
                            </TableFlexRowHeader>
                        </TableFlexRow>
                        <TableFlexRowBody>
                            <TableFlexRowData>
                                <TableFlexRowDataCell>Complete</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && data.status_counts.complete || 0}</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && t('currency',{ value: data.status_sales.complete.toFixed(2)}) || '$0.00'}</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && t('currency',{ value: data.status_tips.complete.toFixed(2)}) || '$0.00'}</TableFlexRowDataCell>
                            </TableFlexRowData>
                        </TableFlexRowBody>
                        <TableFlexRowBody>
                            <TableFlexRowData>
                                <TableFlexRowDataCell>Incomplete</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && data.status_counts.incomplete || 0}</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && t('currency',{ value: data.status_sales.incomplete.toFixed(2)}) || '$0.00'}</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && t('currency',{ value: data.status_tips.incomplete.toFixed(2)}) || '$0.00'}</TableFlexRowDataCell>
                            </TableFlexRowData>
                        </TableFlexRowBody>
                        <TableFlexRowBody>
                            <TableFlexRowData>
                                <TableFlexRowDataCell>Cancelled</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && data.status_counts.cancelled || 0}</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && t('currency',{ value: data.status_sales.cancelled.toFixed(2)}) || '$0.00'}</TableFlexRowDataCell>
                                <TableFlexRowDataCell>{data && data.outcome == 0 && t('currency',{ value: data.status_tips.cancelled.toFixed(2)}) || '$0.00'}</TableFlexRowDataCell>
                            </TableFlexRowData>
                        </TableFlexRowBody>
                    </TableFlex>
                </CardContainer>: <CardContainer style={{alignItems: 'center', justifyContent: 'center'}}><RotateLoader size={3} /></CardContainer> }
                {this.renderCounts()}
            </MainContainer>

            {filtersActive && <MobileModal className='dashboard-filter-modal'>
                    <MobileModalContent>
                        <MobileModalHeader>
                            Date Settings
                            <span onClick={() => {
                                this.setState({
                                    filtersActive: !this.state.filtersActive
                                })
                            }}>
                                <IconClose />
                            </span>
                        </MobileModalHeader>
                        <MobileModalBody style={{paddingTop: 0}}>
                        <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Date Range</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer  style={{paddingLeft: 0, paddingRight: 0, border: 'none'}}>
                                <SettingsCustomOptionsContainer style={{flexWrap: 'wrap', width: '100%'}}>
                                    <SettingsCustomOption
                                        onClick={() => {
                                            this.setState({
                                                period: 'day',
                                                isCustomFilter: false
                                            })
                                        }}
                                        className={period === 'day' && !isCustomFilter ? 'active': ''} style={{flex: 1}}>
                                        Day
                                        {period === 'day' && !isCustomFilter ? <IconCheck />: null}
                                    </SettingsCustomOption>
                                    <SettingsCustomOption 
                                    onClick={() => {
                                        this.setState({
                                            period: 'week',
                                            isCustomFilter: false
                                        })
                                    }}
                                        className={period === 'week' && !isCustomFilter ? 'active': ''} style={{flex: 1}}>
                                            Week
                                        {period === 'week' && !isCustomFilter ? <IconCheck />: null}
                                    </SettingsCustomOption>
                                    <SettingsCustomOption 
                                    onClick={() => {
                                        this.setState({
                                            period: 'month',
                                            isCustomFilter: false
                                        })
                                    }}
                                        className={period === 'month' && !isCustomFilter  ? 'active': ''} style={{flex: 1}}>
                                        Month
                                        {period === 'month' && !isCustomFilter ? <IconCheck />: null}
                                    </SettingsCustomOption>
                                    <SettingsCustomOption 
                                        onClick={() => {
                                            this.setState({
                                                period: 'year',
                                                isCustomFilter: false
                                            })
                                        }}
                                        className={period === 'year' && !isCustomFilter  ? 'active': ''} style={{flex: 1}}>
                                        Year
                                        {period === 'year' && !isCustomFilter ? <IconCheck />: null}
                                    </SettingsCustomOption>
                                    <SettingsCustomOption
                                        onClick={() => {
                                            this.setState({
                                                isCustomFilter: true,
                                                period: 'day'
                                            })
                                        }}
                                        className={isCustomFilter ? 'active': ''} style={{flex: 1}}>Custom</SettingsCustomOption>
                                </SettingsCustomOptionsContainer>
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            {isCustomFilter && <>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>From</SettingsFormLabelLeftContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <IconCalendarAlt />
                                    <DatePicker
                                        date={new Date(start)}
                                        displayFormat='MMM D, Y'
                                        locale='en-AU'
                                        timezone='Australia/Brisbane'
                                        onSelect={(value) => {
                                            this.setState({
                                                start: value.millis
                                            })
                                        }}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>To:</SettingsFormLabelLeftContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <IconCalendarAlt />
                                    <DatePicker
                                        date={new Date(end)}
                                        displayFormat='MMM D, Y'
                                        locale='en-AU'
                                        timezone='Australia/Brisbane'
                                        onSelect={(value) => {
                                            console.log(value)
                                            this.setState({
                                                end: value.millis
                                            })
                                        }}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            </>}

                        </MobileModalBody>
                        <MobileModalFooter>
                            <SettingsActionButtonWrapper
                                position='relative' noborder nopadding>
                                <button type="button" onClick={() => {
                                    let timeRanges = {...this.getTimeRange(Date.now(), this.state.period)}
                                    if(isCustomFilter) {
                                        timeRanges = {...this.getTimeRange(start, 'year', isCustomFilter, end)}
                                    }
                                    this.setState({
                                        filtersActive: !this.state.filtersActive,
                                        ...timeRanges
                                    }, this.query)
                                 }}> Save</button>
                            </SettingsActionButtonWrapper> 
                        </MobileModalFooter>
                    </MobileModalContent>
                </MobileModal> }
            {sendEmailActive && <MobileModal className='dashboard-filter-modal'>
                    <MobileModalContent>
                        <MobileModalHeader>
                            Email Report
                            <span onClick={() => {
                                this.setState({
                                    sendEmailActive: !this.state.sendEmailActive
                                })
                            }}>
                                <IconClose />
                            </span>
                        </MobileModalHeader>
                        <MobileModalBody style={{paddingTop: 0}}>
                            <SettingsFormGroupInput>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer style={{fontSize: 14}}>Email Recipients</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <SelectAdv
                                        type="multi"
                                        value={emails}
                                        onChange={(options: string[]) => {
                                            this.setState({ emails: options });
                                        }}
                                        options={emailOptionsLabel}
                                    />
                                </SettingsFormInputContainer>
                                <p>Pick up to 4 e-mail addresses to send Overview Report.</p>
                            </SettingsFormGroupInput>

                        </MobileModalBody>
                        <MobileModalFooter>
                            <SettingsActionButtonWrapper
                                style={{zIndex: 2}}
                                position='relative' noborder nopadding>
                                <button type="button" onClick={this.sendEmail}> 
                                 {isSendingEmail && <RotateLoader size={2} color="white" />}
					             {!isSendingEmail && "Send"}
                                 </button>
                            </SettingsActionButtonWrapper> 
                        </MobileModalFooter>
                    </MobileModalContent>
                </MobileModal> }
        </div>)
    }
}

// @ts-ignore
export const RestaurantDashboardV2 = withTheme(withTranslation()(RestaurantDashboardV2Class));
