import React from 'react';
import styled, { css } from 'styled-components';
import { CommonText } from '@lib/components';
import { Translation } from "react-i18next";
import { CanceledOrderIcon, CalendarIcon, ConfirmedOrderIcon, UnconfirmedOrderIcon, UsersIcon } from '../../../../../../../components/v2/icons';

const MinimalViewCardWrapper = styled.div`
    display: flex;
    gap: 16px;
    background: #FEFEFE;
    padding: 12px;
    border-radius: 4px;
`;


const CardRight = styled.div``;

const CardBottomDetails = styled.div`
    display: flex;
    margin-top: 8px;
`;

export const DetailItem = styled.div<{ basis?: string }>`
    display: flex;
    align-items: center;
    gap: 8px;
    ${({ basis }) => basis ? css`
    flex-basis: ${basis || '100px'} 
    ` : ''}
`;

type MinimalViewCardProps = {
    title: string;
    numberOfPeople: number;
    bookingTime: number | string;
    timeStamp: number;
    onClick: () => void;
    orderStatus: string;
}
const getOrderIcon = (orderStatus: string) => {
    switch (orderStatus) {
        case 'cancelled':
            return <CanceledOrderIcon />;
        case 'unconfirmed':
            return <UnconfirmedOrderIcon />;
        case 'confirmed':
            return <ConfirmedOrderIcon />;
        default:
            return null;
    }
};
export const MinimalViewCard: React.FC<MinimalViewCardProps> = ({ title, numberOfPeople, bookingTime, onClick, orderStatus, timeStamp }) => {
    return (
        <Translation>
            {
                t => (
                    <MinimalViewCardWrapper onClick={onClick}>
                        <CardLeft orderStatus={orderStatus}>
                            {getOrderIcon(orderStatus)}
                        </CardLeft>
                        <CardRight>
                            <CommonText align='left' size="medium" weight={600} color='#0C202A'>
                                {title}
                            </CommonText>
                            <CardBottomDetails>
                                <DetailItem>
                                    <UsersIcon />
                                    <CommonText size="small" weight={500} color='#6B7270'>
                                        {numberOfPeople} Persons
                                    </CommonText>
                                </DetailItem>
                                <DetailItem style={{
                                    marginLeft: "12px"
                                }}>
                                    <CalendarIcon />
                                    <CommonText size="small" weight={500} color='#6B7270'>
                                        {t("dateFromTimestamp", { value: bookingTime })} - {t("timeFromTimestamp", { value: timeStamp })}
                                    </CommonText>
                                </DetailItem>
                            </CardBottomDetails>
                        </CardRight>
                    </MinimalViewCardWrapper>
                )
            }
        </Translation>
    );
};

const CardLeft = styled.div<{ orderStatus: string }>`
    display: flex; 
    width: 48px;
    height: 48px;
    border-radius: 99px;
    padding: 8px;
    justify-content: center;
    align-items: center; 
    background-color: ${props => {
        switch (props.orderStatus) {
            case 'cancelled':
                return '#BC362F';
            case 'unconfirmed':
                return '#E5AC00';
            case 'confirmed':
                return '#A5C100';
            default:
                return 'transparent'; // hoặc màu mặc định nào đó
        }
    }}`;