import * as React from "react";
import { FastField } from "formik";
import { FormGroup } from "@lib/components";
import { Input } from "@lib/components";
import { Button } from "@lib/components";
import { RotateLoader } from "@lib/components";
import { TabSelect } from "@lib/components";
import { ButtonGroup } from "@lib/components";
import { FormUploadV2 } from "@lib/components";
import { SelectAdv } from "@lib/components";
import { Switch } from "@lib/components";
import shortid from "shortid";
import { FieldDishIngredientsV2 } from "@lib/components";
import { FieldDishChoices } from "@lib/components";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantUtils } from "@lib/common";
import { ModalContent } from "@lib/components";
import { Modal } from "@lib/components";
import _ from "lodash";
import { MobxComponent } from "../../../../../mobx/components";
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { MenuItemText } from "../menus/layout/floating-button";
import { IconHelp } from "../../../../components/v2/icons";
import { SettingsFormGroup, SettingsFormGroupInput, SettingsFormLabelContainer, SettingsFormLabelLeftContainer, SettingsFormLabelRightContainer, SettingsFormInputContainer, SettingsActionButtonContainer, SettingsActionButtonWrapper } from "../settings-v2";
import { TabGroup, TabItem } from "../settings-v2/receipt-printing";
import { IngredientAddingButton } from "../menus/forms/mobileForms/mobileDish";

interface FormValues extends T.Schema.Restaurant.Menu.RestaurantDish { }
interface FormValues extends T.Schema.Restaurant.Menu.RestaurantDish { }
interface Props extends WithTranslation {
	type: "edit" | "create";
	menuIndex: number;
	categoryIndex: number;
	initialValues: FormValues | null;
	close: () => void;
}
interface State {
	tab: string;
	preventCloseModal: boolean;
	showOptionSetPoints: boolean;
	itemType: string;
}

@inject("store")
@observer
class RestaurantFormDishCV2 extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
			preventCloseModal: false,
			showOptionSetPoints: false,
			itemType: 'standard'
		};
	}

	setTab = (tab: string) => {
		this.setState({ tab });
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValues && !prevProps.initialValues) {
			this.setState({
				tab: "0",
			});
		}
	}

	showOptionSetPoints(
		selectedValues: string[],
		option_sets: T.Schema.Restaurant.Menu.RestaurantOptionSet[]
	) {
		let showOptionSetPoints = false;
		if (selectedValues && option_sets) {
			_.forEach(selectedValues, (selectedOptionId) => {
				const index = option_sets.findIndex(
					(item) => item._id === selectedOptionId
				);
				if (index && index > -1 && option_sets[index].using_points) {
					showOptionSetPoints = true;
				}
			});
		}
		return showOptionSetPoints;
	}

	render() {
		const { store } = this.injected;
		const r = store.restaurant!;
		let { type, initialValues, close } = this.props;
		const { tab, preventCloseModal } = this.state;
		const {activeMobileMenu} = store;
		const restaurant = store.restaurant!;
		const currency = store.intl.s.currency;
		const taxRates = r.settings.region.tax.rates ?? []
		const rates = taxRates.map((rate: any) => {
			return {
				name: rate.name,
				_id: rate._id,
			};
		});

		return (
			
				<>
				{(activeMobileMenu.name === 'create-item' || activeMobileMenu.name === 'edit-item') && 
				<TabGroup className='menus' style={{ overflow: 'unset', padding: '16px' }}>
						<TabItem
							active={this.state.tab === '0'}
							onClick={() => this.setState({ tab: '0' })}
							className="mobile-button"
						>
							General
						</TabItem>
						<TabItem
							active={this.state.tab === '1'}
							onClick={() => this.setState({ tab: '1' })}
							className="mobile-button"
						>
							Image/Tags
						</TabItem>
						<TabItem
							active={this.state.tab === '2'}
							onClick={() => this.setState({ tab: '2' })}
							className="mobile-button"
						>
							{this.state.itemType === 'standard' ? 'Options/Ingredients' : 'Item Choices'} 
						</TabItem>
					</TabGroup>}
					<RestaurantForm<FormValues>
					submit={async (r, values) => {
						if (values.image && !values.image._id) {
							values.image = null;
						}

						const { menuIndex, categoryIndex } = this.props;

						// CLEAN & PROCESS
						if (values.type === "combo") {
							values.option_sets = [];
							values.ingredients = [];

							const dishes = RestaurantUtils.menu.getDishes(r);
							for (const [
								index,
								choice,
							] of values.choices.entries()) {
								// CHECK FOR CHOICES THAT NO LONGER EXIST
								for (let i = choice.dishes.length; i--;) {
									const found = dishes.findIndex(
										(d) => d._id === choice.dishes[i]
									);
									if (found === -1) {
										values.choices[index].dishes.splice(
											i,
											1
										);
									}
								}

								// SET LOWEST PRICE OPTION
								let lowest_price_option = 1000000;
								for (const dish of dishes) {
									if (
										choice.dishes.indexOf(dish._id) !==
										-1 &&
										dish.price < lowest_price_option
									) {
										lowest_price_option = dish.price;
									}
								}
								values.choices[index].lpo = lowest_price_option;
							}
						} else {
							values.choices = [];
						}

						// CLEAN TAGS THAT HAVE BEEN DELETED
						values.tags = values.tags.filter((tag) => {
							return (
								r.dish_tags.findIndex(
									(dt) => dt._id === tag
								) !== -1
							);
						});

						// UPDATE EXISTING OR PUSH NEW
						const existing = r.menus[menuIndex].categories[
							categoryIndex
						].dishes.findIndex((p) => p._id === values._id);
						if (existing === -1) {
							r.menus[menuIndex].categories[
								categoryIndex
							].dishes.push(values);
						} else {
							r.menus[menuIndex].categories[categoryIndex].dishes[
								existing
							] = values;
						}

						// Enable all taxes for this new dish

						const rates = r.settings.region.tax.rates ? r.settings.region.tax.rates?.map(
							(rate: any) => {
								if (values.taxes && values.taxes.length > 0) {
									values.taxes.forEach((validTaxID) => {
										if (
											rate._id === validTaxID &&
											this.props.initialValues &&
											this.props.initialValues._id
										) {
											rate.dish_tax =
												rate.dish_tax.concat(
													this.props.initialValues._id
												);
										}
									});
								}
								return rate;
							}
						) : [];

						const update = {
							$set: {
								menus: r.menus,
								"settings.region.tax.rates": rates,
							},
						};
						return { r, update };
					}}
					validators={{
						name: (values) => {
							if (!values.name)
								return { name: "This field is required" };
						},
						price: (values) => {
							// @ts-ignore
							if (values.price === "")
								return { name: "This field is required" };
						},
            image: (values) => {
              if (values.image && values.image._id.length > 2048) {
                return { image: "Image URL must less than 2048 characters" };
              }
            }
					}}
					initialValues={initialValues}
					onSuccess={close}
					onSuccessMessage={
						type === "edit" ? "Item edited" : "Item created"
					}
				>
					{({ form, error, getFieldError }) => {
						const { isSubmitting, setFieldValue, values } = form;
						const isCombo = values.type === "combo";
						return (
							<div>
								{tab === "0" && (
										<SettingsFormGroup>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Item Type</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<FastField
												name="price_type"
												render={({ field }: any) => (
													<TabGroup className='menus' style={{ overflow: 'unset', padding: '16px 0' }}>
													<TabItem
														active={this.state.itemType === 'standard'}
														onClick={() => {this.setState({ itemType: 'standard' }); setFieldValue(
															"price_type",
															'standard'
														)}}
													>
														Standard
													</TabItem>
													<TabItem
														active={this.state.itemType === 'Combo'}
														onClick={() => {this.setState({ itemType: 'Combo' }); setFieldValue(
															"price_type",
															'Combo'
														)}}
													>
														Combo
													</TabItem>
												</TabGroup>
												)}
											/>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
										<FastField
											name="name"
											render={({ field }: any) => (
												<input
													type="text"
													{...field} />
											)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>{`Price (${currency.symbol})`}</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="price"
												render={({ field }: any) => (
												<input
													type="number"
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Display Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
										<FastField
												name="display_name"
												render={({ field }: any) => (
												<input
													type="text"
													{...field}
													/>
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Printer Name</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="print_name"
												render={({ field }: any) => (
												<input
													type="text"
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Printers</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="printers"
												render={({ field }: any) => (
												<input
													type="text"
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Description</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="description"
												render={({ field }: any) => (
												<input
													type="text"
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Subtitle</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="subtitle"
												render={({ field }: any) => (
												<input
													type="text"
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Services</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
											<FastField
												name="taxes"
												render={({ field }: any) => (
													<SelectAdv
													type="multi"
													value={field.value}
													options={rates.map(
														(rate) => ({
															label: rate.name,
															value: rate._id,
														})
													)}
													onChange={(
														selectedValues: string[]
													) => {
														setFieldValue(
															"taxes",
															selectedValues
														);
													}}
												/>
												)}
											/>
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
									</SettingsFormGroup>
								)}

								{tab === "1" && (
									<SettingsFormGroup>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Image</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<FastField
												name="image"
												render={({ field }: any) => (
													<FormUploadV2
														maxSize={1000}
														imagesOnly={true}
														value={[field.value]}
														customSource={true}
														onRemove={() =>
															setFieldValue(
																"image",
																null
															)
														}
														onOpen={() =>
															this.setState({
																preventCloseModal:
																	true,
															})
														}
														onClose={() =>
															this.setState({
																preventCloseModal:
																	false,
															})
														}
														onChange={(files) =>
															setFieldValue(
																"image",
																files[0]
															)
														}
													/>
												)}
											/>
										</SettingsFormGroupInput>
										{/* <FormGroup
											optional={true}
											title="Image"
											help="File name can't contain special characters. Only letters and numbers are allowed."
                      error={getFieldError(form, "image")}
										>
											<FastField
												name="image"
												render={({ field }: any) => (
													<FormUpload
														maxSize={1000}
														imagesOnly={true}
														value={[field.value]}
														customSource={true}
														onRemove={() =>
															setFieldValue(
																"image",
																null
															)
														}
														onOpen={() =>
															this.setState({
																preventCloseModal:
																	true,
															})
														}
														onClose={() =>
															this.setState({
																preventCloseModal:
																	false,
															})
														}
														onChange={(files) =>
															setFieldValue(
																"image",
																files[0]
															)
														}
													/>
												)}
											/>
										</FormGroup> */}
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Services</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
											<FastField
											name="tags"
											render={({ field }: any) => (
													<SelectAdv
														type="multi"
														value={field.value}
														options={restaurant.dish_tags.map(
															(item) => ({
																label: item.name,
																value: item._id,
															})
														)}
														onChange={(
															selectedValues: string[]
														) => {
															setFieldValue(
																"tags",
																selectedValues
															);
														}}
													/>
											)}
										/>
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
									</SettingsFormGroup>
								)}
								{tab === "2" && (
									<SettingsFormGroup>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Option Sets</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
											<FastField
											name="option_sets"
											render={({ field }: any) => (
													<SelectAdv
														type="multi"
														value={field.value}
														options={restaurant.option_sets.map(
															(item) => ({
																label: item.name,
																value: item._id,
															})
														)}
														onChange={(
															selectedValues: string[]
														) => {
															setFieldValue(
																"option_sets",
																selectedValues
															);
															if (
																selectedValues.indexOf(
																	form.values
																		.option_set_primary ||
																	""
																) !== -1
															) {
																setFieldValue(
																	"option_set_primary",
																	""
																);
															}
															this.setState({
																showOptionSetPoints:
																	this.showOptionSetPoints(
																		selectedValues,
																		restaurant.option_sets
																	),
															});
														}}
													/>
											)}
										/>
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
											<SettingsFormLabelLeftContainer>Points</SettingsFormLabelLeftContainer>
											<SettingsFormLabelRightContainer>
											<IconHelp />
											</SettingsFormLabelRightContainer>
										</SettingsFormLabelContainer>
										<SettingsFormInputContainer>
											<FastField
												name="total_points"
												render={({ field }: any) => (
												<input
													type="number"
													step={1}
													min={0}
													max={100}
													required={false}
													{...field} />
												)} />
										</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
											<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Option Sets</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
											<SettingsFormInputContainer>
											<FastField
											name="option_set_primary"
											render={({ field }: any) => (
												<SelectAdv
													type="single"
													options={restaurant.option_sets
														.filter((it) => {
															return (
																form.values.option_sets.indexOf(
																	it._id
																) !== -1 &&
																it.conditions
																	.required &&
																!it.conditions
																	.multi_select
															);
														})
														.map((item) => ({
															label: item.name,
															value: item._id,
														}))}
													value={
														form.values
															.option_set_primary ||
														null
													}
													onChange={(option: string) =>
														setFieldValue(
															"option_set_primary",
															option
														)
													}
												/>
											)}
										/>
											</SettingsFormInputContainer>
										</SettingsFormGroupInput>
										<SettingsFormGroupInput>
										<SettingsFormLabelContainer>
												<SettingsFormLabelLeftContainer>Ingredients</SettingsFormLabelLeftContainer>
												<SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
											</SettingsFormLabelContainer>
										<FastField
											name="ingredients"
											render={({ field }: any) => (
												<FormGroup
													style={{ width: '100%' }}
												>
												<IngredientAddingButton onClick={() => {
															setFieldValue(
																"ingredients",
																[
																	...field.value,
																	{
																		_id: shortid.generate(),
																		name: "",
																		active: true,
																	} as T.Schema.Restaurant.Menu.RestaurantDishIngredient,
																]
															);
														}}>
                                                        Add Ingredient
                                                    </IngredientAddingButton>
													
													{field.value.length !==
														0 && (
															<div className="m-tb-3" />
														)}
													{field.value.length !==
														0 && (
															<FieldDishIngredientsV2
																values={field.value}
																onChange={(item) =>
																	setFieldValue(
																		"ingredients",
																		item
																	)
																}
															/>
														)}
												</FormGroup>
											)}
										/>
										</SettingsFormGroupInput>
									</SettingsFormGroup>
								)}

								{tab === "3" && (
									<ModalContent>
										<FastField
											name="choices"
											render={({ field }: any) => (
												<FormGroup help="Define choices that can contain a list of items a customer can select from. If a choice only has a single item, then it will be preselect when ordering">
													<Button
														type="button"
														color="primary-inverse"
														size="xs"
														onClick={() => {
															setFieldValue(
																"choices",
																[
																	...field.value,
																	{
																		_id: shortid.generate(),
																		name: "",
																		dishes: [],
																		selected:
																			null,
																		lpo: 0,
																	} as T.Schema.Restaurant.Menu.RestaurantDishChoice,
																]
															);
														}}
													>
														Add Choice
													</Button>
													{field.value.length !==
														0 && (
															<div className="m-tb-3" />
														)}
													{field.value.length !==
														0 && (
															<FieldDishChoices
																r={restaurant}
																values={field.value}
																onChange={(item) =>
																	setFieldValue(
																		"choices",
																		item
																	)
																}
															/>
														)}
												</FormGroup>
											)}
										/>
										<FastField
											name="compute_combo_point_from_dishes"
											render={({ field }: any) => (
												<FormGroup
													title="Use Item Points as Combo Points"
													help="Use items' points instead of combo points below. If this is enabled, the combo points will be the total of the selected items' individual points; and it will ignore the Points value below. Default is disabled."
												>
													<Switch
														id="compute_combo_point_from_dishes"
														checked={field.value}
														onChange={(e) =>
															setFieldValue(
																"compute_combo_point_from_dishes",
																e.target.checked
															)
														}
													/>
												</FormGroup>
											)}
										/>
										<FastField
											name="total_points"
											render={({ field }: any) => (
												<FormGroup
													optional={true}
													title="Points"
													help="Total consumable points for items that use Pizza Option Set type. Consuming more than this value will charge the price of the option set."
												>
													<Input
														type="number"
														step={1}
														min={0}
														max={100}
														required={false}
														{...field}
													/>
												</FormGroup>
											)}
										/>
									</ModalContent>
								)}

								{tab === "4" && (
									<ModalContent>
										{type !== "edit" && (
											<FastField
												name="type"
												render={({ field }: any) => (
													<FormGroup
														title="Item Type"
														help="A combo items allows customers to make several choices between selected standard items"
													>
														<ButtonGroup
															width={100}
															size="sm"
															selected={
																field.value
															}
															options={[
																{
																	name: "Standard",
																	value: "standard",
																},
																{
																	name: "Combo",
																	value: "combo",
																},
															]}
															onSelect={({
																value,
															}) =>
																setFieldValue(
																	"type",
																	value
																)
															}
														/>
													</FormGroup>
												)}
											/>
										)}
										<FastField
											name="integrations.pushpos.item_id"
											render={({ field }: any) => (
												<FormGroup
													title="POS Item Id"
													help="A unique id for your item"
													error={getFieldError(
														form,
														"integrations.pushpos.item_id"
													)}
												>
													<Input
														type="number"
														{...field}
														required={true}
													/>
												</FormGroup>
											)}
										/>									
									</ModalContent>
								)}

								<SettingsActionButtonContainer>
									<SettingsActionButtonWrapper  style={{ left: 0}}>
										<button type="button"
											className="default btn-cancel"
											onClick={() => {
												store.setActiveMobileMenu(
													store.activeMobileMenu.referrer.pop()!,
													Array.from(new Set(store.activeMobileMenu.referrer))
												);
											} }>
											Cancel
										</button>
										<button type="submit" disabled={isSubmitting}>
											{isSubmitting && <RotateLoader size={2} color="white" />}
											{!isSubmitting && "Save"}
										</button>
									</SettingsActionButtonWrapper>
								</SettingsActionButtonContainer>
							</div>
						);
					}}
				</RestaurantForm>
				</>
		);
	}
}

export const RestaurantFormDishV2 = withTranslation()(RestaurantFormDishCV2);
